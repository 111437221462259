import pruple from "./img/purple.png"
import green from "./img/green.png"
import blue from "./img/blue.png"
import red from "./img/red.png"
import orange from "./img/orange.png"

export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/heat-sticks/veo/?utm_source=Glo%2B&utm_medium=Article&utm_campaign=Veo",
    copy: "Jetzt entdecken"
  },
  customFinishModal: "correct-match",
  content: [
    {
      title: "Veo™ AROMEN ERKENNEN",
      buttonCopy: "Nächste",
      mechanics: "Quiz",
      type: "button-image-text",
      question: "Zeige uns, wie gut du die veo™ Sticks kennst. Wähle eins der Päckchen aus, das zu der Geschmacksbeschreibung passt.",
      img: require("./img/greenClick.png"),
      correctAnswer: "b",
      mobileQuestionImg: true,
      imgText: {
        text: "Reichhaltiges Pfefferminz-Aroma auf Rooibos-Basis",
        color: "white"
      },
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "veo™ Arctic Click",
          img: blue
        },
        {
          ident: "b",
          content: "veo™ Green Click",
          img: green
        },
      ]
    },
    {
      title: "Veo™ AROMEN ERKENNEN",
      buttonCopy: "Nächste",
      mechanics: "Quiz",
      type: "button-image-text",
      question: "Die innovativen veo™ Sticks werden aus der Rooibos-Pflanze hergestellt und bieten ein tabakfreies* Geschmackserlebnis in 5 Flavours. Welches Päckchen entspricht der folgenden Beschreibung?",
      img: require("./img/scarlet.png"),
      mobileQuestionImg: true,
      imgText: {
        text: "Reichhaltiges Aroma aus roten Beeren auf Rooibos-Basis",
        color: "white"
      },
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "veo™ Scarlett Click",
          img: red
        },
        {
          ident: "b",
          content: "veo™ Violet Click",
          img: pruple
        },
      ]
    },
    {
      title: "Veo™ AROMEN ERKENNEN",
      buttonCopy: "Nächste",
      mechanics: "Quiz",
      type: "button-image-text",
      question: "Die Rooibos-Pflanze entwickelt beim Erhitzen ein mildes Geschmacksprofil, das das Aroma noch besser zur Geltung bringt. Suche eine veo™ Packung aus, die der Beschreibung der Flavour entspricht.",
      img: require("./img/sunset.png"),
      mobileQuestionImg: true,
      imgText: {
        text: "Reichhaltiges Frucht-Aroma auf Rooibos-Basis",
        color: "white"
      },
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "veo™ Sunset Click",
          img: orange
        },
        {
          ident: "b",
          content: "veo™ Arctic Click",
          img: blue
        },
      ]
    },
    {
      title: "Veo™ AROMEN ERKENNEN",
      buttonCopy: "Nächste",
      mechanics: "Quiz",
      type: "button-image-text",
      question: "Du weißt schon, dass du mit dem Klick der Aromakapsel noch mehr Geschmack bekommst, oder? Dann wird es für dich kein Problem sein, die richtige veo™ Packung der Beschreibung zuzuordnen!",
      img: require("./img/violet.png"),
      mobileQuestionImg: true,
      imgText: {
        text: "Reichhaltiges Blaubeer-Aroma auf Rooibos-Basis",
        color: "white"
      },
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "veo™ Arctic Click",
          img: blue
        },
        {
          ident: "b",
          content: "veo™ Violet Click",
          img: pruple
        },
      ]
    },
    {
      title: "Veo™ AROMEN ERKENNEN",
      buttonCopy: "Nächste",
      mechanics: "Quiz",
      type: "button-image-text",
      question: "Jetzt musst du nur noch das letzte veo™ Pack richtig markieren, um deine Challenge zu beenden",
      img: require("./img/arctic.png"),
      mobileQuestionImg: true,
      imgText: {
        text: "Spearmint-Aroma auf Rooibos-Basis",
        color: "white"
      },
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "veo™ Arctic Click",
          img: blue
        },
        {
          ident: "b",
          content: "veo™ Green Click",
          img: green
        },
      ]
    },
  ]
}