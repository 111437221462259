import SideImg from "./img/side-img.jpg"
import tabaco_pack from "./img/tabaco_pack.png"
import tabaco_tile from "./img/tabaco_tile.jpg"
import rounded_tabaco_pack from "./img/rounded_tabaco_pack.png"
import rounded_tabaco_tile from "./img/rounded_tabaco_tile.jpg"


export const questions = {
  staticSideImg: SideImg,
  content: [
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "SPECIAL NEUE PACKS…",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: true,
      question: "LUCKY STRIKE<br/>balanced Tobacco",
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: tabaco_tile,
          img: tabaco_pack
        },
      ]
    },
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "SPECIAL NEUE PACKS…",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: true,
      question: "LUCKY STRIKE<br/>Rounded Tobacco",
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "b",
          content: rounded_tabaco_tile,
          img: rounded_tabaco_pack
        },
      ]
    },

  ]
}