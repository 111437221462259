import React, { useState, useEffect } from 'react'
import "./style.scss"
import { fetchData, getCookie, useWindowSize } from '../../Helpers'
import Email from "./img/mail.svg"
import Whatsapp from "./img/whatsapp.svg"
import Coins from "./img/coins.svg"
import Cart from "./img/cart.svg"
import Copy from "./img/copy.svg"
import Ticket from "./img/ticket.svg"
import Neo from "./img/neo.svg"
import TickMulicolor from "../../../img/tick-multicolor.svg"
import NiceModal from '@ebay/nice-modal-react';
import { useUserState } from '../../UserContext'
import { showErrorPopup, translateError } from '../../components/ErrorHandling'
import Dou from "./img/dou-1.jpg";
import Spider from '../../components/Halloween'
import EasterEgg from '../../components/EasterEgg'

export default function InvitePage() {
  const [page, setPage] = useState("benefits")
  const [show, setShow] = useState(false)
  const [showCopyInfo, setShowCopyInfo] = useState(false)
  const { width } = useWindowSize()
  const HD = 1280
  const XGA = 1020
  const { userDetails } = useUserState()
  const [devicesCount, setDeviceCount] = useState(0)

  useEffect(() => {
    // const getDeviceCount = async () => {
    //   const response = await fetch(process.env.REACT_APP_API_URL + "/consumer/stats/", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " +  (getCookie("loyalty_sso_session_id") ?? JSON.parse(sessionStorage.getItem('token')).uuid),
    //     },
    //     body: JSON.stringify({
    //     })
    //   })
    //   const stats = await response.json()
    //   if (stats.status.success) {
    //     setDeviceCount(stats.data["tell-a-friend"].balanced)
    //   } else {
    //     showErrorPopup(translateError(stats.data.error), stats.meta.ts)
    //   }
    // }

    // setTimeout(() => { setShow(true) }, 800)
    // getDeviceCount()

    const controller = new AbortController()
    fetchData({
      endpoint: "/consumer/stats/",
      payload: {},
      successCallback: resObj => setDeviceCount(resObj.data["tell-a-friend"].balanced),
      controller: controller
    })

    return () => {
      controller.abort()
    }
  }, [])

  async function share() {
    const shareData = {
      title: 'glo+',
      text: 'Mein persönlicher Rabatt-Code für Dich:',
      url: `https://discoverglo.com/de/de/raf?voucherCode=${userDetails?.referralCode}`
    }

    try {
      await navigator.share(shareData);
    } catch (error) {
      if (showCopyInfo === false) {
        navigator.clipboard.writeText(`https://discoverglo.com/de/de/raf?voucherCode=${userDetails?.referralCode}`)
        setShowCopyInfo(true)
        setTimeout(() => setShowCopyInfo(false), 2500)
      }
      console.log(error)
    }
  }

  function shareInWhatsapp() {
    window.open(`whatsapp://send?text=Mein persönlicher Rabatt-Code für Dich: https://discoverglo.com/de/de/raf?voucherCode=${userDetails?.referralCode}`)
  }

  return (
    <section className="invite-page">
      <div className='container'>
        <div className='invite'>
          <h2>FREUNDE Einladen</h2>
          <p>Empfehl glo™ deinen Freunden und ihr profitiert alle! Mit dem Code erhalten sie einen exklusiven Rabatt und du erhältst bis zu 130 Punkte!*</p>
          <h4>Dein persönlicher Rabatt-Code:</h4>
          <div className='your-code-outer'>
            <button className='your-code' onClick={() => {
              if (!navigator.canShare) {
                if (showCopyInfo === false) {
                  navigator.clipboard.writeText(`https://discoverglo.com/de/de/raf?voucherCode=${userDetails?.referralCode}`)
                  setShowCopyInfo(true)
                  setTimeout(() => setShowCopyInfo(false), 2500)
                }
              } else {
                share()
              }
            }}>discoverglo.com/de/de<span>/raf?voucherCode={userDetails?.referralCode}</span></button>
            <button className='copy-link' onClick={() => {
              if (!navigator.canShare) {
                if (showCopyInfo === false) {
                  navigator.clipboard.writeText(`https://discoverglo.com/de/de/raf?voucherCode=${userDetails?.referralCode}`)
                  setShowCopyInfo(true)
                  setTimeout(() => setShowCopyInfo(false), 2500)
                }
              } else {
                share()
              }
            }}>

              <img src={Copy} alt="Copy link" />
              <div className={`copy-info ${showCopyInfo === true ? "show" : ""}`}>
                <img src={TickMulicolor} alt="" />
                <p>Link kopiert</p>
              </div>
            </button>

          </div>

          {!navigator.canShare ? <>
            {width < HD && <h4>Sende Code per</h4>}
            <div className='send-methods'>
              {width >= HD && <button className='send-item' onClick={() => window.open(`mailto:?subject=15 €-Rabatt auf den ersten Kauf eines glo™ Devices&body=Mein persönlicher Rabatt-Code für Dich: https://discoverglo.com/de/de/raf?voucherCode=${userDetails?.referralCode}`)}>Rabatt-Code per E-Mail senden<img src={Email} alt="E-mail" /></button>}
              {width < HD && <button className='send-item' onClick={() => window.open(`mailto:?subject=15 €-Rabatt auf den ersten Kauf eines glo™ Devices&body=Mein persönlicher Rabatt-Code für Dich: https://discoverglo.com/de/de/raf?voucherCode=${userDetails?.referralCode}`)}>E-Mail<img src={Email} alt="E-mail" /></button>}
              {width < HD && <button className='send-item' onClick={() => shareInWhatsapp()}>WhatsApp<img src={Whatsapp} alt="E-mail" /></button>}
            </div>
          </> : null}
          <h4>Bereits von Freunden gekauft</h4>
          <ul className='stats'>
            <li><img src={Cart} alt="Cart" />glo™ devices<span>{devicesCount}</span></li>
          </ul>
        </div>
        <div className='content'>
          <ul className='content-nav'>
            <EasterEgg uuid={"68008b26-ec3c-4740-8e5b-5706d78e6239"} />
            <li onClick={() => setPage("benefits")} className={page === "benefits" ? "active" : ""}>Vorteile</li>
            <li onClick={() => setPage("invite")} className={page === "invite" ? "active" : ""}>Wie einladen?</li>
          </ul>

          {page === "invite" ? <div className='invite-content'>
            <ul>
              <li>
                <span>1</span>
                <h4>Rabatt-Code versenden</h4>
                <p>Sende den Rabatt-Code an einen Freund.</p>
              </li>
              <li>
                <span>2</span>
                <h4>Registrieren und Rabatt sichern</h4>
                <p>Hat dein Freund den Rabatt-Code erhalten, registriert er sich im glo™ Store. (https://www.discoverglo.com/de/de/).</p>
              </li>
              <li>
                <span>3</span>
                <h4>Device kaufen</h4>
                <p>Dein Freund sucht sich sein Device aus – der Rabatt wird automatisch beim Kauf im Check-Out abgezogen.</p>
              </li>
              <li>
                <span>4</span>
                <h4>Deine Belohnung</h4>
                <p>Sobald dein Freund den Kaufvorgang abgeschlossen hat, erhältst du bis zum 130 Punkte.*</p>
              </li>
            </ul>
            <div className='img'>
            </div>
          </div> : <div className='benefits-content'>
            <ul className='benefits-list'>
              <li>
                <div className='img'>
                  {width < HD && <h3>FÜR DICH:</h3>}
                </div>
                <div className='text'>
                  {width >= HD && <h3>FÜR DICH:</h3>}
                  <ul>
                    <li>
                      <img src={Coins} alt="Coins" />
                      <h4>Bis zu 130 Punkte*</h4>
                      <p className="mobile">
                        Du bekommst:<br />80 Punkte für die erste Empfehlung,<br />90 Punkte für die zweite,<br />100 Punkte für die dritte,<br />110 Punkte für die vierte,<br />120 Punkte für die fünfte und<br />130 Punkte für die sechste und jede weitere Empfehlung.
                      </p>
                    </li>
                    <li>
                      <img src={Dou} alt="douglas" />
                      <h4>15 € Wunschgutschein</h4>
                    </li>
                    <li>
                      <p>Für jeden eingeladenen Freund, der ein glo™ Device kauft.</p>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className='img'>
                  {width < HD && <h3>FÜR DEINEN FREUND:</h3>}
                </div>
                <div className='text'>
                  {width >= HD && <h3>FÜR DEINEN FREUND:</h3>}
                  <ul>
                    <li>
                      <img src={Ticket} alt="ticket" />
                      <h4>20 €-Rabatt auf den ersten Kauf eines glo™ Devices.</h4>
                      <p>Rabatt wird angerechnet, wenn er den Link benutzt.</p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>}
        </div>
      </div>
      <p className="info desktop">*Du bekommst: 80 Punkte für die erste Empfehlung, 90 Punkte für die zweite, 100 Punkte für die dritte, 110 Punkte für die vierte, 120 Punkte für die fünfte und 130 Punkte für die sechste und jede weitere Empfehlung.</p>
    </section>
  )
}
