export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  content: [
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      title: "REEL TALK by glo™ – QUIZ",
      mobileTopImg: true,
      type: "button",
      question: "Wie heißt unsere Instagram-Talkshow?",
      img: require("./img/1.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Reel Talk"
        },
        {
          ident: "b",
          content: "Deep Stuff"
        },
        {
          ident: "c",
          content: "Real Post"
        },
        {
          ident: "d",
          content: "Hot Fuzz"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "Wer war bei Reel Talk by glo™ nicht zu Gast?",
      mobileTopImg: true,
      question: "Wer war bei Reel Talk by glo™ nicht zu Gast?",
      img: require("./img/2.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Bill Kaulitz"
        },
        {
          ident: "b",
          content: "Emilio"
        },
        {
          ident: "c",
          content: "Drunken Masters"
        },
        {
          ident: "d",
          content: "Elif"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "REEL TALK by glo™ – QUIZ",
      mobileTopImg: true,
      question: "Welchen dieser legendären Artists würde die Künstlerin Elif gerne einmal treffen?",
      video: require("./img/3.mp4"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Prince"
        },
        {
          ident: "b",
          content: "Queen"
        },
        {
          ident: "c",
          content: "B.B. King"
        },
        {
          ident: "d",
          content: "Elvis"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "REEL TALK by glo™ – QUIZ",
      mobileTopImg: true,
      question: "Wo hat sich das DJ Duo Drunken Masters kennen gelernt?",
      video: require("./img/4.mp4"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "In der Schule"
        },
        {
          ident: "b",
          content: "Im Skate-Shop"
        },
        {
          ident: "c",
          content: "Auf Partys"
        },
        {
          ident: "d",
          content: "Im Kiosk"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "REEL TALK by glo™ – QUIZ",
      mobileTopImg: true,
      question: "Was ist das erste Wort, dass Emilio in der Reel Talk Quickfire Runde bei dem Stichwort „Marokko“ in den Kopf kommt?",
      video: require("./img/5.mp4"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Heimat"
        },
        {
          ident: "b",
          content: "Essen"
        },
        {
          ident: "c",
          content: "Freundschaft"
        },
        {
          ident: "d",
          content: "Sommer"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "REEL TALK by glo™ – QUIZ",
      mobileTopImg: true,
      question: "Wen hat Gizem, aka DJ Meg10, für ihre Master-Arbeit interviewt?",
      video: require("./img/6.mp4"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Melbeatz"
        },
        {
          ident: "b",
          content: "Lil Nas"
        },
        {
          ident: "c",
          content: "Heino"
        },
        {
          ident: "d",
          content: "Haiyti"
        },
      ]
    },
  ]
}