import SideImg from "./img/side-img.jpg"
import green from "./img/green-pack.jpg"
import green_tile from "./img/green.jpg"
import blue_tile from "./img/blue.jpg"
import blue from "./img/blue-pack.jpg"


export const questions = {
  staticSideImg: SideImg,
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/heat-sticks/veo/?utm_source=Glo%2B&utm_medium=Article&utm_campaign=Veo",
    copy: "veo™ kaufen"
  },
  content: [
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "Fresh paintings",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: false,
      question: "<span>veo™</span> Green click",
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: green_tile,
          img: green
        },
        {
          ident: "b",
          content: blue_tile,
          img: blue
        },

      ]
    },
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "Fresh paintings",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: false,
      question: "<span>veo™</span> arctic click",
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: green_tile,
          img: green
        },
        {
          ident: "b",
          content: blue_tile,
          img: blue
        },

      ]
    },

  ]
}