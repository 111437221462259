import React, { useEffect, useState } from 'react'
import Button from '../../../../components/Button'
import PageHeader from '../../../../components/PageHeader'
import "../style.scss"
import { useParams } from 'react-router-dom'
import DOMPurify from 'dompurify';
import { fetchData, getModuleValue, sendEvent } from '../../../../Helpers'
import MainImg from "../Idents/newsletter/img/main.webp"
import { getUserDetails, useUserDispatch } from '../../../../UserContext';


export default function Newsletter({ notificationRef }) {
  const { ident } = useParams();
  const [newsletter, setNewsletter] = useState({})
  const [loading, setLoading] = useState(false)
  const [getPointsLoading, setGetPointsLoading] = useState(false)
  const dispatch = useUserDispatch()
  const [pointsRecieved, setPointsRecieved] = useState(false)

  const getPoints = () => {
    setGetPointsLoading(true)
    fetchData({
      endpoint: "/activation/solution/newsletter",
      payload: {},
      successCallback: () => {
        getUserDetails(dispatch)
        notificationRef.current = true
        setPointsRecieved(true)
        setGetPointsLoading(false)
      },
    })
  }

  useEffect(() => {
    const controller = new AbortController()
    fetchData({
      endpoint: "/consumer/stats/",
      payload: {},
      successCallback: resObj => setNewsletter(resObj.data["newsletter"]),
      controller: controller,
      setLoading: setLoading
    })

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <section className='intro-template game-newsletter'>
      <PageHeader>Check unseren Newsletter aus</PageHeader>
      <div className="wrapper">
        <div className="image">
          <img src={MainImg} alt="" />
        </div>
        <div className="content">
          {!loading ? newsletter?.awaiting !== 0 && newsletter?.payout !== 0 ?
            <>
              <div className="text" >
                <h1>Danke für’s Lesen unseres Newsletters!</h1>
                <p>Wir hoffen, er hat dir gefallen. Jetzt kannst du dir deine Punkte abholen und im Prämienshop ausgeben. </p>
                <div className="card-wrapper">
                  <div className="card">
                    <p className="points">
                      <span>{newsletter?.payout ?? ""}</span> Punkte
                    </p>
                    <p className="copy">Für das Lesen von {newsletter?.awaiting} Ausgaben</p>
                  </div>
                </div>
              </div>
              <Button action={getPoints} disabled={getPointsLoading || pointsRecieved}>
                {
                  !pointsRecieved ?
                    <>
                      <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 4L3.5 7L10 1" stroke="white" strokeWidth="2" />
                      </svg>
                      Punkte sammeln
                    </>
                    :
                    "Punkte hinzugefügt"
                }</Button>
            </>
            :
            <>
              <div className="text" >
                <h1>Hol dir den Newsletter</h1>
                <p>Bleib up to date mit den besten und neusten Infos von glo+. Einfach lesen, klicken und Punkte abgreifen.* <br/> Es kann einen Tag dauern, bis die Punkte auf deinem Konto sind. </p>
              </div>
              <Button disabled={true}>Jetzt lesen</Button>
            </>
            :
            <></>
          }
        </div>
      </div>
    </section>
  )
}
