import FirstMobile from "./img/first_mobile.jpg"
import FirstDesktop from "./img/first_desktop.jpg"
import SecondMobile from "./img/second_mobile.jpg"
import SecondDesktop from "./img/second_desktop.jpg"

export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  content: [
    {
      mechanics: "Find",
      // type: "button-image",
      question: "Finde alle glo Items, die im Bild versteckt sind",
      imgMobile: FirstMobile,
      imgDesktop: FirstDesktop,
      // correctAnswer: "b",
      // wrongAnswerInfo: {
      //   type: "basic",
      // },
      // wrongAnswerInfo: {
      //   type: "extended",
      //   hint: {
      //     content: " Die richtige Anwort findest du in unserem letzten Instagram-Post.",
      //     linkContent: "Go to Instagram profile",
      //     url: "111"
      //   }
      // },
      answers: [
        {
          ident: "d9ab091a-9d6b-4d46-819e-fe855af3719b",
          clipPathMobile: "46 72, 53 72, 53 78, 46 78",
          clipPathDesktop: "51 68, 55 68, 55 76, 51 76",
        },
        {
          ident: "3fc7bf9e-9b7a-4614-85f6-444c7c4fd457",
          clipPathMobile: "74 64, 83 64, 83 71, 74 71",
          clipPathDesktop: "70 54, 76 54, 76 65, 70 65",
        },
        {
          ident: "9566d77e-1a2c-4b38-a5e2-3375c80b8874",
          clipPathMobile: "67 70, 73 70, 73 80, 67 80",
          clipPathDesktop: "65 65, 69 65, 69 80, 65 80",
        },
        {
          ident: "75964d5d-ef7f-4122-9528-137d39928b3a",
          clipPathMobile: "16 89, 23 89, 23 99, 16 99",
          clipPathDesktop: "30 95, 35 95, 35 100, 30 100",
        },
      ]
    },
    {
      mechanics: "Find",
      // type: "button-image",
      question: "Finde alle glo Items, die im Bild versteckt sind",
      imgMobile: SecondMobile,
      imgDesktop: SecondDesktop,
      // correctAnswer: "c",
      // wrongAnswerInfo: {
      //   type: "extended",
      //   hint: {
      //     content: " Die richtige Anwort findest du in unserem letzten Instagram-Post.",
      //     linkContent: "Go to Instagram profile",
      //     url: "111"
      //   }
      // },
      answers: [
        {
          ident: "0150b961-d13b-4130-a770-93429babb30a",
          clipPathMobile: "41 36, 35 36, 35 41, 41 41",
          clipPathDesktop: "42 32, 46 32, 46 42, 42 42",
        },
        {
          ident: "bc22ee68-70a3-4d0e-a7f8-ff12e5ca2468",
          clipPathMobile: "66 50, 59 50, 59 56, 66 56",
          clipPathDesktop: "62 62, 66 62, 66 71, 62 71",
        },
        {
          ident: "e529cfee-f246-4c86-91d8-277670d935d2",
          clipPathMobile: "84 28, 78 28, 78 35, 84 35",
          clipPathDesktop: "77 20, 81 20, 81 28, 77 28",
        },
        {
          ident: "2890240d-6713-4894-b5e2-e147da66a30e",
          clipPathMobile: "95 49, 89 49, 89 56, 95 56",
          clipPathDesktop: "86 60, 90 60, 90 70, 86 70",
        },
      ]
    },
  ]
}