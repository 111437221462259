import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Arrow from "../../../img/arrow.svg"
import { fetchData, parseDate } from '../../Helpers'
import "./style.scss"
import Spider from '../../components/Halloween'
import EasterEgg from '../../components/EasterEgg'

export default function NotificationsPage({ notificationRef, setPopupNotifications }) {
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    fetchData({
      endpoint: "/message/list",
      data: {
        "limit": 100,
        "offset": 0
      },
      successCallback: resObj => {
        setNotifications(resObj.data.filter(e => e?.content || e?.content?.title))
        const filteredUUIDs = resObj.data.filter(e => !e.seen).map(e => e.uuid)
        if (filteredUUIDs.length !== 0) {
          fetchData({
            endpoint: "/message/seen",
            payload: {
              "message": {
                "uuids": filteredUUIDs
              }
            },
            successCallback: () => {
              setPopupNotifications(prev => prev.map(e => e.seen = {}))
              notificationRef.current = true
            },
            shouldThrowError: false
          })
        }
      },
      controller: controller,
    })
  }, [])

  const onPopUpClick = index => {
    fetchData({
      endpoint: "/message/describe",
      payload: {
        "message": {
          "uuid": `${notifications[index].uuid}`
        }
      },
      successCallback: () => {
        notificationRef.current = true
        let newArray = [...notifications]
        newArray[index].seen = {}
        newArray[index].closed = {}
        setNotifications([...newArray])
      },
      shouldThrowError: false
    })
  }

  return (
    <section className="notification-page">
      <h1>Benachrichtigungen</h1>
      <div className="notifications-wrapper">
        {notifications.sort((a, b) => !!a.seen - !!b.seen || !!a.closed - !!b.closed).map((element, index) => (
          element?.url?.custom?.includes("http://") || element?.url?.custom?.includes("https://") ?
            <a className={`notification ${!element?.seen ? "new" : ""}`} key={index} href={`${element?.url?.custom ?? ""}`}
              target="_blank" rel="noopener noreferrer" onClick={() => onPopUpClick(index)}>
              <div className="type-date">
                <p className="type">{element?.category?.custom}</p>
                <p className="date">{parseDate(element?.created?.date)}</p>
              </div>
              <div className="content">
                <h4 className="title">{element?.content?.title}</h4>
                <p className="desc">{element?.content?.description}</p>
                {!element?.seen && !element?.closed && <div className="new">Neu</div>}
              </div>
              {element?.url === null ? "" : <img src={Arrow} alt="" />}
            </a>
            : element?.url?.custom ?
              <Link className={`notification ${!element?.seen ? "new" : ""}`} key={index} to={`${element?.url?.custom ?? ""}`}
                onClick={() => onPopUpClick(index)}>
                <div className="type-date">
                  <p className="type">{element?.category?.custom}</p>
                  <p className="date">{parseDate(element?.created?.date)}</p>
                </div>
                <div className="content">
                  <h4 className="title">{element?.content?.title}</h4>
                  <p className="desc">{element?.content?.description}</p>
                  {!element?.seen && !element?.closed && <div className="new">Neu</div>}
                </div>
                {element?.url === null ? "" : <img src={Arrow} alt="" />}
              </Link>
              :
              <div className={`notification ${!element?.seen || !element?.closed ? "new" : ""}`} key={index}
                onClick={() => onPopUpClick(index)}>
                <div className="type-date">
                  <p className="type">{element?.category?.custom}</p>
                  <p className="date">{parseDate(element?.created?.date)}</p>
                </div>
                <div className="content">
                  <h4 className="title">{element?.content?.title}</h4>
                  <p className="desc">{element?.content?.description}</p>
                  {!element?.seen && !element?.closed && <div className="new">Neu</div>}
                </div>
                {element?.url === null ? "" : <img src={Arrow} alt="" />}
              </div>
        ))}
        {/* <div className="notification new">
          <div className="type-date">
            <p className="type">AUFGABE</p>
            <p className="date">26.09.2022</p>
          </div>
          <div className="content">
            <h4 className="title">Profil vervollständigen bringt Punkte. </h4>
            <p className="desc">Erzähl uns mehr über dich in deinem Profil und du bekommst sofort 30 Punkte.</p>
            <div className="new">Neu</div>
          </div>
          <img src={Arrow} alt="" />
        </div> */}
        {/* <div className="notification">
          <div className="type-date">
            <p className="type">AUFGABE</p>
            <p className="date">26.09.2022</p>
          </div>
          <div className="content">
            <h4 className="title">Profil vervollständigen bringt Punkte. </h4>
            <p className="desc">Erzähl uns mehr über dich in deinem Profil und du bekommst sofort 30 Punkte.</p>
            <div className="new">Neu</div>
          </div>
          <img src={Arrow} alt="" />
        </div> */}
        <EasterEgg uuid={"fd2c0944-6fa6-44a2-9ab0-11a90c33b719"} />
      </div>

    </section>
  )
}
