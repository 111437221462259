import React, { useState } from 'react'
import Button from '../../../../components/Button'
import PageHeader from '../../../../components/PageHeader'
import { useWindowSize } from '../../../../Helpers'
import useMeasure from 'react-use-measure'

export default function Find({ questions, currentQuestion, setCurrentQuestion, setAnswers }) {
  const [selected, setSelected] = useState([])
  const [imageRef, bounds] = useMeasure()
  const { width } = useWindowSize()
  const SCREEN_XGA = 1020

  const hadnleButtonClick = () => {
    setAnswers(prev => [...prev, {
      question_ident: `q${currentQuestion}`,
      answer_ident: selected
    }])
    setSelected([])
    setCurrentQuestion(prev => prev < questions.content.length - 1 ? ++prev : prev)
  }

  const handlePathClick = (ident) => {
    if (!selected.includes(ident))
      setSelected(prev => [...prev, ident])
  }

  return (
    <section className='game-page find'>
      <PageHeader>NYR promo</PageHeader>
      <div className="game">
        <div className="upper-container">
          <div className="progress">
            {Array(questions.content.length).fill(null).map((_, index) => (
              <div className={`number ${currentQuestion >= index ? "active" : ""}`} key={index}>
                <div><p>{++index}</p></div>
              </div>
            ))}
          </div>
          <div className="grid-wrapper">
            <div className="question">
              <h4>{questions.content[currentQuestion].question}</h4>
            </div>
          </div>
        </div>
        <div className="find-image">
          <Button action={hadnleButtonClick} disabled={selected.length !== questions.content[currentQuestion]?.answers.length}>
            Nächste
          </Button>
          <img src={questions.content[currentQuestion][width < SCREEN_XGA ? "imgMobile" : "imgDesktop"]} alt="" ref={imageRef} />
          <svg viewBox="0 0 100 100" preserveAspectRatio='none' width={bounds.width} height={bounds.height}>
            {questions.content[currentQuestion]?.answers.map((element, index) => (
              <polygon className={`${selected.includes(element.ident) ? "selected" : ""}`} points={element[width < SCREEN_XGA ? "clipPathMobile" : "clipPathDesktop"]}
                key={index} onClick={() => handlePathClick(element.ident)} vectorEffect="non-scaling-stroke" />
            ))}
          </svg>
        </div>
        {questions.dis && <div className='dis'><p>{questions.dis}</p></div>}
      </div>
    </section >
  )
}
