import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../../components/Button'
import PageHeader from '../../../../components/PageHeader'
import CorrectPopup from './CorrectPopup'
import HintPopup from './HintPopup'
import { useWindowSize } from '../../../../Helpers'
import NiceModal from '@ebay/nice-modal-react';

const ERASER_WIDTH = 20
const ERASER_THRESHOLD = 98

export default function Scratch({ questions, currentQuestion, setCurrentQuestion, setAnswers, ident }) {
  const [selected, setSelected] = useState(-1)
  const [hint, setHint] = useState(false)
  const [correctPopup, setCorrectPopup] = useState(false)

  const prevDrawCoordinates = useRef([null, null])
  const canvasRef = useRef(null)
  const ctxRef = useRef(null)
  const [isScratching, setIsScratching] = useState(false)
  const [scratchedPercentage, setScratchedSPercentage] = useState(0)

  const { width } = useWindowSize()

  useEffect(() => {
    if (!canvasRef.current) {
      return
    }
    const canvas = canvasRef.current
    // const dpr = window.devicePixelRatio || 1;
    // let rect = canvas.getBoundingClientRect();
    // canvas.width = rect.width * dpr;
    // canvas.height = rect.height * dpr;
    const ctx = canvas.getContext('2d');
    // ctx.scale(dpr, dpr);
    // const img = new Image()
    // img.onload = () => {
    //   ctx.drawImage(img, 0, 0, canvas.width, 292)
    // }
    // img.src = Initial
    ctxRef.current = ctx
    resetCanvas()

  }, [canvasRef.current, currentQuestion])


  const drawDot = (x, y) => {
    if (prevDrawCoordinates.current.includes(null)) {
      ctxRef.current.beginPath();
      ctxRef.current.arc(x, y, ERASER_WIDTH, 0, 2 * Math.PI, true);
      ctxRef.current.fillStyle = '#000';
      ctxRef.current.globalCompositeOperation = "destination-out";
      ctxRef.current.fill();
      prevDrawCoordinates.current = [x, y]
      return
    }

    ctxRef.current.beginPath();
    ctxRef.current.moveTo(prevDrawCoordinates.current[0], prevDrawCoordinates.current[1]);
    ctxRef.current.lineTo(x, y);
    ctxRef.current.lineWidth = ERASER_WIDTH * 2;
    ctxRef.current.lineCap = 'round';
    ctxRef.current.stroke();
    prevDrawCoordinates.current = [x, y]
  }

  const getPercentage = () => {
    let pixelCount = canvasRef.current.width * canvasRef.current.height;
    let arrayElemsCount = pixelCount * 4;
    let dataArray = ctxRef.current.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height).data;
    let threshold = 0.5;
    let transparentPixelCount = 0;
    for (let i = 3; i < arrayElemsCount; i += 4) {
      let alphaValue = dataArray[i];
      if (alphaValue <= threshold) {
        transparentPixelCount++;
      }
    }
    setScratchedSPercentage((transparentPixelCount / pixelCount) * 100)
  }

  // const debouncedGetPercentage = useCallback(_.debounce(getPercentage, 300), []);

  const startScratch = ({ nativeEvent }) => {
    if (selected === -1) {
      return
    }
    setIsScratching(true)
    const { offsetX, offsetY } = nativeEvent
    drawDot(offsetX, offsetY)
  }

  const stopScratch = () => {
    setIsScratching(false)
    getPercentage()
    prevDrawCoordinates.current = [null, null]
  }

  const scratch = ({ nativeEvent }) => {
    if (!isScratching || selected === -1) {
      return
    }
    const { offsetX, offsetY } = nativeEvent
    drawDot(offsetX, offsetY)
  }

  const startScratchMobile = ({ nativeEvent }) => {
    if (selected === -1) {
      return
    }
    setIsScratching(true)
    const { clientX, clientY } = nativeEvent.targetTouches[0]
    const rect = canvasRef.current.getBoundingClientRect()
    drawDot(clientX - rect.x, clientY - rect.y)
  }

  const scratchMobile = ({ nativeEvent }) => {
    if (!isScratching || selected === -1) {
      return
    }
    const { clientX, clientY } = nativeEvent.targetTouches[0]
    const rect = canvasRef.current.getBoundingClientRect()
    drawDot(clientX - rect.x, clientY - rect.y)
  }

  const resetCanvas = () => {
    const img = new Image()
    img.onload = () => {
      ctxRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      ctxRef.current.globalCompositeOperation = "source-over";
      ctxRef.current.drawImage(img, 0, 0, canvasRef.current.width, canvasRef.current.height)
    }
    img.src = width < 1020 ? questions.content[currentQuestion].templateMobile : questions.content[currentQuestion].templateDesktop
    setScratchedSPercentage(0)
  }

  const nextQuestion = () => {
    setAnswers(prev => [...prev, {
      question_ident: `q${currentQuestion}`,
      answer_ident: questions.content[currentQuestion].answers[selected].ident
    }])
    if (!Object.keys(questions.content).length === currentQuestion) {
      setSelected(-1)
    }
    setCurrentQuestion(prev => prev < questions.content.length - 1 ? ++prev : prev)
  }

  const hadnleButtonClick = () => {
    if (questions.content[currentQuestion].answers[selected].ident !== questions.content[currentQuestion]?.correctAnswer) {
      setHint(true)
      return
    } else {
      if (questions.content[currentQuestion]?.showModalOnCorrect) {
        NiceModal.show("scratch-modal", { currentQuestion: currentQuestion, nextQuestion: nextQuestion, ...questions.content[currentQuestion].modalData })
      } else {
        nextQuestion()
      }
    }
  }

  return (
    <section className={`game-page scratch ${ident}`}>
      <PageHeader>{questions.content[currentQuestion].title ?? "glo+ Quiz"}</PageHeader>
      <div className="wrapper">
        {questions.content[currentQuestion].mobileTopImg && <div className={`mobile-top-img `} style={{ backgroundImage: `url(${questions.staticSideImg ? questions.staticSideImg : questions?.content[currentQuestion]?.img})` }}>
        </div>}
        <div className="game" onMouseUp={stopScratch}>
          <div className="progress">
            {Array(questions.content.length).fill(null).map((_, index) => (
              <div className={`number ${currentQuestion >= index ? "active" : ""}`} key={index}>
                <div><p>{++index}</p></div>
              </div>
            ))}
          </div>
          <div className="answers-background">
            <div className="question">
              <h4 dangerouslySetInnerHTML={{ __html: questions.content[currentQuestion].question }} />
            </div>
            {questions.content[currentQuestion].mobileQuestionImg && <div className="mobile-question-img"
              style={{ backgroundImage: `url(${questions.content[currentQuestion].img})` }}>
            </div>}
            <div className='scratch-wrapper'>
              <div className="canvas-wrapper">
                <canvas width={width < 1020 ? 112 : 88} height={width < 1020 ? 367 : 290} ref={canvasRef}
                  style={selected !== -1 ? { backgroundImage: `url(${questions.content[currentQuestion].answers[selected].img})` } : {}}
                  onMouseDown={startScratch} onTouchStart={startScratchMobile}
                  onMouseUp={stopScratch} onTouchEnd={stopScratch}
                  onMouseMove={scratch} onTouchMove={scratchMobile}
                />
              </div>
              <div className="color-selector">
                {questions.content[currentQuestion].answers.map((element, index) => (
                  <button key={index} onClick={() => { setSelected(index); resetCanvas() }}
                    className={`${selected === index ? "selected" : ""}`}>
                    <img src={element.content} alt="" draggable={false} />
                  </button>
                ))}
              </div>
            </div>
            <HintPopup visible={hint} setClose={() => { setHint(false); resetCanvas(); setSelected(-1) }} content={questions.content[currentQuestion].wrongAnswerInfo} />
            <CorrectPopup visible={correctPopup} setClose={() => { setCorrectPopup(false); nextQuestion() }} content={questions.content[currentQuestion]?.correctAnswerInfo?.content} />
          </div>
          <Button action={hadnleButtonClick} disabled={selected === -1 || hint || scratchedPercentage <= ERASER_THRESHOLD}>{questions.content[currentQuestion]?.buttonCopy ?? "Nächste Frage"}</Button>
        </div>
        <div className={`side-img`} style={{ backgroundImage: `url(${questions.staticSideImg ? questions.staticSideImg : questions?.content[currentQuestion]?.img})` }}>
        </div>
      </div>
    </section>
  )
}
