import React, { useState, useRef, useEffect } from 'react'
// import Arrow from "../../../img/arrow-orange.svg"
// import Unavailable from "./img/unavailable.svg"
// import Tick from "../../../img/tick.svg"
// import HourGlass from "../../../img/hourglass.svg"
// import TickWhite from "../../../img/tick-white.svg"
import "./style.scss"
import { fetchData, getCookie, getModuleValue, parseDate, parseHTMLToObjets, parseHTMLToText, useWindowSize, validateDates } from '../../Helpers'
import { Link } from 'react-router-dom'
import Calendar from "../../../img/calendar.svg"
import Gift from "../../../img/gift.svg"
import ButtonRight from "../../../img/right-button.svg"
import Button from '../../components/Button'
import Copy from "../../../img/copy.svg"
import TickMulicolor from "../../../img/tick-multicolor.svg"
import Dropdown from '../../components/Dropdown'
import Logo from "../../../img/glo-plus.svg"
import Tick from "../../../img/tick-orange.svg"
import DOMPurify from 'dompurify'

const filterMap = {
  all: "Alle",
  active: "Aktiv",
  inactive: "Inaktiv"
}


function Code({ codeView, setCodeView }) {
  return (
    <div className={`code-full ${codeView.visible ? "visible" : ""}`}>
      <div className="upper">
        <img src={Logo} alt="" />
        <button className="exit" onClick={() => setCodeView(prev => ({ ...prev, visible: !prev.visible }))}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.95147 11.6485C10.4201 12.1172 11.1799 12.1172 11.6485 11.6485C12.1172 11.1799 12.1172 10.4201 11.6485 9.95147L7.69706 5.99999L11.6485 2.04853C12.1171 1.5799 12.1171 0.820101 11.6485 0.351472C11.1799 -0.117156 10.4201 -0.117156 9.95147 0.351472L6 4.30294L2.04853 0.351472C1.57991 -0.117157 0.820108 -0.117157 0.35148 0.351472C-0.11715 0.8201 -0.11715 1.5799 0.35148 2.04853L4.30295 5.99999L0.351472 9.95147C-0.117157 10.4201 -0.117157 11.1799 0.351472 11.6485C0.8201 12.1172 1.5799 12.1172 2.04853 11.6485L6 7.69705L9.95147 11.6485Z" fill="white" />
          </svg>
        </button>
      </div>
      <div className="content">
        <div className={`code-wrapper ${codeView.pin ? "pin" : ""}`}>
          <div>
            <p>Code</p>
            <div className="pill">
              <h3>{codeView.code}</h3>
            </div>
          </div>
          {codeView.pin && <div>
            <p>PIN</p>
            <div className="pill">
              <h3>{codeView.pin}</h3>
            </div>
          </div>}
        </div>
        {getModuleValue(codeView?.content?.content?.modules, "bullets", "custom") && <div className='how-to'>
          <h4 className="title">
            {getModuleValue(codeView?.content?.content?.modules, "bullets", "metadata.name")?.length !== 0 ? getModuleValue(codeView?.content?.content?.modules, "bullets", "metadata.name") : "Wie löse ich einen Code ein?"}
          </h4>
          <div className="content-wrapper">
            {parseHTMLToObjets(getModuleValue(codeView?.content?.content?.modules, "bullets", "custom"))
              .map(({ tag, content }, index) => {
                if (content === "" || tag === "" || !tag) {
                  return
                }

                if (tag === "li") {
                  return <div className='flex-list' key={index}>
                    <div className="mark">
                      <img src={Tick} alt="" />
                    </div>
                    <p className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
                  </div>
                }

                if (tag === "p") {
                  return <p key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
                }
              })}
          </div>
          {/* <h4>Wie löse ich einen Code ein?</h4>
          <div className="grid">
            <div className="mark">
              <img src={Tick} alt="" />
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget lorem in aliquam, magna turpis pretium eu laoreet porta.</p>
            <div className="mark">
              <img src={Tick} alt="" />
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget lorem in aliquam, magna turpis pretium eu laoreet porta.</p>
            <div className="mark">
              <img src={Tick} alt="" />
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget lorem in aliquam, magna turpis pretium eu laoreet porta.</p>
          </div> */}
        </div>}
      </div>
    </div>
  )
}

export default function Received() {
  const [showCopyInfo, setShowCopyInfo] = useState(-1)
  const [filter, setFilter] = useState({ filter: "all", expanded: false })
  const [codeView, setCodeView] = useState({ visible: false, code: "", content: [] })
  const [recieved, setRecieved] = useState([])
  const [serverTime, setServerTime] = useState("")

  useEffect(() => {
    const controller = new AbortController()
    fetchData({
      endpoint: "/prize/list",
      payload: {
        "limit": 100,
        "offset": 0,
        "expired": false
      },
      successCallback: resObj => {
        setRecieved(resObj.data.prize);
        setServerTime(resObj.time.date.replace(" ", "T"));
      },
      controller: controller
    })

    return () => {
      controller.abort()
    }
  }, [])

  const filterData = () => {
    if (filter.filter === "all") {
      return [...recieved.filter(e => !validateDates(e.validTo.date, serverTime)), ...recieved.filter(e => validateDates(e.validTo.date, serverTime))]
    }

    return recieved.filter(e => {
      if (filter.filter === "inactive") {
        return validateDates(e.validTo.date, serverTime)
      }
      if (filter.filter === "active") {
        return !validateDates(e.validTo.date, serverTime)
      }
    }).sort((a, b) => validateDates(a.validTo.date, serverTime) === validateDates(b.validTo.date, serverTime) ? validateDates(a.validTo.date, serverTime) - 1 : 1)
  }


  return (
    <section className='rewards-received'>
      <div className="page-header-nav">
        <h1>Prämienshop</h1>
        <Link to="/pramienshop" className="link">
          Katalog
        </Link>
        <div className="link active">
          Eingelöst
        </div>
      </div>
      <div className="desktop-wrapper">
        <div className="filter">
          <h4>Filter</h4>
          <Dropdown
            summary={
              <>{filterMap[filter.filter]}</>
            }
            details={
              <>
                {Object.values(filterMap).map((element, index) => (
                  <button key={index} onClick={() => setFilter(prev => ({ expanded: !prev.expanded, filter: Object.keys(filterMap)[index] }))}
                    className={filterMap[filter.filter] === element ? "active" : ""}>{element}</button>
                ))}
              </>
            }
            setOpen={() => setFilter(prev => ({ ...prev, expanded: !prev.expanded }))}
            open={filter.expanded}
            tension={150}
            friction={30}
          />
        </div>
        <div className="rewards">
          {filterData().map((element, index) => {
            const expired = validateDates(element.validTo.date, serverTime)

            return (
              <div className='card' key={index}>
                <div className="image">
                  <img src={element?.catalogue?.thumbnail?.file?.url} alt="" />
                </div>
                <div className="content">
                  <div className="title">{element?.catalogue?.content?.title}</div>
                  <div className="button-wrapper">
                    <Button action={() => setCodeView({ visible: true, code: element.code, ident: element.catalogue.ident, pin: element.pin, content: element.catalogue })} disabled={expired}>
                      <div className="inner">
                        {!expired && <img src={Gift} alt="" />}
                        <p>Code: {expired ? "BENUTZT" : element.code}</p>
                        {!expired && <img src={ButtonRight} alt="" />}
                      </div>
                    </Button>
                    <Button action={() => {
                      if (showCopyInfo === -1) {
                        navigator.clipboard.writeText(element.code)
                        setShowCopyInfo(index)
                        setTimeout(() => setShowCopyInfo(-1), 1500)
                      }
                    }}
                      disabled={expired}>
                      <img src={Copy} alt="" />
                    </Button>
                  </div>
                  <div className={`expires ${element.pin ? "pin" : ""}`}>
                    <img src={Calendar} alt="" />
                    <p>Verfallsdatum: <strong>{parseDate(element.validTo.date)}</strong></p>
                    {element.pin && <p className='pin'>
                      PIN: <strong>{element.pin}</strong>
                    </p>}
                  </div>
                  <div className={`copy-info ${showCopyInfo === index ? "show" : ""}`}>
                    <img src={TickMulicolor} alt="" />
                    <p>Code kopiert</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Code codeView={codeView} setCodeView={setCodeView} />
    </section>
  )
}
