import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../../components/Button'
import PageHeader from '../../../../components/PageHeader'
import CorrectPopup from './CorrectPopup'
import HintPopup from './HintPopup'

export default function Quiz({ questions, currentQuestion, setCurrentQuestion, setAnswers, ident }) {
  const [selected, setSelected] = useState(-1)
  const [hint, setHint] = useState(false)
  const [correctPopup, setCorrectPopup] = useState(false)
  const videoRef = useRef(null)
  const videoRefMobile = useRef(null)

  const nextQuestion = () => {
    setAnswers(prev => [...prev, {
      question_ident: `q${currentQuestion}`,
      answer_ident: questions.content[currentQuestion].answers[selected].ident
    }])
    setSelected(-1)
    setCurrentQuestion(prev => prev < questions.content.length - 1 ? ++prev : prev)
  }

  const hadnleButtonClick = () => {
    if (questions.content[currentQuestion].answers[selected].ident !== questions.content[currentQuestion]?.correctAnswer) {
      setHint(true)
      return
    }
    if (questions.content[currentQuestion]?.correctAnswerInfo?.content) {
      setCorrectPopup(true)
    } else {
      nextQuestion()
    }
  }

  useEffect(() => {
    videoRef.current?.load()
    videoRefMobile.current?.load()
  }, [questions.content[currentQuestion]?.video])

  return (
    <section className={`game-page quiz ${ident}`}>
      <PageHeader>{questions.content[currentQuestion].title ?? "glo+ Quiz"}</PageHeader>
      <div className="wrapper">
        {questions.content[currentQuestion].mobileTopImg && <div className={`mobile-top-img  ${questions.content[currentQuestion]?.video ? "video-wrapper" : ""}`} style={{ backgroundImage: `url(${questions?.content[currentQuestion]?.img})` }}>
          {Boolean(questions.content[currentQuestion]?.video) && <video controls={true} controlsList="nodownload" name="media" ref={videoRefMobile}>
            <source src={questions.content[currentQuestion]?.video} type="video/mp4" />
          </video>}
          {Boolean(questions.content[currentQuestion]?.imgText) && <p style={{ color: questions.content[currentQuestion].imgText.color }}>
            {questions.content[currentQuestion].imgText.text}
          </p>}
        </div>}
        <div className="game">
          <div className="progress">
            {Array(questions.content.length).fill(null).map((_, index) => (
              <div className={`number ${currentQuestion >= index ? "active" : ""}`} key={index}>
                <div><p>{++index}</p></div>
              </div>
            ))}
          </div>
          <div className="question">
            <h4>{questions.content[currentQuestion].question}</h4>
          </div>
          {questions.content[currentQuestion].mobileQuestionImg && <div className="mobile-question-img"
            style={{ backgroundImage: `url(${questions.content[currentQuestion].img})` }}>
            {Boolean(questions.content[currentQuestion]?.imgText) && <p style={{ color: questions.content[currentQuestion].imgText.color }}>
              {questions.content[currentQuestion].imgText.text}
            </p>}
          </div>}
          <div className="answers-background">
            <div className={`answers ${questions.content[currentQuestion].type} ${questions.content[currentQuestion]?.forceSizeBySideMobile ? "side-by-side" : ""}`}>
              {questions.content[currentQuestion].answers.map((element, index) => (
                ["button", "image", "button-image", "button-image-text"].includes(questions.content[currentQuestion].type) &&
                <button onClick={() => setSelected(index)} className={selected === index ? "selected" : ""} key={index} disabled={hint}>
                  <div className="inner">
                    {"button" === questions.content[currentQuestion].type &&
                      <p>{element.content}</p>}
                    {"button-image" === questions.content[currentQuestion].type &&
                      <div className='button-image-wrapper'>
                        <img src={element.img} alt="" />
                        <p>{element.content}</p>
                      </div>}
                    {"button-image-text" === questions.content[currentQuestion].type &&
                      <div className='button-image-text-wrapper'>
                        <img src={element.img} alt="" />
                        <p>{element.content}</p>
                      </div>}
                  </div>
                </button>
              ))}
            </div>
            <HintPopup visible={hint} setClose={() => setHint(false)} content={questions.content[currentQuestion].wrongAnswerInfo} />
            <CorrectPopup visible={correctPopup} setClose={() => { setCorrectPopup(false); nextQuestion() }} content={questions.content[currentQuestion]?.correctAnswerInfo?.content} />
          </div>
          <Button action={hadnleButtonClick} disabled={selected === -1 || hint}>{questions.content[currentQuestion]?.buttonCopy ?? "Nächste Frage"}</Button>
        </div>
        <div className={`side-img ${questions.content[currentQuestion]?.video ? "video-wrapper" : ""}`} style={{ backgroundImage: `url(${questions?.content[currentQuestion]?.img})` }}>
          {Boolean(questions.content[currentQuestion]?.video) && <video controls={true} controlsList="nodownload" name="media" ref={videoRef}>
            <source src={questions.content[currentQuestion]?.video} type="video/mp4" />
          </video>}
          {Boolean(questions.content[currentQuestion]?.imgText) && <p style={{ color: questions.content[currentQuestion].imgText.color }}>
            {questions.content[currentQuestion].imgText.text}
          </p>}
        </div>
      </div>
    </section>
  )
}
