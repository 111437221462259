import React, { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom"
import "./style.scss"
import PageHeader from "../../components/PageHeader"
import Pointer from "../../../img/pointer-red.svg"
import { fetchData, getCookie, levelBreakPoints, levelMapper, useWindowSize } from '../../Helpers'
import { ReactComponent as Level1 } from "./img/level-1.svg"
import { ReactComponent as Level2 } from "./img/level-2.svg"
import { ReactComponent as Level3 } from "./img/level-3.svg"
import { ReactComponent as Level4 } from "./img/level-4.svg"
import { ReactComponent as Arrow } from "./img/arrow.svg"
import ArrowReward from "../../../img/arrow-orange.svg"
import { useUserState } from '../../UserContext'
import Spider from '../../components/Halloween'
import EasterEgg from '../../components/EasterEgg'

const ranks = {
  basic: {
    name: "Basic",
    desc: "Anfangsstatus"
  },
  advanced: {
    name: "ADVANCED",
    desc: "Ab 100 Punkten"
  },
  professional: {
    name: "PROFESSIONAL",
    desc: "Ab 300 Punkten"
  },
  master: {
    name: "MASTER",
    desc: "Ab 1000 EP"
  }
}

const RankButton = ({ available, action, Icon, title, desc, active }) => {
  const { width } = useWindowSize()
  const HD = 1280
  const activeRef = useRef(null)

  useEffect(() => {
    if (activeRef.current !== null) {
      document.querySelector('.rewards').style.setProperty("--offsetTop", `${activeRef.current.offsetTop - 4}px`)
    }
  }, [activeRef.current, active])

  return (
    <div className={(available ? "rank-button available" : "rank-button") + " " + (active ? "active" : "")} onClick={() => { action() }} ref={active ? activeRef : null}>
      {width >= HD && <div className='icon'><Icon /></div>}
      <div className='text'>
        <h4>{title}</h4>
        <p>{desc}</p>
      </div>
      {width >= HD ?
        active && <Arrow className="arrow" />
        :
        <Arrow className="arrow" />
      }
    </div>
  )
}

const ProgressBarMobile = ({ points, limit, show, level }) => {
  const [innerHeight, setInnerHeight] = useState(0)
  const progressRef = useRef(null)

  useEffect(() => {
    const calculateProgress = () => {
      let precentage = 0
      precentage += Math.min(100, points) / 100 * 0.3333

      if (points > 100) {
        precentage += Math.min(200, points - 100) / 200 * 0.3333
      }

      if (points > 300) {
        precentage += Math.min(700, points - 300) / 700 * 0.3333
      }

      setInnerHeight(precentage * progressRef.current.clientHeight)
    }

    calculateProgress()
  }, [show])

  return (
    <div className='progress-bar-mobile' ref={progressRef}>
      <div className="progress-bar-mobile-inner" style={{ height: `${show ? innerHeight : 0}px` }}>
        <div className="pointer" style={{ top: `${show ? innerHeight : 0}px` }}>
          <img src={Pointer} alt="" className="pointer-img" />
          <div className="text">
            <h4>{points}</h4>
            <p>Status<br />Punkte</p>
          </div>
        </div>
      </div>
      <div className="progress-breakpoints">
        <div className={`breakpoint first ${0 <= points ? "active" : ""} ${level === "basic" ? "highlighted" : ""}`}><span>0</span><Level1 /></div>
        <div className={`breakpoint second ${levelBreakPoints.basic <= points ? "active" : ""} ${level === "advanced" ? "highlighted" : ""}`}><span>100</span><Level2 /></div>
        <div className={`breakpoint third ${levelBreakPoints.advanced <= points ? "active" : ""} ${level === "professional" ? "highlighted" : ""}`}><span>300</span><Level3 /></div>
        <div className={`breakpoint fourth ${levelBreakPoints.professional <= points ? "active" : ""} ${level === "master" ? "highlighted" : ""}`}><span>1000</span><Level4 /></div>
      </div>
    </div>
  )
}

const ProgressBarDesktop = ({ points, limit, show, level }) => {
  const [innerWidth, setInnerWidth] = useState(0)
  const progressRef = useRef(null)

  useEffect(() => {
    const calculateProgress = () => {

      let precentage = 0
      precentage += Math.min(100, points) / 100 * 0.3333

      if (points > 100) {
        precentage += Math.min(200, points - 100) / 200 * 0.3333
      }

      if (points > 300) {
        precentage += Math.min(700, points - 300) / 700 * 0.3333
      }

      setInnerWidth(precentage * progressRef.current.clientWidth)
    }

    calculateProgress()
  }, [show])

  return (
    <div className='progress-bar-desktop' ref={progressRef}>
      <div className="progress-bar-desktop-inner" style={{ width: `${show ? innerWidth : 0}px` }}>
        <div className="pointer" style={{ left: `${show ? innerWidth : 0}px` }}>
          <img src={Pointer} alt="" className="pointer-img" />
          <div className="text">
            <h4>{points}</h4>
            <p>Status Punkte</p>
          </div>
        </div>
      </div>
      <div className="progress-breakpoints">
        <div className={`breakpoint first ${0 <= points ? "active" : ""} ${level === "basic" ? "highlighted" : ""}`}><span className="rank-title first">Basic</span><span>0</span><Level1 /></div>
        <div className={`breakpoint second ${levelBreakPoints.basic <= points ? "active" : ""} ${level === "advanced" ? "highlighted" : ""}`}><span className="rank-title">Advanced</span><span>100</span><Level2 /></div>
        <div className={`breakpoint third ${levelBreakPoints.advanced <= points ? "active" : ""} ${level === "professional" ? "highlighted" : ""}`}><span className="rank-title">Professional</span><span>300</span><Level3 /></div>
        <div className={`breakpoint fourth ${levelBreakPoints.professional <= points ? "active" : ""} ${level === "master" ? "highlighted" : ""}`}><span className="rank-title last">Master</span><span>1000</span><Level4 /></div>
      </div>
    </div>
  )
}

export default function RankInfo() {
  const [show, setShow] = useState(false)
  const { width } = useWindowSize()
  const HD = 1280
  const { userDetails } = useUserState()
  const [selectedRank, setSelectedRank] = useState(width >= HD ? levelMapper.get(userDetails.level) : null)
  const [awards, setAwards] = useState([])
  console.log(selectedRank)

  if (width >= HD && selectedRank === null) {
    setSelectedRank(levelMapper.get(userDetails.level))
  }

  useEffect(() => {
    // const getRewards = async () => {
    //   const response = await fetch(process.env.REACT_APP_API_URL + "/prize/catalogue/list", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + (getCookie("loyalty_sso_session_id") ?? JSON.parse(sessionStorage.getItem('token')).uuid),
    //     },
    //     body: JSON.stringify({
    //       "limit": 100,
    //       "offset": 0,
    //       "expired": false
    //     })
    //   })
    //   const awards = await response.json()
    //   if (awards.status.success) {
    //     setAwards(awards.data.catalogue)
    //   } else {
    //     showErrorPopup(translateError(awards.data.error), awards.meta.ts)
    //   }
    // }
    // getRewards()
    const controller = new AbortController()
    fetchData({
      endpoint: "/prize/catalogue/list",
      payload: {
        "limit": 100,
        "offset": 0,
        "expired": false
      },
      successCallback: resObj => setAwards(resObj.data.catalogue),
      controller: controller
    })

    setTimeout(() => { setShow(true) }, 800)

    return () => {
      controller.abort()
    }
  }, [])

  const hiddenRewards = ["join"]
  
  const filteredRewards = awards.filter(reward => levelMapper.get(reward.level) === selectedRank && reward.content && !hiddenRewards.includes(reward.ident)).slice(0, 5);

  return (
    <section className="rank-info">
      <PageHeader>STATUS-VORTEILE</PageHeader>

      <div className="desktop-wrapper">
        <div className="main-text">
          <div>
            <p>Erhöhe deinen Status, indem du Aufgaben erfüllst und easy Punkte sammelst. Mit jedem neuen Status schaltest du weitere Belohnungen und kostenlose Preise im Shop frei.</p>
            <p>Mitmachen lohnt sich – siehe unten!</p>
            <p>Keine Sorge! Dein Status fällt nicht, wenn du deine Punkte gegen Prämien eintauscht.</p>
            <EasterEgg uuid={"cba60cca-2be5-4456-916b-063cb9b457ea"} />
          </div>
          {width >= HD && <div className="progress-bar">
            <ProgressBarDesktop points={userDetails.status} limit="1000" show={show} level={levelMapper.get(userDetails.level)} />
          </div>}
        </div>
        <div className="mobile-progress">
          {width < HD && <div className="progress-bar">
            <ProgressBarMobile points={userDetails.status} limit="1000" show={show} level={levelMapper.get(userDetails.level)} />
          </div>}
          <div className="rank-info-inner">
            <p className="available">Jetzt im Prämienkatalog</p>
            <div>
              {0 <= userDetails.status && <RankButton title="Basic" desc="Anfangsstatus" active={selectedRank === "basic"} available={0 <= userDetails.status} Icon={Level1} action={() => { if (true) setSelectedRank("basic") }} />}
              {levelBreakPoints.basic <= userDetails.status && <RankButton title="ADVANCED" desc="Ab 100 Punkten" active={selectedRank === "advanced"} available={levelBreakPoints.basic <= userDetails.status} Icon={Level2} action={() => { if (true) setSelectedRank("advanced") }} />}
              {levelBreakPoints.advanced <= userDetails.status && <RankButton title="PROFESSIONAL" desc="Ab 300 Punkten" active={selectedRank === "professional"} available={levelBreakPoints.advanced <= userDetails.status} Icon={Level3} action={() => { if (true) setSelectedRank("professional") }} />}
              {levelBreakPoints.professional <= userDetails.status && <RankButton title="MASTER" desc="Ab 1000 EP" active={selectedRank === "master"} available={levelBreakPoints.professional <= userDetails.status} Icon={Level4} action={() => { if (true) setSelectedRank("master") }} />}
            </div>
            <p className="other">Weitere status</p>
            <div>
              {levelBreakPoints.basic > userDetails.status && <RankButton title="ADVANCED" desc="Ab 100 Punkten" active={selectedRank === "advanced"} available={levelBreakPoints.basic < userDetails.status} Icon={Level2} action={() => { if (true) setSelectedRank("advanced") }} />}
              {levelBreakPoints.advanced > userDetails.status && <RankButton title="PROFESSIONAL" desc="Ab 300 Punkten" active={selectedRank === "professional"} available={levelBreakPoints.advanced < userDetails.status} Icon={Level3} action={() => { if (true) setSelectedRank("professional") }} />}
              {levelBreakPoints.professional > userDetails.status && <RankButton title="MASTER" desc="Ab 1000 EP" active={selectedRank === "master"} available={levelBreakPoints.professional < userDetails.status} Icon={Level4} action={() => { if (true) setSelectedRank("master") }} />}
            </div>
          </div>
          {width >= HD && <div className={"rewards " + selectedRank}>
            <div className="rewards-inner">
              <h3>Beispielpreise</h3>
              <div className='rewards-list'>
                {filteredRewards.map((element, index) => (
                  <Link to={`/pramienshop/einlosen/${element?.ident}`} className='card-link' key={index}>
                    <div className={`card ${["glofree", "glopro", "glomaster", "gloadv"].includes(element?.ident) ? "free" : ""}`}>
                      <div className="image">
                        <img src={element?.thumbnail?.file?.url} alt="" />
                      </div>
                      <div className="content">
                        {/* <div className={`upper ${element.unavailable ? "unavailable" : ""}`}>
                          <div className={`points`}>
                            {element?.basePrice}p
                          </div>
                        </div> */}
                        <h4 className="title">
                          {element?.content?.title}
                        </h4>
                        <div className="arrow">
                          <img src={ArrowReward} alt="" />
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
                <div className="see-more">
                  <Link to={"/pramienshop"}>
                    <div className="flex-wrapper">
                      Mehr sehen <img src={ArrowReward} alt="" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
      {width < HD && <div className={selectedRank !== null ? "rewards open" : "rewards"}>
        <div className='cover' onClick={() => { setSelectedRank(null) }}></div>
        <div className="rewards-inner">
          <PageHeader customBack={() => { setSelectedRank(null) }}>Katalog der Auszeichnungen</PageHeader>
          <div className="rank-title">
            <div className='text'>
              <h4>{ranks[selectedRank]?.name}</h4>
              <p>{ranks[selectedRank]?.desc}</p>
            </div>
          </div>
          <div className='rewards-list'>
            {filteredRewards.map((element, index) => (
              <Link to={`/pramienshop/einlosen/${element?.ident}`} className='card-link' key={index}>
                <div className={`card ${["glofree", "glopro", "glomaster", "gloadv"].includes(element?.ident) ? "free" : ""}`}>
                  <div className="image">
                    <img src={element?.thumbnail?.file?.url} alt="" />
                  </div>
                  <div className="content">
                    {/* <div className={`upper ${element.unavailable ? "unavailable" : ""}`}>
                      <div className={`points`}>
                        {element?.basePrice}p
                      </div>
                    </div> */}
                    <h4 className="title">
                      {element?.content?.title}
                    </h4>
                    <div className="arrow">
                      <img src={ArrowReward} alt="" />
                    </div>
                  </div>
                </div>
              </Link>
            ))}
            <div className="see-more">
              <Link to={"/pramienshop"}>
                <div className="flex-wrapper">
                  Mehr sehen <img src={ArrowReward} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>}
    </section>
  )
}
