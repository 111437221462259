import React from 'react'
import Modal from "../index"
import Dislike from "./img/dislike.svg"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import { useNavigate } from 'react-router-dom';

export default NiceModal.create(() => {
  const modal = useModal();
  const navigate = useNavigate();

  return (
    <Modal isOpen={modal.visible} onClose={() => { modal.hide(); navigate("/aufgaben") }}
      onAfterClose={() => modal.remove()} barIcon={Dislike}
      innerClass={"correct-answer-modal"}>
      <h3 className="upper">Schade!</h3>
      <p className="sub">Nächstes Mal klappt‘s bestimmt!<br />Du kannst das Quiz nochmal spielen. Du hast immer noch die Chance auf:</p>
      <div className="card wrong-answer">
        <p className="points">
          <span>{modal?.args?.payout ?? ""}</span> Punkte
        </p>
      </div>

      <div className="button-wrapper">
        <Button action={() => { modal.hide(); navigate(`/aufgaben/${modal?.args?.ident}`) }}>
          Nochmal quizzen
        </Button>
        <Button action={() => { modal.hide(); navigate("/aufgaben") }} btnClass="white">
          Zur Aufgabenübersicht
        </Button>
      </div>
    </Modal >
  );
});
