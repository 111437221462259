import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import _ from 'lodash';
import BackOrange from "../img/back-orange.svg"
import "./style.scss";
import { fetchData, sendEvent, useWindowSize } from "../../../Helpers";
import Button from "../../../components/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import DOMPurify from 'dompurify';
import { ReactComponent as Arrow } from "../../../..//img/arrow-orange.svg"

import "swiper/css";
import "swiper/css/pagination"

import SwiperCore, {
  Mousewheel, Pagination, Navigation
} from 'swiper';
import { useUserState, getUserDetails, useUserDispatch } from "../../../UserContext";
import { Link } from "react-router-dom";

SwiperCore.use([Mousewheel, Pagination, Navigation]);

const ArticlePage = () => {
  const { width } = useWindowSize()
  const SCREEN_XGA = 1020;
  const { slug } = useParams()
  const navigate = useNavigate()
  const [article, setArticle] = useState({})
  const { userDetails } = useUserState()
  const dispatch = useUserDispatch()
  const [sortedArticles, setSortedArticles] = useState([])
  const [buttonLoading, setButtonLoading] = useState(false)

  function sendReelTalkEvent() {
    setButtonLoading(true)
    sendEvent("REELTALK")
    setTimeout(() => {
      getUserDetails(dispatch)
    }, 500)
  }

  const getArticle = () => {
    const params = {
      "article": {
        "slug": DOMPurify.sanitize(slug)
      }
    }

    fetchData({
      endpoint: "/article/describe",
      payload: params,
      successCallback: resObj => {
        const isExternal = _.filter(resObj.data.content?.modules, { type: "external_url" })[0] ? true : false
        if (isExternal) {
          window.location.replace(_.filter(resObj.data.content?.modules, { type: "external_url" })[0].custom)
        } else {
          setArticle(resObj.data)
        }
      },
    })
  }

  useEffect(() => {
    const getNews = async () => {
      fetchData({
        endpoint: "/article/list",
        payload: {
          "limit": 5,
          "offset": 0,
          "expired": false
        },
        successCallback: resObj => {
          setSortedArticles(resObj.data.filter(e => e.content && e.slug !== slug && e.slug !== "x-mas-specials-your-way"))
        },
        shouldThrowError: false
      })
    }

    setTimeout(() => { getArticle(); getNews() }, 300)
    //eslint-disable-next-line
  }, [userDetails])

  const articleImage = _.filter(article.content?.modules, { type: "hero_image" })[0]
  const articleImageMobile = _.filter(article.content?.modules, { type: "thumbnail" })[0]
  const articleIntro = _.filter(article.content?.modules, { type: "intro" })[0]?.custom || ""
  const articleModules = _.filter(article.content?.modules, (mod) => {
    return mod.type !== "hero_image" && mod.type !== "thumbnail" && mod.type !== "intro"
  })

  return (
    <section className={`article-page ${slug}`}>
      <div className="article-top" style={{ backgroundImage: "url(" + (width < SCREEN_XGA ? (articleImageMobile ? articleImageMobile?.file?.url : articleImage?.file?.url) : articleImageMobile?.file?.url) + ")" }}>
        <div className="image-cover"></div>
        <div className="article-container">
          <div className="go-back" onClick={() => navigate(-1)}><img src={BackOrange} alt="Back" /></div>
          <div className="article-data">
          </div>
          <h1 className={DOMPurify.sanitize(article.content?.title) === "JETZT ABSAHNEN: Hyper X2 x Bonnie Strange Limited Edition" ? "custom-size-title" : ""} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content?.title) }}></h1>
        </div>
      </div>
      <div className="article-container">
        <div className="article-head">
          <div className="intro-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(articleIntro) }}></div>
        </div>
        <div className="article-content">
          {articleModules.map((module, i) => {
            const forceMobile = module?.metadata?.forceMobile ? "force-mobile" : ""
            const highlightType = module?.metadata?.highlighted === "border" ? "with-border" : module?.metadata?.highlighted === "background" ? "highlighted" : ""

            switch (module.type) {
              case 'text':
                return <div key={i} className={"article-text " + highlightType} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.custom) }}></div>
              case 'text-cta':
                return <div key={i} className={module.metadata?.highlighted ? "article-text-cta highlighted" : "article-text-cta"}>
                  <div className="article-text-cta-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.custom) }}></div>
                  <div className="article-text-cta-buttons">
                    {module.metadata?.name_1 && <Button href={module.metadata?.name_1} btnClass={module.metadata?.color_1 === "orange" ? "orange" : "white"}>{module.metadata?.url_1}</Button>}
                    {module.metadata?.name_2 && <Button href={module.metadata?.name_2} btnClass={module.metadata?.color_2 === "orange" ? "orange" : "white"}>{module.metadata?.url_2}</Button>}
                    {module.metadata?.name_3 && <Button href={module.metadata?.name_3} btnClass={module.metadata?.color_3 === "orange" ? "orange" : "white"}>{module.metadata?.url_3}</Button>}
                  </div>
                </div>
              case 'image-text':
                return (
                  <div key={i} className={"article-columns with-image " + (module.metadata?.layout === 'left' ? "text-left " : "text-right ") + (module.metadata?.highlighted ? "highlighted" : "")}>
                    <div className="article-column-image"><img src={module.file?.url} alt={module.metadata?.alt} /><p className="article-image-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.metadata?.imgDesc) }}></p></div>
                    <div className="article-column-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.custom) }}></div>
                  </div>
                )
              case 'disclaimer':
                return (
                  <div key={i} className="disclaimer" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.custom) }}></div>
                )
              case 'gallery':
                return (
                  <div key={i}>
                    {module.custom && <h3 className="gallery-title">{module.custom}</h3>}
                    <div className={(module.metadata?.images?.length > 2 ? "article-gallery highlighted " : "article-gallery ") + (module.metadata?.layout === "left" ? "from-left" : "from-right")}>
                      {module.metadata?.images?.map((img, j) => {
                        return (
                          <div key={j} className="article-gallery-image"><img src={img.url} alt={""} /></div>
                        )
                      })}
                    </div>
                  </div>
                )
              case 'logo-list':
                return (
                  <div key={i}>
                    {module.custom && <h3 className="logo-title">{module.custom}</h3>}
                    <div className={"logo-list"}>
                      {module.metadata?.images?.map((img, j) => {
                        return (
                          <div key={j} className="logo-image"><img src={img.url} alt={""} /></div>
                        )
                      })}
                    </div>
                  </div>
                )
              case 'award':
                return (
                  <div key={i} className={"award " + (module.metadata?.layout === 'left' ? "text-left" : "text-right")}>
                    <div className="award-column-image"><img src={module.file?.url} alt={module.metadata?.alt} /><p className="award-image-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.metadata?.imgDesc) }}></p></div>
                    <div className="award-column-text">
                      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.custom) }}></div>
                      {module.metadata?.buttonUrl && <Button href={module.metadata?.buttonUrl} btnClass={"transparent"}>{module.metadata?.buttonCopy}</Button>}
                    </div>
                  </div>
                )
              case 'timer-image-text':
                return (
                  <div key={i} className={"article-columns with-image highlighted " + (module.metadata?.layout === 'left' ? "text-left " : "text-right ")}>
                    <div className="article-column-image"><img src={module.file?.url} alt={module.metadata?.alt} /><p className="article-image-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.metadata?.imgDesc) }}></p></div>
                    <div className="article-column-text">
                      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.custom) }}></div>
                      {/* <Timer from={module.metadata.dateFrom} to={module.metadata.dateTo} /> */}
                    </div>
                  </div>
                )
              case 'cyclic-timer-image-text':
                return (
                  <div key={i} className={"article-columns with-image highlighted " + (module.metadata?.layout === 'left' ? "text-left " : "text-right ")}>
                    <div className="article-column-image"><img src={module.file?.url} alt={module.metadata?.alt} /><p className="article-image-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.metadata?.imgDesc) }}></p></div>
                    <div className="article-column-text">
                      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.custom) }}></div>
                      {/* <CyclicTimer/> */}
                    </div>
                  </div>
                )
              case 'image':
                return (
                  <div key={i} className={`article-image ${module.metadata.fullWidth ? "full-width" : ""}`}>
                    <img src={module.file?.url} alt={module.metadata?.alt} />
                    <p className="article-image-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.metadata?.imgDesc) }}></p>
                  </div>
                )
              case 'columns':
                return (
                  <div key={i} className={"article-columns " + highlightType + " " + forceMobile}>
                    <div className="article-column" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.columns[0].custom) }}></div>
                    <div className="article-column" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.columns[1].custom) }}></div>
                  </div>
                )
              case 'columns-3':
                return (
                  <div key={i} className={"article-columns-3 " + highlightType + " " + forceMobile}>
                    <div className="article-column" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.columns[0].custom) }}></div>
                    <div className="article-column" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.columns[1].custom) }}></div>
                    <div className="article-column" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(module.columns[2].custom) }}></div>
                  </div>
                )
              case 'button':
                return (
                  <div key={i} className="article-button">
                    {slug === "verpasse-nicht-unser-reeltalk" && module.custom === "#" ?
                      <Button action={() => sendReelTalkEvent()} disabled={buttonLoading || userDetails.reelTalk}>{module.metadata?.name}</Button>
                      :
                      <Button href={module.custom}>{module.metadata?.name}</Button>
                    }
                  </div>
                )
              case 'video':
                return (
                  <div key={i} className={"article-video " + (module.metadata?.highlighted ? "highlighted" : "")}>
                    <div className="wrapper">
                      <video width={720} controls>
                        <source src={module.file?.url} type="video/mp4" />
                        Twoja przeglądarka nie obsługuje odtwarzania wideo.
                      </video>
                    </div>
                  </div>
                )
              case 'video-src':
                return (
                  <div key={i} className={"article-video " + (module.metadata?.highlighted ? "highlighted" : "")}>
                    <div className="wrapper">
                      <video width={720} controls>
                        <source src={module?.metadata?.altSrc} type="video/mp4" />
                        Twoja przeglądarka nie obsługuje odtwarzania wideo.
                      </video>
                    </div>
                  </div>
                )
              default:
                return null
            }
          })}
        </div>
      </div>
      <div className="read-more">
        <div className="read-more-container">
          <h3>More</h3>
          <Swiper pagination={false} navigation={false} className={"swiper-awards visible"}
            breakpoints={{
              0: {
                spaceBetween: 16,
                slidesPerView: "auto"
              },
              1280: {
                spaceBetween: 16,
                slidesPerView: 4
              }
            }}
          >
            {sortedArticles.length > 0 && sortedArticles?.map((element, index) => (
              <SwiperSlide width={320} key={index}>
                <Link key={index} className={`award`} to={`/article/${DOMPurify.sanitize(element.slug)}`}>
                  <div className="img" style={{ backgroundImage: `url( ${(element?.thumbnail?.file?.url ? element?.thumbnail?.file?.url : element?.hero_image?.file?.url)} )` }}>
                  </div>
                  <div className="content">
                    <div className="title">
                      {element.content?.title}
                    </div>
                    <div className="desc">
                      {element.content?.description}
                    </div>
                    <div className="arrow">
                      <Arrow />
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default ArticlePage