import SideImg from "./img/side-img.jpg"
import orange from "./img/orange.png"
import orangeTile from "./img/orangeTile.jpg"
import red from "./img/red.png"
import redTile from "./img/redtile.jpg"
import purple from "./img/purple.png"
import purpleTile from "./img/purpleTile.jpg"



export const questions = {
  staticSideImg: SideImg,
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/heat-sticks/veo/?utm_source=Glo%2B&utm_medium=Article&utm_campaign=Veo",
    copy: "veo™ kaufen"
  },
  content: [
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "The veo™ Artist",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: false,
      question: "veo™ scarlet click",
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: orangeTile,
          img: orange
        },
        {
          ident: "b",
          content: redTile,
          img: red
        },
        {
          ident: "c",
          content: purpleTile,
          img: purple
        },
      ]
    },
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "The veo™ Artist",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: false,
      question: "veo™ Violet click",
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: orangeTile,
          img: orange
        },
        {
          ident: "b",
          content: redTile,
          img: red
        },
        {
          ident: "c",
          content: purpleTile,
          img: purple
        },
      ]
    },
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "The veo™ Artist",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: false,
      question: "veo™ sunset click",
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: orangeTile,
          img: orange
        },
        {
          ident: "b",
          content: redTile,
          img: red
        },
        {
          ident: "c",
          content: purpleTile,
          img: purple
        },
      ]
    },

  ]
}