import React, { useEffect, useState } from 'react'
import Button from '../../../components/Button'
import PageHeader from '../../../components/PageHeader'
import "./style.scss"
import { useParams } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html';
import * as DOMPurify from 'dompurify';
import { fetchData, getCookie, getModuleValue, sendEvent } from '../../../Helpers'
import { showErrorPopup, translateError } from '../../../components/ErrorHandling'


export default function IntroTemplate() {
  const [content, setContent] = useState({})
  const { ident } = useParams();

  useEffect(() => {
    const controller = new AbortController()
    fetchData({
      endpoint: "/activation/describe",
      payload: {
        "activation": {
          "ident": DOMPurify.sanitize(ident),
          "withSolutions": false
        }
      },
      successCallback: resObj => setContent(resObj.data),
      controller: controller
    })

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <section className='intro-template'>
      <PageHeader>{content?.content?.title}</PageHeader>
      <div className="wrapper">
        <div className="image">
          <img src={getModuleValue(content?.content?.modules, "hero_image", "file.url")} alt="" />
        </div>
        <div className="content">
          <div className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getModuleValue(content?.content?.modules, "intro", "custom")) }}>
          </div>
          <Button href={DOMPurify.sanitize(`/aufgaben/${ident}/spiel`) ?? "/"} action={() => sendEvent(`${ident}_START`)} state={{ ...content }} disabled={content.completed || content.expired}>{content.completed ? "Aufgabe schon erledigt" : ident === "memory_2" ? "Quiz starten" : ident === "poll_2" ? "Sag uns deine Meinung!" : ident === "meet_1" ? "Zu den funktionen" : ident.includes("puzzle_7") ? "Jetzt Spielen" : ident.includes("quiz_21") ? "ZUM ENTHÜLLUNGS-RÄTSEL" : ident.includes("quiz_20") ? "ZUM QUIZ" : ident.includes("quiz_22") ? "Zur Aufgabe " : "Aufgabe starten"}</Button>
          {getModuleValue(content?.content?.modules, "terms", "file.url") && <a href={getModuleValue(content?.content?.modules, "terms", "file.url")} className="statute-link">Überprüfen Sie die Aktivitätsregeln</a>}
        </div>
      </div>
    </section>
  )
}
