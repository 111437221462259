import React, { useState } from 'react'
import Modal from "../index"
import Lock from "../../../../img/lock.svg"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import { logout, useUserDispatch } from '../../../UserContext';
import { showErrorPopup, translateError } from '../../ErrorHandling';
import { fetchData, getCookie } from '../../../Helpers';

export default NiceModal.create(() => {
  const modal = useModal();
  const [loading, setLoading] = useState(false)
  const dispatch = useUserDispatch()

  const leaveGlo = async () => {
    // const response = await fetch(process.env.REACT_APP_API_URL + "/consumer/leave", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + (getCookie("loyalty_sso_session_id") ??  JSON.parse(sessionStorage.getItem('token')).uuid)
    //   },
    // })
    // const leave = await response.json()
    // if (leave.status.success) {
    //   logout(dispatch)
    //   modal.hide()
    // } else {
    //   showErrorPopup(translateError(leave.data.error), leave.meta.ts)
    //   console.log("error")
    // }
    fetchData({
      endpoint:"/consumer/leave",
      payload: {},
      successCallback: () => {logout(dispatch); modal.hide()},
      setLoading: setLoading
    })
  }

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()} barIcon={Lock}
      innerClass={"close-account-modal"}>
      <h2>Close Account</h2>
      <p>Are you sure you want to close your account?</p>
      <div className="buttons-wrapper">
        <Button action={leaveGlo} loading={loading}>Close Account</Button>
        <Button btnClass='white' action={() => modal.hide()}>Cancel</Button>
      </div>
    </Modal>
  );
});
