import DOMPurify from 'dompurify'
import React, { useState } from 'react'
import { useEffect } from 'react'
import Dropdown from '../../components/Dropdown'
import { showErrorPopup, translateError } from '../../components/ErrorHandling'
import { fetchData, getCookie, parseHTMLToText } from '../../Helpers'
import "./style.scss"

const faqContent = [
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat m dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc."
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?"
  },
  {
    title: "test",
    content: "Testowy"
  },
  {
    title: "Faq",
    content: "Faq"
  },
  {
    title: "glo",
    content: "glo"
  },
  {
    title: "statute",
    content: "statute"
  },
]

export default function FaqPage() {
  const [faqs, setFaqs] = useState([{}])
  const [currentQuestion, setCurrentQuestion] = useState({ index: -1, open: false })

  useEffect(() => {
    // const getFaq = async () => {
    //   const response = await fetch(process.env.REACT_APP_API_URL + "/faq/list", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + (getCookie("loyalty_sso_session_id") ?? JSON.parse(sessionStorage.getItem('token')).uuid),
    //     },
    //     body: JSON.stringify({
    //       "limit": 100,
    //       "offset": 0
    //     })
    //   })
    //   const faqs = await response.json()
    //   if (faqs.status.success) {
    //     const objectArray = []
    //     faqs.data.forEach((element, index) => {
    //       if (objectArray.filter(e => e.category === element.categoryName).length === 0) {
    //         objectArray.push({
    //           category: element.categoryName,
    //           content: [{
    //             title: element.content.title,
    //             answer: element.content.modules.filter(e => e.type === "intro")[0].custom,
    //             index: index
    //           }]
    //         })
    //       } else {
    //         objectArray[objectArray.findIndex(e => e.category === element.categoryName)].content.push({
    //           title: element.content.title,
    //           answer: element.content.modules.filter(e => e.type === "intro")[0].custom,
    //           index: index
    //         })
    //       }
    //     });
    //     console.log(objectArray)
    //     setFaqs(objectArray)
    //   } else {
    //     showErrorPopup(translateError(faqs.data.error), faqs.meta.ts)
    //   }
    // }
    // getFaq()

    const controller = new AbortController()
    fetchData({
      endpoint: "/faq/list",
      payload: {
        "limit": 100,
        "offset": 0
      },
      successCallback: resObj => {
        const objectArray = []
        resObj.data.forEach((element, index) => {
          if (objectArray.filter(e => e.category === element.categoryName).length === 0) {
            objectArray.push({
              category: element.categoryName,
              content: [{
                title: element.content.title,
                answer: element.content.modules.filter(e => e.type === "intro")[0].custom,
                index: index
              }]
            })
          } else {
            objectArray[objectArray.findIndex(e => e.category === element.categoryName)].content.push({
              title: element.content.title,
              answer: element.content.modules.filter(e => e.type === "intro")[0].custom,
              index: index
            })
          }
        });
        setFaqs(objectArray)
      },
      controller: controller
    })

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <section className='faq-page home-page'>
      <h1>FAQ</h1>
      <p className="desc">Du hast noch Fragen? Hier findest Du Antworten auf häufig gestellte Fragen:</p>
      {faqs.map((element, index) => (
        <React.Fragment key={index}>
          <h2>{element?.category}</h2>
          <div className="faq-content">
            {element?.content?.map((element) => (
              <Dropdown className='details'
                key={element.index}
                summary={
                  <div className='question'>
                    <h4>{element?.title}</h4>
                  </div>
                }
                details={
                  <div className="answer" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element?.answer) }}></div>
                }
                open={currentQuestion.index === element.index && currentQuestion.open}
                setOpen={() => setCurrentQuestion(prev => ({ index: element.index, open: prev.index !== element.index ? true : !prev.open }))}
              />
            ))}
          </div>
        </React.Fragment>
      ))}
      {/* {faqs.map((element, index) => (
          <Dropdown className='details'
            key={index}
            summary={
              <div className='question'>
                <h4>{element?.content?.title}</h4>
              </div>
            }
            details={
              <div className="answer" dangerouslySetInnerHTML={{ __html: element?.content?.modules.filter(e => e.type === "intro")[0]?.custom }}></div>
            }
            open={currentQuestion.index === index && currentQuestion.open}
            setOpen={() => setCurrentQuestion(prev => ({ index: index, open: prev.index !== index ? true : !prev.open }))}
          />
        ))} */}
    </section>
  )
}
