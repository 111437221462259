export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  content: [
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      title: "glo™ QUIZ",
      mobileTopImg: true,
      type: "button",
      question: "Seit wann gibt es den @glo.germany Channel?",
      img: require("./img/1.jpg"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Seit 2015"
        },
        {
          ident: "b",
          content: "Seit 2018"
        },
        {
          ident: "c",
          content: "Seit 2020"
        },
        {
          ident: "d",
          content: "Seit 2022"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ QUIZ",
      mobileTopImg: true,
      question: "Auf welchen Social-Media-Kanälen kannst du glo™ folgen?",
      img: require("./img/2.jpg"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Auf Pinterest"
        },
        {
          ident: "b",
          content: "Auf dem Nord-Ostsee-Kanal"
        },
        {
          ident: "c",
          content: "Auf Facebook & Instagram"
        },
        {
          ident: "d",
          content: "Auf dem Heizkanal"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ QUIZ",
      mobileTopImg: true,
      question: "Was hat glo™ nicht erfunden?",
      img: require("./img/3.webp"),
      correctAnswer: "d",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Die Heat-not-Burn Technologie"
        },
        {
          ident: "b",
          content: "Den HYPER X2 Heater"
        },
        {
          ident: "c",
          content: "Die erste Insta-Talkshow"
        },
        {
          ident: "d",
          content: "Die Kombi Anzug und Sneakers"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ QUIZ",
      mobileTopImg: true,
      question: "Was ist das Motto von glo™?",
      img: require("./img/4.webp"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "#noway"
        },
        {
          ident: "b",
          content: "#gloyourway"
        },
        {
          ident: "c",
          content: "#globetter"
        },
        {
          ident: "d",
          content: "#gloistsupercool"
        },
      ]
    },
  ]
}