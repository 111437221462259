import React, { useEffect, useRef, useState } from 'react'
import { useSpring, animated } from "react-spring";
import useMeasure from 'react-use-measure';

export default function Dropdown({ summary, details, open, setOpen, className = "", disabled = false }) {
  const [contentHeight, setContentHeight] = useState("0px");
  const [contentRef, { height }] = useMeasure();

  const expand = useSpring({
    height: open ? `${contentHeight}px` : "0px"
  });

  useEffect(() => {
    setContentHeight(height);
    window.addEventListener("resize", setContentHeight(height));

    return () => {
      window.removeEventListener("resize", setContentHeight(height));
    }
  }, [height])


  return (
    <div className={`dropdown ${className} ${disabled ? "disabled" : ""}`} data-open={open} aria-hidden={!open}>
      <button onClick={() => { if (!disabled) setOpen() }}>
        <div className="upper">{summary}</div>
      </button>
      <animated.div style={{ ...expand, overflow: "hidden" }}>
        <div className="content" ref={contentRef}>
          {details}
        </div>
      </animated.div>
    </div>
  )
}
