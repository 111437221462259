import React from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { getUserDetails, useUserDispatch, useUserState } from '../../../UserContext';
import ReactModal from 'react-modal';
import Button from '../../Button';
import { ReactComponent as Gift } from '../../../../img/gift-red-full.svg';
import { ReactComponent as Controler } from '../../../../img/controler.svg';
import ImageDestkop from "./img/welcome-desktop.webp"
import { ReactComponent as Logo } from "../../../../img/glo-plus.svg"
import { fetchData } from '../../../Helpers';

export default NiceModal.create(() => {
  const modal = useModal();
  const { userDetails } = useUserState()
  const dispatch = useUserDispatch()

  const closeModal = () => {
    fetchData({
      endpoint: "/activation/solution/flag",
      payload: {
        "activation": {
          "ident": "introductionCompleted"
        }
      },
      successCallback: () => {
        dispatch({
          type: 'USER_DETAILS_SUCCESS',
          payload: { ...userDetails, introductionCompleted: true }
        })
        getUserDetails(dispatch);
      },
      errorCallback: () => {
        dispatch({
          type: 'USER_DETAILS_SUCCESS',
          payload: { ...userDetails, introductionCompleted: true }
        })
      },
    })
    modal.hide()
  }

  return (
    <ReactModal isOpen={modal.visible} onClose={closeModal}
      onAfterClose={() => modal.remove()} overlayClassName={"modal-overlay welcome-modal-overlay"}
      className={"modal-inner welcome-modal-inner"} shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true}
      preventScroll={true} appElement={document.getElementById("root")}>
      <button onClick={closeModal} className="exit">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.95147 11.6485C10.4201 12.1172 11.1799 12.1172 11.6485 11.6485C12.1172 11.1799 12.1172 10.4201 11.6485 9.95147L7.69706 5.99999L11.6485 2.04853C12.1171 1.5799 12.1171 0.820101 11.6485 0.351472C11.1799 -0.117156 10.4201 -0.117156 9.95147 0.351472L6 4.30294L2.04853 0.351472C1.57991 -0.117157 0.820108 -0.117157 0.35148 0.351472C-0.11715 0.8201 -0.11715 1.5799 0.35148 2.04853L4.30295 5.99999L0.351472 9.95147C-0.117157 10.4201 -0.117157 11.1799 0.351472 11.6485C0.8201 12.1172 1.5799 12.1172 2.04853 11.6485L6 7.69705L9.95147 11.6485Z" fill="white" />
        </svg>
      </button>
      <div className="image">
        <img src={ImageDestkop} alt="" />
      </div>
      <div className="content">
        <h4>Hello</h4>
        <h2>{userDetails.firstName}</h2>
        <p>Toll, dass du bei&nbsp;
          <Logo className="logo" />
          &nbsp;dabei bist! Komm erstmal in Ruhe an und guck dich um.</p>
        <p>Pro-Tipp: Um schnell und einfach deine ersten Punkte zu sammeln, empfehlen wir dir die GamingAufgaben. <Controler className='controler' /></p>
        <p>Die Punkte kannst du gegen Rewards <Gift className='gift' /> einlösen – schon ab 30 Punkten staubst du nice Prämien ab!</p>
        <div className="buttons-wrapper">
          <Button href="/aufgaben" action={closeModal}>Go to Aufgaben</Button>
          <Button href="/" btnClass="white" action={closeModal}>Go to homepage</Button>
        </div>
      </div>
    </ReactModal>
  );
});
