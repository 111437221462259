import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useMeasure from 'react-use-measure'
import GloPlusLogo from "../../../img/glo-plus.svg"
import Button from '../../components/Button'
import LoginIcon from "./img/login-icon.svg"
import "./styles.scss"
import { getCookie } from "../../Helpers";

export default function LoginPage() {
  useEffect(() => {
    const documentHeight = () => {
      const obj = document.querySelector(".full-viewport")
      obj.style.setProperty('--height', `${window.innerHeight}px`)
    }

    documentHeight()
    window.addEventListener("resize", documentHeight)

    return () => {
      window.removeEventListener("resize", documentHeight)
    }
  }, [])

  useEffect(() => {
    if(!getCookie("loyalty_sso_session_id")) window.location.replace(`${process.env.REACT_APP_DG_URL}/gloloyalty/authorize?referer=${btoa(window.location.href)}`)
  }, [])

  return (
    <section className='logout-page full-viewport login-page'>
      <div className="inner ">
        <img src={GloPlusLogo} alt="" className='logo' />
        <img src={LoginIcon} alt="" className='login-svg'/>
        <h2>DU BIST NICHT EINGELOGGT.</h2>
        <p>Logge dich ein, um weiterzumachen</p>
        <Button href={`${process.env.REACT_APP_DG_URL}/gloloyalty/authorize?referer=${btoa(window.location.href)}`} target="_self">Zum Log In</Button>
      </div>
    </section>
  )
}
