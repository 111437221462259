import React, { useEffect, useRef, useState } from 'react'
import GloPlus from "../../../img/glo-plus.svg"
import Glo from "../../../img/glo.svg"
import UserIcon from "../../../img/user-icon.svg"
import Notification from "../../../img/notification.svg"
import Dropdown from "../../../img/dropdown.svg"
import ZonePlusIcon from "../Nav/img/zone-plus-icon.svg"
import Button from "../Button"
import Nav from "../Nav"
import "./style.scss"
import { Link, useNavigate } from 'react-router-dom'
import { getCookie, levelBreakPoints, levelMapper, useWindowSize } from '../../Helpers'
import { getUserDetails, logout, useUserDispatch, useUserState } from '../../UserContext'

const ProgressBar = ({ points, limit, extended }) => {
  const [innerWidth, setInnerWidth] = useState(0)
  const progressRef = useRef(null)

  useEffect(() => {
    const calculateProgress = () => {
      setInnerWidth(points / limit * progressRef.current.clientWidth)
    }

    calculateProgress()
  }, [extended])

  return (
    <div className='progress-bar' ref={progressRef}>
      <div className="inner" style={{ width: `${extended ? innerWidth : 0}px` }}>
        <strong>{points}&nbsp;/</strong>&nbsp;{limit}
      </div>
    </div>
  )
}

export default function Header() {
  const [extended, setExtended] = useState(false)
  const { width } = useWindowSize()
  const dispatch = useUserDispatch()
  const { loading, userDetails } = useUserState()
  const navigate = useNavigate()

  const getUserData = async () => {
    try {
      let response = await getUserDetails(dispatch)
      if (!response) return
    } catch (error) {
      console.log(error)
    }
  }

  const handleLogout = () => {
    navigate("/logged-out", { replace: true })
    logout(dispatch)
  }

  useEffect(() => {
    if (!userDetails.firstName && !loading) {
      getUserData()
    }
  }, [])

  useEffect(() => {
    if(localStorage.getItem("hyperproar") && userDetails.firstName) {
      const redirectUrl = (' ' + localStorage.getItem("hyperproar")).slice(1)
      localStorage.removeItem("hyperproar")
      window.location.replace(`${redirectUrl}?token=${(getCookie("loyalty_sso_session_id") ?? JSON.parse(sessionStorage.getItem('token')).uuid)}`)
    }
  }, [userDetails])

  useEffect(() => {
    const getUserData = async () => {
      try {
        let response = await getUserDetails(dispatch)
        if (!response) return
      } catch (error) {
        console.log(error)
      }
    }
    setTimeout(() => { if (!userDetails?.firstName || !userDetails?.value) getUserData() }, 300)
  }, [])

  useEffect(() => {
    const disableScroll = (e) => {
      e.preventDefault();
    }

    const closeMenu = () => {
      setExtended(false)
    }

    const menuButtons = document.querySelectorAll(".menu-wrapper .button") ?? []
    const pageHolder = document.querySelector(".page-holder") ?? {}

    if (extended) {
      if (width > 1020) {
        document.getElementById("root")?.addEventListener("touchmove", disableScroll)
        document.getElementById("root")?.addEventListener("wheel", disableScroll, { passive: false })
      } else {
        pageHolder.classList.add("no-scroll")
      }
      menuButtons.forEach(e => e.addEventListener("click", closeMenu))
      // document.getElementById("root")?.addEventListener("click", collapseWhenClickedOutside)
    }

    return () => {
      document.getElementById("root")?.removeEventListener("touchmove", disableScroll)
      document.getElementById("root")?.removeEventListener("wheel", disableScroll, { passive: false })
      pageHolder.classList.remove("no-scroll")
      menuButtons.forEach(e => e.removeEventListener("click", closeMenu))
      // document.getElementById("root")?.addEventListener("click", collapseWhenClickedOutside)
    }
  }, [extended, width])

  // function isAccountOlderThan14Days(accountCreatedDate) {
  //   const createdDate = new Date(accountCreatedDate);
  //   const currentDate = new Date();
  //   const differenceInDays = (currentDate - createdDate) / (1000 * 60 * 60 * 24);
  //   return differenceInDays >= 14;
  // }

  return (
    <div className="header">
      <Link to="/">
        <div className="logo">
          <img src={GloPlus} alt="" />
        </div>
      </Link>
      <Nav />
      <div className="user-info">
        <div className="user">
          <h3 className="name">{Boolean(userDetails?.firstName) ? userDetails.firstName.length < 14 ? userDetails.firstName : (userDetails.firstName.substring(0, 14) + "...") : ""}</h3>
          <Link to="/status-vorteile" className="status">Dein Status: <br /><strong>{levelMapper.get(userDetails?.level)}</strong></Link>
          <p className="points">Prämien-Punkte: <br /><span className="pill">{userDetails?.balance ?? ""}<span className='smaller'>P</span></span></p>
        </div>
        {width >= 1020 &&
          <>
            <Link to="/benachrichtigungen" className={`notification ${userDetails?.newMessage ? "new-message" : ""}`}>
              <div className="inner">
                <img src={Notification} alt="" />
              </div>
            </Link>
            <button className="icon" onClick={() => setExtended(prev => !prev)}>
              <img src={UserIcon} alt="" />
            </button>
            <button onClick={() => setExtended(prev => !prev)}>
              <div className="dropdown">
                <img src={Dropdown} alt="" className={extended ? "rotate" : ""} />
              </div>
            </button>
          </>}
      </div>
      {width < 1020 && <div className="vertical">
        <div className={`notification ${userDetails?.newMessage ? "new-message" : ""}`}>
          <Link to="/benachrichtigungen">
            <div className="inner">
              <img src={Notification} alt="" />
            </div>
          </Link>
        </div>
        <div className="profile">
          <button onClick={() => setExtended(prev => !prev)}>
            <div className="inner">
              <img src={UserIcon} alt="" />
            </div>
          </button>
        </div>
        <div className="zone-plus">
          <Link to="/zone-plus">
            <div className="inner">
              <img src={ZonePlusIcon} alt="" />
              <p>Zone +</p>
            </div>
          </Link>
        </div>
      </div>}
      <div className="menu-wrapper" aria-hidden={!extended}>
        <div className="inner-container">
          <div className="points">
            <div className="inner">
              <div className="flex">
                <h3>Prämien-Punkte:</h3>
                <div className="pill user-points">{userDetails?.balance ?? ""}<span className='smaller'>P</span></div>
              </div>
              <div className="info">
                Du kannst deine Punkte jeder Zeit einlösen.<br />
                Dein Status wird dadurch nicht verändert.
              </div>
              <div className="buttons">
                <Button btnClass='transparent menu' href="/pramienshop">Jetzt zum Shop</Button>
              </div>
            </div>
          </div>
          <div className="status">
            <div className="inner">
              <div className="flex">
                <h3>Dein Status:</h3>
                <div className="pill rank">{levelMapper.get(userDetails?.level)}</div>
              </div>
              <div className="progress">
                <ProgressBar points={userDetails.status} limit={levelBreakPoints[levelMapper.get(userDetails.level)]} extended={extended} />
                Du hast bisher <strong>{userDetails.status}</strong> Status-Punkte gesammelt. <br />
                Dir fehlen noch <strong>{userDetails.level ? Math.max(levelBreakPoints[levelMapper.get(userDetails.level)] - userDetails.status, 0) : ""}</strong> Status-Punkte bis zum <br />
                nächsten Status.
              </div>
              <div className="buttons">
                <Button btnClass='transparent menu' href={"/status-vorteile"}>Zur Übersicht</Button>
              </div>
            </div>
          </div>
          <div className="profil">
            <div className="inner">
              <h3>PROFIL:</h3>
              <div className="user-data">
                Name: <strong>{userDetails.firstName}</strong><br />
                Nachname: <strong>{userDetails.lastName}</strong><br />
                E-mail: <strong>{userDetails.emailAddress}</strong>
              </div>
              <div className="buttons">
                <Button btnClass='transparent menu' href={"/profile"}>Profile</Button>
              </div>
            </div>
          </div>

          {width < 1020 && <>
            <div className="to-discoverglo">
              <div className="inner flex">
                <img src={Glo} alt="" />
                <Button href="https://www.discoverglo.com/de/de/?utm_source=glo%2B&utm_medium=home_button&utm_campaign=glo%2B2glo" btnClass='transparent menu'>Go to discoverglo.com/de</Button>
              </div>
            </div>
            <div className="logout">
              <div className="inner">
                <Button btnClass='transparent menu' action={handleLogout}>Ausloggen</Button>
              </div>
            </div>
          </>}

          {width >= 1020 && <div className='menu-flex'>
            <div className="to-discoverglo">
              <div className="inner flex">
                <img src={Glo} alt="" />
                <Button href="https://www.discoverglo.com/de/de/?utm_source=glo%2B&utm_medium=home_button&utm_campaign=glo%2B2glo" btnClass='transparent menu'>
                  Go to discoverglo.com/de
                </Button>
              </div>
            </div>
            <div className="logout">
              <div className="inner">
                <Button btnClass='transparent menu' action={handleLogout}>Ausloggen</Button>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}
