

export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/valentines-day?utm_source=Glo%2B&amp;utm_medium=Article&amp;utm_campaign=vday",
    copy: "ANGEBOT NUTZEN"
  },
  customFinishModal: "correct-find",
  content: [
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      title: "Das Spiel mit der Liebe",
      mobileTopImg: true,
      type: "button",
      question: "Welches der folgenden Geschenke ist am beliebtesten zum Valentinstag?",
      img: require("./img/1.jpg"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Coole Socken"
        },
        {
          ident: "b",
          content: "Blumen und Schokolade"
        },
        {
          ident: "c",
          content: "Eine Weltreise "
        },
        {
          ident: "d",
          content: "Ein Staubsauger"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "Das Spiel mit der Liebe",
      mobileTopImg: true,
      question: "Welche Blumen werden oft zum Valentinstag verschenkt?",
      img: require("./img/2.jpg"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Sonnenblumen "
        },
        {
          ident: "b",
          content: "Tulpen "
        },
        {
          ident: "c",
          content: "Rosen"
        },
        {
          ident: "d",
          content: "Veilchen"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "Das Spiel mit der Liebe",
      mobileTopImg: true,
      question: "Welche Farbe steht häufig für die Liebe und Leidenschaft?",
      img: require("./img/3.jpg"),
      correctAnswer: "d",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Blau"
        },
        {
          ident: "b",
          content: "Gelb"
        },
        {
          ident: "c",
          content: "Grün "
        },
        {
          ident: "d",
          content: "Rot"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "Das Spiel mit der Liebe",
      mobileTopImg: true,
      question: "Wenn du auf das Rauchen, aber nicht auf Nikotin verzichten willst, solltest du:",
      img: require("./img/4.jpg"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "nach Kuba auswandern "
        },
        {
          ident: "b",
          content: "jeden Morgen Yoga üben "
        },
        {
          ident: "c",
          content: "dir einen glo™ Heater besorgen"
        },
        {
          ident: "d",
          content: "nie mehr ins Kino gehen"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "Das Spiel mit der Liebe",
      mobileTopImg: true,
      question: "Welche dieser Aktivitäten ist ein beliebter Valentinstagsplan? ",
      img: require("./img/5.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Ein romantisches Abendessen  "
        },
        {
          ident: "b",
          content: "Ein Tag im Freizeitpark "
        },
        {
          ident: "c",
          content: "Eine Wanderung in den Bergen"
        },
        {
          ident: "d",
          content: "Ein Paintball-Match"
        },
      ]
    },
  ]
}