import React from 'react'
import Modal from "../index"
import EmailIcon from "./img/email-top.svg"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Arrow from "./img/arrow.svg"
import { useUserState } from '../../../UserContext';

export default NiceModal.create(() => {
  const modal = useModal();
  const { userDetails } = useUserState()

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()} barIcon={EmailIcon}
      innerClass={"glo-email-share"}>
      <h2>Wie möchtest du den Rabatt-Code senden?</h2>
      <ul className='email-select'>
        <li onClick={() => {
          modal.hide()
          NiceModal.show('email-copy')
        }}>
          <p>Link kopieren und Rabatt-Code versenden.</p>
          <span>1</span>
          <img src={Arrow} alt="" />
        </li>
        <li onClick={() => {
          window.open(`mailto:?subject=My glo+ code&body=My unique glo+ code: https://discoverglo.com/de/de/raf?voucherCode=${userDetails?.referralCode}`)
        }}>
          <p>Rabatt-Code per E-Mail versenden.</p>
          <span>2</span>
          <img src={Arrow} alt="" />
        </li>
      </ul>
    </Modal>
  );
});
