import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Arrow from "../../../img/arrow.svg"
import { fetchData, parseDate } from '../../Helpers'
import "./style.scss"

export default function NotificationsWrapper({ notifications, notificationRef, setNotifications }) {

  const [index, setIndex] = useState(0)

  const onClose = index => {
    fetchData({
      endpoint: "/message/close",
      payload: {
        "message": {
          "uuid": `${notifications[index].uuid}`
        }
      },
      successCallback: () => {
        setIndex(prev => ++prev);
      },
      shouldThrowError: false
    })
  }

  const onPopUpClick = () => {
    fetchData({
      endpoint: "/message/describe",
      payload: {
        "message": {
          "uuid": `${notifications[index].uuid}`
        }
      },
      successCallback: () => {
        let newArray = [...notifications]
        newArray[index].seen = {}
        newArray[index].closed = {}
        setNotifications([...newArray])
        setIndex(prev => ++prev);
        notificationRef.current = true
      },
      shouldThrowError: false
    })
  }

  return (
    <div className="notifications-popup-wrapper">
      {index < notifications.length &&
        <>
          {notifications?.[index]?.url?.custom?.includes("http://") || notifications?.[index]?.url?.custom?.includes("https://") ?
            <a className="popup" href={notifications?.[index]?.url ? `${notifications?.[index]?.url?.custom}` : ""}
              onClick={onPopUpClick} target="_blank" rel="noopener noreferrer">
              {notifications.length > 1 && <div className="pagination">
                <p>
                  {index + 1}/{notifications.length}
                </p>
              </div>}
              <button className="exit" onClick={e => { e.preventDefault(); onClose(index) }}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.95147 11.6485C10.4201 12.1172 11.1799 12.1172 11.6485 11.6485C12.1172 11.1799 12.1172 10.4201 11.6485 9.95147L7.69706 5.99999L11.6485 2.04853C12.1171 1.5799 12.1171 0.820101 11.6485 0.351472C11.1799 -0.117156 10.4201 -0.117156 9.95147 0.351472L6 4.30294L2.04853 0.351472C1.57991 -0.117157 0.820108 -0.117157 0.35148 0.351472C-0.11715 0.8201 -0.11715 1.5799 0.35148 2.04853L4.30295 5.99999L0.351472 9.95147C-0.117157 10.4201 -0.117157 11.1799 0.351472 11.6485C0.8201 12.1172 1.5799 12.1172 2.04853 11.6485L6 7.69705L9.95147 11.6485Z" fill="white" />
                </svg>
              </button>
              <div className="type-date">
                <p className="type">{notifications[index]?.category?.custom}</p>
                <p className="date">{notifications?.[index] ? parseDate(notifications[index]?.created?.date) : ""}</p>
              </div>
              <div className="content">
                <h4 className="title">{notifications[index]?.content?.title}</h4>
                <p className="desc">{notifications[index]?.content?.description}</p>
              </div>
              <img src={Arrow} alt="" />
            </a>
            :
            <Link className="popup" to={notifications?.[index]?.url ? `${notifications?.[index]?.url?.custom}` : "/benachrichtigungen"}
              onClick={onPopUpClick}>
              {notifications.length > 1 && <div className="pagination">
                <p>
                  {index + 1}/{notifications.length}
                </p>
              </div>}
              <button className="exit" onClick={e => { e.preventDefault(); onClose(index) }}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.95147 11.6485C10.4201 12.1172 11.1799 12.1172 11.6485 11.6485C12.1172 11.1799 12.1172 10.4201 11.6485 9.95147L7.69706 5.99999L11.6485 2.04853C12.1171 1.5799 12.1171 0.820101 11.6485 0.351472C11.1799 -0.117156 10.4201 -0.117156 9.95147 0.351472L6 4.30294L2.04853 0.351472C1.57991 -0.117157 0.820108 -0.117157 0.35148 0.351472C-0.11715 0.8201 -0.11715 1.5799 0.35148 2.04853L4.30295 5.99999L0.351472 9.95147C-0.117157 10.4201 -0.117157 11.1799 0.351472 11.6485C0.8201 12.1172 1.5799 12.1172 2.04853 11.6485L6 7.69705L9.95147 11.6485Z" fill="white" />
                </svg>
              </button>
              <div className="type-date">
                <p className="type">{notifications[index]?.category?.custom}</p>
                <p className="date">{notifications?.[index] ? parseDate(notifications[index]?.created?.date) : ""}</p>
              </div>
              <div className="content">
                <h4 className="title">{notifications[index]?.content?.title}</h4>
                <p className="desc">{notifications[index]?.content?.description}</p>
              </div>
              <img src={Arrow} alt="" />
            </Link>}
        </>
      }
    </div>
  )
}
