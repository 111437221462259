import SideImg from "./img/side-img.jpg"
import lounge from "./img/lounge.jpg"
import lounge_tile from "./img/lounge_tile.jpg"
import terazza from "./img/terazza.jpg"
import terazza_tile from "./img/terazza_tile.jpg"


export const questions = {
  staticSideImg: SideImg,
  content: [
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "Summer Paintings",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: true,
      question: "<span>neo™</span> Lounge Click",
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: lounge_tile,
          img: lounge
        },
      ]
    },
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "Summer Paintings",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: true,
      question: "<span>neo™</span> Terrazza Click",
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "b",
          content: terazza_tile,
          img: terazza
        },
      ]
    },

  ]
}