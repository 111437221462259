import React, { useEffect } from "react"
import { NavLink } from 'react-router-dom'
import "./style.scss"
import Logo from "../../../img/glo-plus.svg"
import { ReactComponent as Mail } from "./img/mail.svg"
import { ReactComponent as FB } from "./img/facebook.svg"
import { ReactComponent as Insta } from "./img/instagram.svg"
import { useState } from "react"
import { useUserState } from "../../UserContext"
import { showErrorPopup, translateError } from "../ErrorHandling"
import { fetchData, getCookie } from "../../Helpers"

export default function Footer() {
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    if(JSON.parse(sessionStorage.getItem('token'))?.uuid) {
      fetchData({
        endpoint:"/document/list",
        payload: {
          "limit": 100,
          "offset": 0,
          "status": [
            "new",
            "current",
            "archive"
          ]
        },
        successCallback: resObj => setDocuments(resObj.data),
        controller: controller
      })
    }
    return () => {
      controller.abort()
    }
  }, [JSON.parse(sessionStorage.getItem('token'))?.uuid])

  return (
    <footer className={`${!JSON.parse(sessionStorage.getItem("token"))?.uuid ? "token-missing" : ""}`}>
      <div className="footer-top">
        <div className="flex-wrapper">
          <img src={Logo} alt="" className="logo" />
          <div className="footer-nav">
            {/* <NavLink to="">Vorschriften</NavLink>  */}
            {documents.map((item, i) => {
              return (
                <NavLink key={i} to={`/terms/${item.slug}`}>{item.content.title}</NavLink>
              )
            })}
            {JSON.parse(sessionStorage.getItem("token"))?.uuid && <NavLink to="/faq">FAQ</NavLink>}
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie-Einstellungen</button>
          </div>
        </div>
        <hr className="line" />
        <div className="footer-left">
          <div className="footer-contact">
            <div className="social">
              <h4>Finden Sie uns auf:</h4>
              <div className="wrapper">
                <div><a href="https://www.facebook.com/glo.germany/" target="_blank" rel="noopener noreferrer"><FB /></a></div>
                <div><a href="https://www.instagram.com/glo.germany/" target="_blank" rel="noopener noreferrer"><Insta /></a></div>
                <div><a href="mailto:SUPPORT@MYGLO.DE" target="_blank" rel="noopener noreferrer"><Mail /></a></div>
                <div><a href="mailto:SUPPORT@MYGLO.DE" rel="noopener noreferrer" className="email-link"><h4 className="email">SUPPORT@MYGLO.DE</h4></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>©British American Tobacco 2022. Alle Rechte vorbehalten.</p>
      </div>
    </footer>
  );
}
