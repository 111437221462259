import { useState, useEffect } from 'react';
import { showErrorPopup, translateError } from './components/ErrorHandling';

export function useWindowSize() {
  const isClient = typeof window === 'object';

  const [windowSize, setWindowSize] = useState({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  });

  useEffect(() => {
    const isClient = typeof window === 'object';

    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize({
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export function sendEvent(event, payload) {
  const data = {
    "event": {
      "ident": event,
      "payload": payload || null
    }
  }

  fetch(process.env.REACT_APP_API_URL + "/consumer/event-create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (getCookie("loyalty_sso_session_id") ?? JSON.parse(sessionStorage.getItem('token')).uuid),
      'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
    },
    body: JSON.stringify(data)
  })
    .then((response) => {
      return response.json()
    })
    .then(
      (result) => {

      },
      (error) => {
        console.log(error)
      }
    )
}

export function leadingZero(day) {
  let d = parseInt(day);
  if (d < 10) {
    return "0" + d;
  } else {
    return "" + d;
  }
}

export function parseDate(d, hours, showSeconds, addDays) {
  let date = new Date(d.replace(" ", "T"));
  let seconds = ":" + leadingZero(date.getSeconds());

  if (showSeconds === false) {
    seconds = "";
  }

  if (addDays) {
    date.setDate(date.getDate() + addDays)
  }

  if (hours) {
    return (
      leadingZero(date.getDate()) +
      "/" +
      leadingZero(date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      ", " +
      leadingZero(date.getHours()) +
      ":" +
      leadingZero(date.getMinutes()) +
      seconds
    );
  } else {
    return (
      leadingZero(date.getDate()) +
      "." +
      leadingZero(date.getMonth() + 1) +
      "." +
      date.getFullYear()
    );
  }
}

export function validateDates(vlalidTo, serverTime, daysAdded = 0) {
  const date1 = new Date(vlalidTo)
  date1.setDate(date1.getDate() + daysAdded)
  const date2 = new Date(serverTime)

  return date1 <= date2
}

export function localeString(string) {
  const date = new Date(string)
  return date.toLocaleDateString("pl-PL", { day: "numeric", month: 'long', year: "numeric" })
}

export function validToBreakpoint(created, validToFromServer, disabledValidation = false, serverTime = null, forceChcek = false) {
  let date = new Date(created)
  const magicDate = new Date("2022-03-15 00:00")

  if (date < magicDate) {
    return disabledValidation ? validateDates(validToFromServer, serverTime) : parseDate(validToFromServer)
  }

  if (forceChcek) {
    return disabledValidation ? validateDates(validToFromServer, serverTime) : parseDate(validToFromServer)
  }

  return disabledValidation ? validateDates(created, serverTime, 30) : parseDate(created, false, false, 30)
}

export function getCookie(sName) {
  var oCrumbles = document.cookie.split(';');
  console.log("all:", oCrumbles)
  for (var i = 0; i < oCrumbles.length; i++) {
    var oPair = oCrumbles[i].split('=');
    var sKey = decodeURIComponent(oPair[0].trim());
    var sValue = oPair.length > 1 ? oPair[1] : false;
    if (sKey === sName) {
      return decodeURIComponent(sValue);
    }
  }
  return undefined;
}

export function setCookie(sName, sValue, options) {
  var sCookie = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);
  if (options && options instanceof Date) {
    options = {
      expires: options
    };
  }
  if (options && typeof options === 'object') {
    if (options.expires) {
      sCookie += '; expires=' + options.expires.toGMTString();
    }
    if (options.path) {
      sCookie += '; path=' + options.path.toString();
    }
    if (options.domain) {
      sCookie += '; domain=' + options.domain.toString();
    }
    if (options.secure) {
      sCookie += '; secure';
    }
  }
  document.cookie = sCookie;
}

export function removeCookie(sName, options) {
  var opt = options || {};
  opt.expires = new Date();
  this.setCookie(sName, '', opt);
}

export function sendFloodEvent(sendTo, path) {
  if (window.gtag) {
    window.gtag('event', 'conversion', path ? {
      'allow_custom_scripts': true,
      'u1': path,
      'send_to': sendTo
    } : {
      'allow_custom_scripts': true,
      'send_to': sendTo
    });
  }
}

export function debounce(func, timeout = 300) {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeout);
  };
};

export function getModuleValue(modulesArray, type, field) {
  if (!modulesArray || modulesArray.length === 0) {
    return
  }
  let fieldSplit = field.split(".")
  let data = modulesArray?.filter(obj => obj.type === type)[0]
  for (let i = 0; i < fieldSplit.length; i++) {
    data = data?.[fieldSplit[i]]
  }
  return data?.replace("ß", "&#7838;")
}

export function parseHTMLToText(html, HTMLTag, toArray = false) {
  let text = ""
  let dummy = document.createElement("html")
  dummy.innerHTML = html
  let lenght = dummy.getElementsByTagName(HTMLTag).length
  let finalArray = []
  for (let i = 0; i < lenght; i++) {
    if(toArray) {
      finalArray.push(dummy.getElementsByTagName(HTMLTag).item(i).innerHTML)
    } else {
      text += (dummy.getElementsByTagName(HTMLTag).item(i).innerHTML)
      text += " "
    }
  }
  return toArray ? finalArray : text
}

export function parseHTMLToObjets(html, searchTags="p, li") {
  let dummy = document.createElement("html")
  dummy.innerHTML = html
  const tags = dummy.querySelectorAll(searchTags)
  const lenght = tags.length
  let result = []
  for(let i = 0; i < lenght; i++) {
    result.push({
      tag: tags.item(i).tagName?.toLowerCase(),
      content: tags.item(i).innerHTML
    })
  }  
  return result
}

export async function fetchData({endpoint, method="POST", payload, successCallback, errorCallback=false, fetchErrorCallback=false, shouldThrowError=true, setLoading=false, controller=false} = {}) {
  try {
    if(setLoading) {
      setLoading(true)
    }
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("loyalty_sso_session_id") ?? JSON.parse(sessionStorage.getItem('token')).uuid}`,
      },
      body: JSON.stringify(payload),
      ...(controller && {signal: controller.signal})
    })
    const resObj = await response.json()

    if (resObj.status.success) {
      successCallback(resObj)
    } else {
      if(errorCallback) {
        errorCallback(resObj)
      }
      if(shouldThrowError) {
        showErrorPopup(translateError(resObj.data.error), resObj.meta.ts)
      }
    }
  } catch (error) {
    console.log(error)
    if(fetchErrorCallback && error.name !== "AbortError") {
      fetchErrorCallback()
    }
    if(shouldThrowError && error.name !== "AbortError") {
      console.log(error.name)
      showErrorPopup(translateError("generic"), "0000000")
    }
  }
  finally {
    if(setLoading) {
      setLoading(false)
    }
  }
}

export const levelMapper = new Map([
  [1, "basic"],
  [2, "advanced"],
  [3, "professional"],
  [4, "master"]
])

export const levelBreakPoints = {
  basic: 100,
  advanced: 300,
  professional: 1000,
  master: 1000,
}

export const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  if(countDown <= 0) {
    return [0, 0, 0, 0]
  }
  
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export function useSessionStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
      if (window)
          try {
              // Get from local storage by key
              const item = window.sessionStorage.getItem(key);
              // Parse stored json or if none return initialValue
              return item ? JSON.parse(item) : initialValue;
          } catch (error) {
              // If error also return initialValue
              console.log(error);
              return initialValue;
          }
  });
  if (typeof window === "undefined") {
      return initialValue;
  }
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
      try {
          // Allow value to be a function so we have same API as useState
          const valueToStore =
              value instanceof Function ? value(storedValue) : value;
          // Save state
          setStoredValue(valueToStore);
          // Save to local storage
          window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
          window.dispatchEvent(new Event("storage"));
      } catch (error) {
          // A more advanced implementation would handle the error case
          console.log(error);
      }
  };
  return [storedValue, setValue];
}