export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  customFinishModal: "quiz-finish-modal",
  content: [
    {
      mechanics: "Quiz",
      buttonCopy: "Zur Auswertung",
      title: "GEWINNSPIEL QUIZ FÜR DOCKVILLE TICKETS ",
      mobileTopImg: true,
      type: "button",
      question: "Wie viel wiegt der Hyper x2 Air?",
      img: require("./img/1.jpg"),
      correctAnswer: "d",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "180g"
        },
        {
          ident: "b",
          content: "115g"
        },
        {
          ident: "c",
          content: "100g"
        },
        {
          ident: "d",
          content: "75g"
        },
      ]
    },
  ]
}