import React, { useEffect, useRef, useState } from 'react'
import PageHeader from '../../../../components/PageHeader'
import { useCountdown } from '../../../../Helpers';
import { useNavigate } from 'react-router-dom'

export default function Countdown({ questions, currentQuestion, setCurrentQuestion, setAnswers, ident }) {
  const [days, hours, minutes, seconds] = useCountdown(questions.content[currentQuestion].date);
  const navigate = useNavigate()

  useEffect(() => {
    if ([days, hours, minutes, seconds].every(e => e === 0)) {
      navigate(questions.content[currentQuestion].navigateTo ?? "/", {replace: true})
    }
  }, [days, hours, minutes, seconds])

  return (
    <section className={`game-page quiz countdown ${ident}`}>
      <PageHeader>{questions.content[currentQuestion].title ?? "glo+ Quiz"}</PageHeader>
      <div className="wrapper">
        {questions.content[currentQuestion].mobileTopImg && <div className={`mobile-top-img`} style={{ backgroundImage: `url(${questions?.content[currentQuestion]?.imgMobile})` }}>
        </div>}
        <div className="game">
          <div className="inner">
            <h4 className='countdown-header'>{questions.content[currentQuestion].header}</h4>
            <p className='desc'>{questions.content[currentQuestion].desc}</p>
            <h3>{hours + days * 24 !== 0 && <>{hours + days * 24}<small>Std</small>:<small /></>}
              {(minutes !== 0 || hours + days * 24 > 0) && <>{minutes ?? "0"}<small>Min</small>:<small /></>}
              {(seconds !== 0 || minutes > 0 || hours + days * 24 > 0) && <>{seconds ?? "0"}<small>Sek</small></>}
            </h3>
          </div>
        </div>
        <div className={`side-img`} style={{ backgroundImage: `url(${questions?.content[currentQuestion]?.imgDesktop})` }}>
        </div>
      </div>
    </section>
  )
}
