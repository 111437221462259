import React from 'react'
import Modal from "../index"
import EmailIcon from "./img/email-top.svg"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';

export default NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()} barIcon={EmailIcon}
      innerClass={"glo-email-share"}>
      <h2>Kopiere diese E-Mail und versende sie.</h2>
      <textarea disabled id="txt" defaultValue={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst vitae augue et ornare non lacus. Sem venenatis ac est nascetur tempus. Eget enim suspendisse magna tortor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst vitae augue et ornare non lacus. Sem venenatis ac est nascetur tempus. Eget enim suspendisse magna tortor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst vitae augue et ornare non lacus. Sem venenatis ac est nascetur tempus. Eget enim suspendisse magna tortor. Lorem ipsum  suspendisse magna tortor."}/>
      <div className='btn-holder'>
        <Button action={() => {
            navigator.clipboard.writeText(document.getElementById("txt").value)
        }}>Kopieren</Button>
      </div>
    </Modal>
  );
});
