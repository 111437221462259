import img1 from "./img/1.png"
import img2 from "./img/2.png"
import img3 from "./img/3.png"
import img4 from "./img/4.png"
import img5 from "./img/5.png"
import img6 from "./img/6.png"
import img7 from "./img/7.png"

export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/glo-tabak-heater/glo-hyper-x2-air/?_ga=2.7433970.110774826.1692622344-806060856.1692622344&utm_source=Glo%2B&utm_medium=CTA&utm_campaign=Glo%2B+Activity+%22Find+the+Intruder%22",
    copy: "Jetzt probieren"
  },
  customFinishModal: "correct-answer",
  content: [
    {
      mechanics: "Quiz",
      buttonCopy: "Weiter",
      title: "Finde den Eindringling",
      mobileTopImg: true,
      type: "button-image",
      question: "Welche Farbe passt nicht?",
      img: require("./img/1.jpg"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          img: img1
        },
        {
          ident: "b",
          img: img2
        },
        {
          ident: "c",
          img: img3
        },
        {
          ident: "d",
          img: img4
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Weiter",
      type: "button-image",
      title: "Finde den Eindringling",
      mobileTopImg: true,
      question: "Welches Gerät scheint anders zu sein?",
      img: require("./img/1.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          img: img5
        },
        {
          ident: "b",
          img: img7
        },
        {
          ident: "c",
          img: img6
        },
        {
          ident: "d",
          img: img4
        },
      ]
    },
  ]
}