import React, { useEffect, useState, useRef } from 'react'
import Button from '../../../../components/Button'
import PageHeader from '../../../../components/PageHeader'
import HintPopup from './HintPopup'
import Dropdown from "../../../../../img/dropdown-oragne.svg"
import { debounce, useWindowSize } from '../../../../Helpers'
import useMeasure from 'react-use-measure'

export default function Find({ questions, currentQuestion, setCurrentQuestion, setAnswers }) {
  const [userBoard, setUserBoard] = useState([])
  const rootIndex = useRef(null)
  const finalIndex = useRef(null)
  const disableBoard = useRef(null)
  const { width } = useWindowSize()
  const SCREEN_XGA = 1020

  if (userBoard.length === 0) {
    setUserBoard([...questions.content[currentQuestion].answers])
  }

  const hadnleButtonClick = () => {
    setAnswers(prev => [...prev, {
      question_ident: `q${currentQuestion}`,
      answer_ident: userBoard.map(e => e.ident)
    }])
    setCurrentQuestion(prev => prev < questions.content.length - 1 ? ++prev : prev)
  }

  const swapTiles = (firstIndex, destinationInfo) => {
    const { secondIndex, direction } = destinationInfo
    disableBoard.current = true
    const firstTile = userBoard[firstIndex]
    const secondTile = userBoard[secondIndex]

    if (firstIndex === secondIndex) {
      disableBoard.current = false
      return
    }

    if ((firstIndex <= 2 && secondIndex < 0) || secondIndex > 8) {
      return
    }

    if ((firstIndex === 2 && secondIndex === 3) || (firstIndex === 6 && secondIndex === 5)) {
      return
    }

    if ((firstIndex === 3 && secondIndex === 2) || (firstIndex === 6 && secondIndex === 5)) {
      return
    }

    const firstObject = document.querySelector(`img[data-index='${firstIndex}']`)
    const secondObject = document.querySelector(`img[data-index='${secondIndex}']`)

    firstObject.classList.add("animate", direction)
    secondObject.classList.add("animate", direction === "left" ? "right" : direction === "right" ? "left" :
      direction === "up" ? "down" : direction === "down" ? "up" : "")

    const newUserBoard = userBoard
    newUserBoard[firstIndex] = secondTile
    newUserBoard[secondIndex] = firstTile

    setTimeout(() => {
      firstObject.classList.remove("animate", direction)
      secondObject.classList.remove("animate", direction === "left" ? "right" : direction === "right" ? "left" :
        direction === "up" ? "down" : direction === "down" ? "up" : "")
      disableBoard.current = false
      setUserBoard([...newUserBoard])
    }, 450)
  }

  const onPointerDown = event => {
    rootIndex.current = {
      index: +event.target.dataset.index,
      clientX: +event.nativeEvent.clientX,
      clientY: +event.nativeEvent.clientY,
    }
  }

  const onPointerUp = event => {
    if (disableBoard.current) return
    const a = rootIndex.current.clientX - event.nativeEvent.clientX ?? event.changedTouches[0].clientX
    const b = rootIndex.current.clientY - event.nativeEvent.clientY ?? event.changedTouches[0].clientY

    const x1 = rootIndex.current.clientX
    const x = event.nativeEvent.clientX ?? event.changedTouches[0].clientX
    const y1 = rootIndex.current.clientY
    const y = event.nativeEvent.clientY ?? event.changedTouches[0].clientY

    const THRESHOLD = 15;

    let direction = ""

    if (!(parseInt(Math.sqrt(a * a + b * b), 10) < THRESHOLD)) {
      if (x1 - x > Math.abs(y - y1)) {
        direction = "left";
      }
      if (x - x1 > Math.abs(y - y1)) {
        direction = "right";
      }
      if (y1 - y > Math.abs(x - x1)) {
        direction = "up";
      }
      if (y - y1 > Math.abs(x - x1)) {
        direction = "down";
      }
    } else {
      direction = "none";
    }

    swapTiles(rootIndex.current.index, (() => {
      switch (direction) {
        case "left":
          return { secondIndex: rootIndex.current.index - 1, direction }
        case "right":
          return { secondIndex: rootIndex.current.index + 1, direction }
        case "up":
          return { secondIndex: rootIndex.current.index - 3, direction }
        case "down":
          return { secondIndex: rootIndex.current.index + 3, direction }
        default:
          return { secondIndex: rootIndex.current.index, direction }
      }
    })()
    )
  }

  return (
    <section className='game-page puzzle'>
      <PageHeader>{questions.content[currentQuestion].title ?? "PUZZLE SPAß INCOMING!"}</PageHeader>
      <div className="game">
        <div className="text">
          <div className="question">
            <h4>{questions.content[currentQuestion].question}</h4>
            {width >= SCREEN_XGA &&
              <Button action={hadnleButtonClick} disabled={!userBoard.map(e => e.ident).every((element, index) => questions.content[currentQuestion].correctAnswers[index] === element)}>Nächste</Button>}
          </div>
        </div>
        <div className="board" onPointerDown={onPointerDown} onPointerUp={onPointerUp}>
          {userBoard.length !== 0 && userBoard.map((element, index) => (
            <img data-index={index} className="tile" src={element?.img} alt="" key={index} draggable={false} />
          ))}
        </div>
        {width < SCREEN_XGA &&
          <Button action={hadnleButtonClick} disabled={!userBoard.map(e => e.ident).every((element, index) => questions.content[currentQuestion].correctAnswers[index] === element)}>Nächste</Button>}
      </div>
    </section>
  )
}
