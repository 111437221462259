import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Arrow from "../../../img/arrow.svg"
import Button from '../../components/Button';
import { fetchData, useWindowSize } from '../../Helpers';
import "./style.scss"
import ArrowGray from "../../../img/arrow-gray.svg"
import ActivityDoneWider from "./img/done-wider.svg"
import { ReactComponent as Gift } from "../../../img/gift-red-full.svg"

import NewsletterMain from "./Activities/Idents/newsletter/img/main.webp"
import InstagramFollowMain from './Activities/Idents/instagram_follow/img/main.webp'
import OMRImg from "./Activities/Idents/omr_countdown_local/img/OMR-desktop.jpg"


export const disableIntroIn = [
  "instagram_follow",
  "newsletter",
  "meet_1"
];
export const alwaysShown = ["newsletter",]
export const hiddenActivities = ["poll_1", "quiz_6", "quiz_19"]

export default function ActivitiesPage() {
  const [apiActivites, setAPIActivities] = useState([])
  const { width } = useWindowSize();
  const [gloProfilePoints, setGloProfilePoints] = useState(0)
  const [forceShowActivities, setForceShowActivities] = useState(false)
  const [loading, setLoading] = useState(true)
  const [newsletterContent, setNewsletterContent] = useState({
    ident: "newsletter",
    completed: false,
    thumbnail: {
      file: {
        url: NewsletterMain,
      }
    },
    type: "AUFGABE",
    content: {
      title: "Check unseren Newsletter aus",
      description: "Bleib up to date und sammel Punkte!",
    },
    payout: 3
  })
  const SCREEN_XGA = 1020
  const [OMRFinished, setOMRFinished] = useState(false)

  const [disableCalendarIntro, setDisableCalendarIntro] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    fetchData({
      endpoint: "/activation/list",
      payload: {
        "limit": 100,
        "offset": 0
      },
      successCallback: resObj => {
        setAPIActivities(resObj.data.activations.filter(e => e.content && !e.expired && !hiddenActivities.includes(e.ident)));
        setGloProfilePoints(resObj.data.activations.filter(e => e.ident.includes("complete_profile_") && e.currentBalance === 0).map(e => e.payout).reduce((partial, a) => partial + a, 0))
        setOMRFinished(resObj.data.activations.find(e => e.ident === "poll_1")?.currentNumberOfSolutions === 0 ? false : true)
        const calendarIntro = resObj.data?.advent_2023
        setDisableCalendarIntro(calendarIntro)
      },
      controller: controller,
      setLoading: setLoading
    })


    fetchData({
      endpoint: "/consumer/stats/",
      payload: {},
      successCallback: resObj => {
        setNewsletterContent(prev => ({ ...prev, payout: resObj.data?.newsletter?.payout === 0 ? 3 : resObj.data?.newsletter?.payout }))
      },
      controller: controller
    })

    return () => {
      controller.abort()
    }
  }, [])


  const data = {
    "invite": {
      "imgMobile": require("./img/invite-mobile.jpg"),
      "imgDesktop": require("./img/invite-desktop.jpg"),
      "type": "AUFGABE",
      "title": "Guten Freunden empfiehlt man glo™.",
      "desc": "Du bist glo™-Fan? Empfiehl uns deinen Freunden und kassiere dafür ganze 80 Punkte!",
      "points": "80",
    },
    "selected": {
      "thumbnail": {
        "file": {
          "url": require("./img/selected-mobile.jpg")
        }
      },
      "type": "AUFGABE",
      "content": {
        "title": "Erzähl uns mehr über dich.",
        "description": "Was ist deine Lieblings-Jahreszeit und welche sind deine favourite Social-Media-Kanälen? Lass es uns wissen und erhalte Punkte!",
      },
      "payout": gloProfilePoints,
      "ident": "glo-profile",
      "completed": gloProfilePoints === 0 ? true : false
    },
    ORM: {
      img: OMRImg,
      type: "Quiz",
      title: OMRFinished ? "Danke für deine Teilnahme!" : "OMR-QUIZ",
      desc: OMRFinished ? "Wir werden dich in Kürze benachrichtigen, wenn du zu den glücklichen Gewinnern gehörst." : "Beantworte alle Fragen richtig und nimm somit am Gewinnspiel teil. Der Gewinn? 2 x OMR-Festival Tickets im Wert von 798 €!",
      poitsCopy: "2x TICKETS",
      url: "omr_countdown_local/spiel"
    }
  }

  const activites = [...apiActivites, newsletterContent].sort((a, b) => a.completed - b.completed)
  console.log(disableCalendarIntro)

  return (
    <section className="activities-page">
      <h1>AUFGABEN
      </h1>
      <Link to="/einladen">
        <div className="invite">
          <div className="image" style={{ backgroundImage: `url(${width < SCREEN_XGA ? data.invite.imgMobile : data.invite.imgDesktop})` }}>
          </div>
          <div className="content">
            <p className="type">{data.invite.type}</p>
            <h4 className="title">{data.invite.title}</h4>
            <p className="desc">{data.invite.desc}</p>
            <div className="bottom">
              <div className="arrow">
                <img src={Arrow} alt="" />
              </div>
              <div className="points">+{data.invite.points}<span>P</span></div>
            </div>
          </div>
        </div>
      </Link>
      {!loading && <>
        <div className="desktop-wrapper">
          {!(gloProfilePoints === 0 && activites.filter(e => !alwaysShown.includes(e.ident)).every(e => e.completed) && !forceShowActivities) && <div className={`selected-activity ${gloProfilePoints === 0 ? "hidden" : ""}`}>
            <Link to="/aufgaben/glo-profile" data-disabled={gloProfilePoints === 0}>
              <div className="image">
                <img src={data.selected.thumbnail.file.url} alt="" />
              </div>
              <div className="content">
                <p className="type">{data.selected.type}</p>
                <h3 className="title">{data.selected.content.title}</h3>
                <p className="desc">{data.selected.content.description}</p>
                <div className="bottom">
                  <div className="arrow">
                    <img src={Arrow} alt="" />
                  </div>
                  <div className="points">+{gloProfilePoints}<span>P</span></div>
                </div>
              </div>
            </Link>
          </div>}

          {/* <div className="selected-activity pulse">
            <Link to={`/aufgaben/${data.ORM.url}`} style={{ pointerEvents: OMRFinished ? "none" : "" }}>
              <div className="image">
                <img src={data.ORM.img} alt="" />
              </div>
              <div className="content">
                <p className="type">{data.ORM.type}</p>
                <h3 className="title">{data.ORM.title}</h3>
                <p className="desc">{data.ORM.desc}</p>
                <div className="bottom">
                  <div className="arrow">
                    <img src={Arrow} alt="" />
                  </div>
                  <div className="points">{data.ORM.poitsCopy}</div>
                </div>
              </div>
            </Link>
          </div> */}

          <div className="activities">
            {!(gloProfilePoints === 0 && activites.filter(e => !alwaysShown.includes(e.ident)).every(e => e.completed) && !forceShowActivities) ? activites.map((element, index) => (
              <Link to={`/aufgaben/${element.ident}${(disableIntroIn.includes(element.ident) || (disableCalendarIntro && element.ident.includes("advent_2023"))) ? "/spiel" : ""}`} data-disabled={element.ident.includes("advent_2023") ? false : element.ident === "glo-profile" ? gloProfilePoints === 0 : element.completed} key={index}>
                <div className={`activity ${element.ident.includes("advent_2023") ? "" : (element.ident === "glo-profile" ? gloProfilePoints === 0 : element.completed) ? "done" : ""}`} >
                  <div className="image">
                    <img src={element?.thumbnail.file.url} alt="" />
                  </div>
                  <div className="content">
                    <p className="type">{element?.type}</p>
                    <h4 className="title" dangerouslySetInnerHTML={{ __html: element?.content?.title.replace("ß", "&#7838;") }} />
                    <p className="desc" dangerouslySetInnerHTML={{ __html: element?.content?.description.replace("ß", "&#7838;") }} />
                    <div className="bottom">
                      <div className="arrow">
                        <img src={Arrow} alt="" />
                      </div>
                      {element.ident.includes("advent_2023") ? "" : <div className="points">+{element?.payout}<span>P</span></div>}
                    </div>
                  </div>
                </div>
              </Link>
            ))
              :
              activites.filter(e => alwaysShown.includes(e.ident)).map((element, index) => (
                <Link to={`/aufgaben/${element.ident}${(disableIntroIn.includes(element.ident) || (disableCalendarIntro && element.ident.includes("advent_2023"))) ? "/spiel" : ""}`} data-disabled={element.completed} key={index}>
                  <div className={`activity ${element.completed ? "done" : ""}`} >
                    <div className="image">
                      <img src={element?.thumbnail.file.url} alt="" />
                    </div>
                    <div className="content">
                      <p className="type">{element?.type}</p>
                      <h4 className="title" dangerouslySetInnerHTML={{ __html: element?.content?.title.replace("ß", "&#7838;") }} />
                      <p className="desc" dangerouslySetInnerHTML={{ __html: element?.content?.description.replace("ß", "&#7838;") }} />
                      <div className="bottom">
                        <div className="arrow">
                          <img src={Arrow} alt="" />
                        </div>
                        {element.ident.includes("advent_2023") ? "" : <div className="points">+{element?.payout}<span>P</span></div>}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
        {gloProfilePoints === 0 && activites.filter(e => !alwaysShown.includes(e.ident)).every(e => e.completed) && !forceShowActivities &&
          <div className="soon">
            <div className="card">
              <div className="content">
                <div className="text">
                  <h2>Alle Aufgaben erledigt!</h2>
                  <p>Tausche jetzt deine Punkte gegen Prämien ein! Schau‘ bald wieder rein für neue Aufgaben.</p>
                </div>
                <div className="img-wrapper">
                  <img src={ActivityDoneWider} alt="" />
                </div>
              </div>
              <div className="template">
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
                <div className="flex-bottom">
                  <img src={ArrowGray} alt="" />
                  <div className="points"></div>
                </div>
              </div>
            </div>
            <div className="buttons-wrapper">
              <Gift />
              <Button href="/pramienshop">Zum Prämienshop</Button>
              <Button btnClass='white' action={() => setForceShowActivities(true)}>Erledigte Aufgaben</Button>
            </div>
          </div>}
      </>}
    </section>
  )
}
