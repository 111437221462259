import React, { useEffect, useState } from 'react';
import { sendEvent, useWindowSize } from "../../../../Helpers";
import "../style.scss"
import { useUserDispatch } from '../../../../UserContext/context';
import { showErrorPopup, translateError } from "../../../../components/ErrorHandling"
import NiceModal from '@ebay/nice-modal-react';
import { getUserDetails } from '../../../../UserContext';
import PageHeader from '../../../../components/PageHeader';

export const Calendar = ({ questions, currentQuestion }) => {
    const dispatch = useUserDispatch()
    const [idents, setIdents] = useState([])
    const [localPayout, setLocalPayout] = useState(-1)
    const [time, setTime] = useState(new Date())
    const getAdventId = (ident) => {
        console.log(ident)
        return ident.split("_")[2]
    }

    const getActivites = async () => {

        const responses = await Promise.allSettled([
            fetch(process.env.REACT_APP_API_URL + "/activation/list", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid,
                    'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
                },
                body: JSON.stringify({
                })
            }),
            fetch(process.env.REACT_APP_API_URL + "/activation/list", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid,
                    'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
                },
                body: JSON.stringify({
                    "limit": 100,
                    "offset": 0,
                    "expired": true,
                    "contest": false,
                    "withSolutions": []
                })
            })
        ])

        let activations = []
        let finalArray = new Array(31).fill(0)

        for (const response of responses) {
            const data = await response.value.json()
            if (data.status.success) {
                activations.push(...data.data.activations)
                setTime(new Date(data.time.date.replace(" ", "T")))
            } else {
                showErrorPopup(translateError(data.data.error), data.meta.ts)
            }
        }

        activations = activations.filter(e => e.ident.includes("advent_2023")).sort((a, b) => +getAdventId(a.ident) - +getAdventId(b.ident))



        for (const element of activations) {
            let index = getAdventId(element.ident)
            finalArray[index - 1] = element
        }

        setIdents([...finalArray])
        console.log(finalArray)
        // const activities = await response.json()
        // if (activities.status.success) {
        //   let newActivites = activities.data.activations.filter(e => e.ident.includes("grudzien1_")).sort((a, b) => +a.ident.match(/_(\d+)_/)[1] - +b.ident.match(/_(\d+)_/)[1])
        //   let finalArray = new Array(31).fill(0)
        //   for (const element of newActivites) {
        //     let index = element.ident.match(/_(\d+)_/)[1]
        //     finalArray[index - 1] = element
        //   }
        //   setIdents([...finalArray])
        //   setTime(new Date(activities.time.date.replace(" ", "T")))
        // } else {
        //   showErrorPopup(translateError(activities.data.error), activities.meta.ts)
        // }
    }

    useEffect(() => {
        getActivites()
    }, [])

    const CheckAnswers = ident => {
        const data = {
            activation: {
                ident: ident,
                payload: {}
            }
        }

        fetch(process.env.REACT_APP_API_URL + "/activation/solution/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid,
                'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                return response.json()
            })
            .then(
                (result) => {
                    if (result.status?.success) {
                        setTimeout(() => getActivites(), 500)
                        getUserDetails(dispatch)
                        setLocalPayout(result.data.payout)
                        NiceModal.show("calendar-modal", { payout: result.data.payout, shouldNavigate: true })
                    } else {
                        showErrorPopup(translateError(result.data.error), result.meta.ts)
                    }
                },
                (error) => {
                    showErrorPopup(translateError("generic"))
                    console.log(error)
                }
            )
    }


    return (
        <div className={`game-page calendar`}>
            <PageHeader>{questions.content[currentQuestion].title ?? "glo+ Quiz"}</PageHeader>
            <div className='game-wrapper'>
                <div className='main-wrapper'>
                    <div className="grid">
                        {idents.map((element, index) => {
                            //przed prod zmienić date na 12
                            const startDate = new Date(`2023-12-${(index + 1).toString().length === 1 ? `0${index + 1}` : index + 1}T00:00:00.000000`)
                            const finishDate = new Date(`2023-12-${(index + 1).toString().length === 1 ? `0${index + 1}` : index + 1}T23:59:59.000000`)
                            let disableButtonByDate = true

                            if (time >= startDate && time <= finishDate) {
                                disableButtonByDate = false
                            }

                            console.log(element?.completed, element?.ident)

                            return (
                                <button key={index} onClick={() => CheckAnswers(element?.ident)} disabled={(element?.expired ?? true) || (element?.completed ?? true) || (element?.currentBalance ?? 1) > 0 || disableButtonByDate}>
                                    {element?.completed || element?.currentBalance > 0 ? <span className='redeemed'>{element.payout} pkt</span> : time >= finishDate ? "" : index + 1}
                                </button>
                            )
                        })}
                    </div>
                </div>
            </div>

        </div>
    );
}
