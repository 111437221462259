import React, { useEffect } from 'react'
import QRCode from 'qrcode'
import MobileDetect from "mobile-detect"
import Button from '../../components/Button';
import OK from "./img/ok-icon.svg"
import ZonePlusIcon from "../../components/Nav/img/zone-plus-icon.svg"
import { getUrlParam } from '../../UrlParamsHelper';
import "./style.scss"
import Spider from '../../components/Halloween';
import EasterEgg from '../../components/EasterEgg';

export default function ZonePlusPage() {
  const md = new MobileDetect(window.navigator.userAgent);
  const isMobile = md.mobile() ? true : false

  const token = JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
  const url = (process.env.REACT_APP_BRANCH === "dev" && getUrlParam("arReturnUrl") ? getUrlParam("arReturnUrl") : process.env.REACT_APP_AR_URL) + "?utm_content=zoneplusqr&token=" + token
  const urlMobile = (process.env.REACT_APP_BRANCH === "dev" && getUrlParam("arReturnUrl") ? getUrlParam("arReturnUrl") : process.env.REACT_APP_AR_URL) + "?utm_content=zoneplusbutton&token=" + token

  useEffect(() => {
    if (!isMobile && token) {
      const canvas = document.getElementById('qr-code')

      QRCode.toCanvas(canvas, url, { width: 256, height: 256 }, (error) => {
        if (error) console.error(error)
      })
    }
  }, [token])

  return (
    <section className="zone-plus-page">
      <div className='ar-content'>

        <div className='ar-copy'>
          <h2>Entdecke ein <br />brandneues <br />Universum!</h2>
          <EasterEgg uuid={"d84b66cb-b08e-42ea-8f13-0fd675998307"} />
          <p className='sub-title'>Willkommen in Zone+, wo Scannen sich lohnt!</p>

          <h4>neo™ oder veo™ Packs scannen und Punkte sammeln.</h4>

          <ul>
            <li>
              <img src={OK} alt="" />
              <p><strong>Scannen:</strong> Starte den Scanner, scanne die neo™ oder veo™ Verpackungen und lerne, wie du die glo™ Augmented Reality nutzen kannst.</p>
            </li>
            <li>
              <img src={OK} alt="" />
              <p><strong>Punkte sammeln:</strong> Mit dem ersten Scan erhältst du Punkte auf deinem Konto.</p>
            </li>
            <li>
              <img src={OK} alt="" />
              <p><strong>Bleib dran für mehr…</strong></p>
            </li>
          </ul>
          {!isMobile && <p>Zone+ wurde für mobile Geräte entwickelt. Entdecke neue spannende Aufgaben, indem du QR-Codes oder Ähnliches mit deinem Smartphone oder Tablet scannst.</p>}
        </div>

        <div className='separator'></div>
        <p className='disclaimer'>Zone+ benötigt ein paar zusätzliche Berechtigungen, damit Du das volle Erlebnis genießen kannst. Bitte erlaube uns den Zugriff auf Deine Kamera. Keine Sorge, wir speichern keine Deiner Daten. Die Kamera wird lediglich für Zone+ unterstützende Funktionen verwendet.</p>
        {!isMobile && <div className='qr-code-wrapper'><canvas id="qr-code"></canvas></div>}
        {isMobile && <div className='buttons'><Button btnClass='orange' href={urlMobile} target="_self" rel="noreferrer external"><img src={ZonePlusIcon} alt="" /><span>Zone+ starten</span></Button></div>}
      </div>
    </section>
  )
}
