import React, { useEffect, useState } from 'react'
import Button from '../../../components/Button'
import PageHeader from '../../../components/PageHeader'
import "./style.scss"
import { useParams } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html';
import * as DOMPurify from 'dompurify';
import { fetchData, getCookie, getModuleValue, sendEvent } from '../../../Helpers'
import { showErrorPopup, translateError } from '../../../components/ErrorHandling'


export default function IntroTemplate() {
  const [content, setContent] = useState({})
  const ident = "poll_1"

  useEffect(() => {
    const controller = new AbortController()
    fetchData({
      endpoint: "/activation/describe",
      payload: {
        "activation": {
          "ident": ident,
          "withSolutions": false
        }
      },
      successCallback: resObj => setContent(resObj.data),
      controller: controller
    })

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <section className='intro-template omr-poll'>
      <PageHeader>OMR-QUIZ</PageHeader>
      <div className="wrapper">
        <div className="image">
          <img src={getModuleValue(content?.content?.modules, "hero_image", "file.url")} alt="" />
        </div>
        <div className="content">
          <div className="text">
            <h1>TIME TO WIN*</h1>
            <p>Wähle die richtige Antwortmöglichkeit zu den Fragen aus.</p>
          </div>
          <Button href={DOMPurify.sanitize(`/aufgaben/${ident}/spiel`) ?? "/"} action={() => sendEvent(`${ident}_START`)} disabled={content.expired || content.currentNumberOfSolutions !== 0}>Jetzt loslegen</Button>
          <p className="info">
            * Das Gewinnspiel und das dazugehörige Quiz laufen ab dem 27.03.2023 um 15:00 Uhr bis zum 06.04.2023 um 23:59 Uhr. Die Teilnahme am Gewinnspiel erfolgt automatisch bei korrekter Beantwortung aller Quiz-Fragen. Die Gewinner werden im Losverfahren gezogen. Die 30 Gewinner werden am 11.04.2023. informiert. Der Gewinn enthält 2 OMR-Tickets, ohne Hotel- und Anreisekosten und kann nicht gegen Bargeld eingetauscht werden. Nur für erwachsene Nikotinkonsumenten. Die vollständigen Teilnahmebedingungen sind <a href="https://api.glo-loyalty-de.tdy-apps.com/upload/2023-03-20/teilnahmebedingunggloxomr_docx-6418737ad74be.pdf" target="_blank" rel="noopener noreferrer">hier</a> verfügbar.
          </p>
        </div>
      </div>
    </section>
  )
}
