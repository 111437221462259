import React from 'react'
import { useNavigate } from 'react-router-dom';
import Back from "../../../img/back.svg"
import "./style.scss"

export default function PageHeader({ children, customBack = null }) {
  const navigate = useNavigate();
  const isSzligPresent = children ? children.includes("ß") : false

  return (
    <div className='page-header'>
      <div className="back-icon">
        <button onClick={!customBack ? () => navigate(-1) : customBack}>
          <div className="icon-wrapper">
            <img src={Back} alt="" />
          </div>
        </button>
      </div>
      {
        isSzligPresent ?
          <div className="title" dangerouslySetInnerHTML={{ __html: children.replace("ß", "&#7838;") }} />
          :
          <div className="title">{children}</div>
      }
    </div >
  )
}
