import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import "./styles/index.scss";
import MainView from "./app/MainView";
import { UserProvider } from './app/UserContext';
import { ArticleProvider } from './app/ArticlesContext';
import { initUrlParams } from './app/UrlParamsHelper';
import NiceModal from '@ebay/nice-modal-react';
import { BrowserRouter } from 'react-router-dom';
import * as DOMPurify from 'dompurify';

const checkUrlParams = () => {
  // replace '&' with '?' in search params
  const includesAmp = window.location.href.includes("&")
  const includesQuestion = window.location.href.includes("?")
  if (includesAmp && !includesQuestion) window.location.href = DOMPurify.sanitize(window.location.href.replace("&", "?"))

  // get parsed URL params, before app init
  initUrlParams()
}

const savePrompt = () => {
  // console.log("listen to beforeinstallprompt")
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    window.deferredPrompt = e;
    // console.log(`'beforeinstallprompt' event was fired.`);
  });
}

savePrompt()
checkUrlParams();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
      <UserProvider>
        <NiceModal.Provider>
          <MainView />
        </NiceModal.Provider>
      </UserProvider>
    </BrowserRouter>
  </ >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
