import React, { useEffect, useState } from 'react'
import Button from '../../../../components/Button'
import PageHeader from '../../../../components/PageHeader'
import "../style.scss"
import { useParams } from 'react-router-dom'
import DOMPurify from 'dompurify';
import { fetchData, getModuleValue, sendEvent } from '../../../../Helpers'
import MainImg from "../Idents/instagram_follow/img/main.webp"
import { getUserDetails, useUserDispatch } from '../../../../UserContext';


export default function InstagramFollow({ notificationRef, content }) {
  const [linkClicked, setLinkClicked] = useState(false)
  const [getPointsLoading, setGetPointsLoading] = useState(false)
  const dispatch = useUserDispatch()
  const [pointsRecieved, setPointsRecieved] = useState(false)

  const getPoints = () => {
    setGetPointsLoading(true)
    fetchData({
      endpoint: "/activation/solution/create",
      payload: {
        "activation": {
          "ident": "instagram_follow",
          "payload": {}
        }
      },
      successCallback: () => {
        getUserDetails(dispatch)
        notificationRef.current = true
        setPointsRecieved(true)
        setGetPointsLoading(false)
      },
    })
  }

  return (
    <section className='intro-template game-newsletter'>
      <PageHeader>Folg uns auf Insta</PageHeader>
      <div className="wrapper">
        <div className="image">
          <img src={MainImg} alt="" />
        </div>
        <div className="content">
          {linkClicked ?
            <>
              <div className="text" >
                <h1>MEGA</h1>
                <p>Danke fürs Folgen unseres glo+ Instagram Accounts!</p>
                <div className="card-wrapper">
                  <div className="card">
                    <p className="points">
                      <span>{10}</span> Punkte
                    </p>
                    <p className="copy">Für deinen Follow</p>
                  </div>
                </div>
              </div>
              <Button action={getPoints} disabled={getPointsLoading || pointsRecieved}>
                {
                  !pointsRecieved ?
                    <>
                      <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 4L3.5 7L10 1" stroke="white" strokeWidth="2" />
                      </svg>
                      Punkte sammeln
                    </>
                    :
                    "Punkte hinzugefügt"
                }</Button>
            </>
            :
            <>
              <div className="text" >
                <h1>Folg uns</h1>
                <p>Willst du immer erfahren, was los ist und mit uns in Kontakt bleiben?
                  Besuch uns auf Instagram und entdeck spannenden Content. Folg uns
                  und komm zu dieser Seite zurück, um dir 10 Punkte zu sichern!</p>
              </div>
              <Button href={"https://www.instagram.com/glo.germany/"} action={() => setTimeout(() => { setLinkClicked(true); }, 1000 * 2)} disabled={content?.completed || content?.expired}>{content?.completed ? "Aufgabe schon erledigt" : "Jetzt folgen"}</Button>
            </>
          }
        </div>
      </div>
    </section>
  )
}
