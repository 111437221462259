import React, { useEffect, useState } from 'react'
import Modal from "../index"
import giftIcon from "./img/gift_icon.svg"
import tickIcon from "./img/tick_icon.svg"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import { getUserDetails, useUserDispatch, useUserState } from '../../../UserContext';
import { showErrorPopup, translateError } from '../../ErrorHandling';
import { fetchData, getCookie } from '../../../Helpers';
import DOMPurify from 'dompurify';

export default NiceModal.create(() => {
  const modal = useModal();
  const [loading, setLoading] = useState(false)
  const { img, price, title, ident, discount } = modal.args
  const [step, setStep] = useState(0)
  const dispatch = useUserDispatch()
  const { userDetails } = useUserState()

  useEffect(() => {
    getUserDetails(dispatch);
  }, [])

  const redeemReward = async () => {
    fetchData({
      endpoint: "/prize/redeem",
      payload: {
        "catalogue": {
          "ident": DOMPurify.sanitize(ident)
        }
      },
      successCallback: () => {
        setStep(prev => ++prev);
        getUserDetails(dispatch);
      },
      errorCallback: resObj => {
        modal.hide(); modal.remove();
        getUserDetails(dispatch);
        if (resObj.data.error === "error_critical_exception") {
          showErrorPopup(translateError("redeem_error_critical_exception"), resObj.meta.ts)
        } else {
          showErrorPopup(translateError(resObj.data.error), resObj.meta.ts)
        }
      },
      setLoading: setLoading,
      shouldThrowError: false
    })
  }

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()} barIcon={step === 0 ? giftIcon : tickIcon}
      innerClass={"collect-reward-modal"}>
      {step === 0 ?
        <div>
          <h3 className="upper">
            Möchten Sie<br />Ihre Prämie abholen?
          </h3>
          <div className="desktop-wrapper">
            <div className="image">
              <img src={img} alt="" />
              {ident?.includes("_disc") && <div className='type discount'><p className='discount-procent'>-{discount}%</p><p className='discount-date'>24-26.11</p></div>}
            </div>
            <div>
              <p className="title">{title}</p>
              <div className="summary">
                <div className="points">
                  <div>{userDetails?.balance}<span>P</span></div>
                  <p>Prämien-Punkte</p>
                </div>
                <div className={`price-wrapper`}>
                  <p className={`price ${ident?.includes("_disc") ? "discount" : ""}`}>-{price}<span>P</span></p>
                  <p>Aufwand</p>
                </div>
              </div>
            </div>
          </div>
          <Button action={redeemReward} loading={loading}>
            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M13.0202 0.940348C13.4215 1.31977 13.4392 1.95269 13.0598 2.35401L5.39984 10.4559L0.93986 5.73862C0.560434 5.33731 0.578179 4.70439 0.979495 4.32496C1.38081 3.94554 2.01373 3.96328 2.39315 4.3646L5.39984 7.54475L11.6065 0.979983C11.986 0.578667 12.6189 0.560922 13.0202 0.940348Z" fill="white" />
            </svg>
            Ja
          </Button>
        </div>
        :
        <div>
          <h3 className="upper">
            Belohnung erhalten!
          </h3>
          <div className="button-wrapper">
            <Button href="/pramienshop/eingelost" action={() => modal.hide()}>Gehe zu erlöst</Button>
            <Button href="/pramienshop" action={() => modal.hide()} btnClass='white'>Zurück zum Katalog</Button>
          </div>
        </div>
      }
    </Modal>
  );
});
