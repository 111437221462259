import React, { useEffect } from 'react'
import Arrow from "../../../img/arrow-orange.svg"
import Unavailable from "./img/unavailable.svg"
import Tick from "../../../img/tick-orange-light.svg"
import HourGlass from "../../../img/hourglass.svg"
import TickWhite from "../../../img/tick-white.svg"
import "./style.scss"
import { useState } from 'react'
import { fetchData, getCookie, levelMapper, useWindowSize } from '../../Helpers'
import { Link } from 'react-router-dom'
import Dropdown from '../../components/Dropdown'
import { useUserState } from '../../UserContext'
import { showErrorPopup, translateError } from '../../components/ErrorHandling'
import DOMPurify from 'dompurify'

export default function Catalogue() {
  const { userDetails } = useUserState()
  const { width } = useWindowSize()
  const SCREEN_XGA = 1020
  const [awards, setAwards] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [filterOut, setFilterOut] = useState({
    basic: userDetails?.level < 1 ?? false,
    advanced: userDetails?.level < 2 ?? false,
    professional: userDetails?.level < 3 ?? false,
    master: userDetails?.level < 4 ?? false,
    showAvailable: false
  })


  useEffect(() => {
    // const getRewards = async () => {
    //   const response = await fetch(process.env.REACT_APP_API_URL + "/prize/catalogue/list", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " +  (getCookie("loyalty_sso_session_id") ?? JSON.parse(sessionStorage.getItem('token')).uuid),
    //     },
    //     body: JSON.stringify({
    //       "limit": 100,
    //       "offset": 0,
    //       "expired": false
    //     })
    //   })
    //   const awards = await response.json()
    //   if (awards.status.success) {
    //     setAwards(awards.data.catalogue)
    //   } else {
    //     showErrorPopup(translateError(awards.data.error), awards.meta.ts)
    //   }
    // }
    // getRewards()
    const controller = new AbortController()
    fetchData({
      endpoint: "/prize/catalogue/list",
      payload: {
        "limit": 100,
        "offset": 0,
        "expired": false
      },
      successCallback: resObj => setAwards(resObj.data.catalogue.
        filter(e => !e.internal && e.content).
        sort((a, b) => !a.inStock - !b.inStock || a.expired - b.expired || !a.available - !b.available || b.level - a.level || a.basePrice - b.basePrice)),
      controller: controller
    })

    return () => {
      controller.abort()
    }
  }, [])

  const filterData = () => {
    let filteredKeys = Object.keys(filterOut).filter(key => filterOut[key]).filter(e => e !== "showAvailable")
    let result = []

    const filterLogic = e => {
      if (!filteredKeys.includes(levelMapper.get(e.level))) {
        if (!filterOut.showAvailable) {
          result.push(e)
          return
        }
        if (e.basePrice <= userDetails?.balance) result.push(e)
      }
    }

    awards.filter(e => e?.content?.title).filter(filterLogic)

    return result
  }


  return (
    <section className='rewards-catalogue'>
      <div className="page-header-nav">
        <h1>Prämienshop</h1>
        <div className="link active">
          Katalog
        </div>
        <Link to="/pramienshop/eingelost" className="link">
          Eingelöst
        </Link>
      </div>
      <div className="desktop-wrapper">

        <div className="filter">
          <Dropdown
            summary={<>
              <h4
              // className={`${Object.values(filterOut).some(e => e === true) ? "filter-applied" : ""}`}
              >
                Filter</h4>
              {/* {Object.values(filterOut).some(e => e === true) && <button className='reset' onClick={() => {
                setFilterOut({
                  basic: false,
                  advanced: false,
                  professional: false,
                  master: false,
                  showAvailable: false
                })
              }}>
                Alle zurücksetzen
              </button>} */}
            </>}
            details={
              <div className='inner'>
                <p className='status'>Status Range</p>
                <div className="filter-type">
                  <div className={`type ${filterOut.basic ? "filter-out" : ""} ${!filterOut.advanced ? "line" : ""}`}>
                    <button id='basic' onClick={() => setFilterOut(prev => ({ basic: false, advanced: true, professional: true, master: true, showAvailable: prev.showAvailable }))}>
                      <div className={`content `}>
                        <img src={TickWhite} alt="" />
                      </div>
                    </button>
                    <label htmlFor="basic">Basic</label>
                  </div>
                  <div className={`type ${filterOut.advanced ? "filter-out" : ""} ${!filterOut.professional ? "line" : ""}`}>
                    <button id='advanced' onClick={() => setFilterOut(prev => ({ basic: false, advanced: false, professional: true, master: true, showAvailable: prev.showAvailable }))}>
                      <div className={`content `}>
                        <img src={TickWhite} alt="" />
                      </div>
                    </button>
                    <label htmlFor="advanced">Advanced</label>
                  </div>
                  <div className={`type ${filterOut.professional ? "filter-out" : ""} ${!filterOut.master ? "line" : ""}`}>
                    <button id='professional' onClick={() => setFilterOut(prev => ({ basic: false, advanced: false, professional: false, master: true, showAvailable: prev.showAvailable }))}>
                      <div className={`content`}>
                        <img src={TickWhite} alt="" />
                      </div>
                    </button>
                    <label htmlFor="professional">Professional</label>
                  </div>
                  <div className={`type ${filterOut.master ? "filter-out" : ""} `}>
                    <button id='master' onClick={() => setFilterOut(prev => ({ basic: false, advanced: false, professional: false, master: false, showAvailable: prev.showAvailable }))}>
                      <div className={`content `}>
                        <img src={TickWhite} alt="" />
                      </div>
                    </button>
                    <label htmlFor="master">Master</label>
                  </div>
                </div>
                <div className="show-available">
                  <p>Prämien-Punkte:</p>
                  <label className={`switch ${filterOut.showAvailable ? "selected" : ""}`}>
                    <input type="checkbox" value={filterOut.showAvailable} onChange={() => setFilterOut(prev => ({ ...prev, showAvailable: !filterOut.showAvailable }))} />
                    <span className="slider"></span>
                    <p className="text">{filterOut.showAvailable ? "Erreichbar" : "Alle"}</p>
                  </label>
                </div>
                {/* <button className='reset' onClick={() => {
                  setFilterOut({
                    basic: false,
                    advanced: false,
                    professional: false,
                    master: false,
                    showAvailable: false
                  })
                }}>
                  Alle zurücksetzen
                </button> */}
              </div>
            }
            setOpen={() => setShowFilter(prev => !prev)}
            open={width < SCREEN_XGA ? showFilter : true}
          />
        </div>
        <div className="rewards">
          {console.log(filterData())}
          {filterData().map((element, index) => {
            const unavailable = !element?.available || !element?.inStock || element.expired

            return (
              <Link to={`/pramienshop/einlosen/${element.ident}`} className={`card-link`} key={index}>
                <div className={`card ${element.group === "omr_bundle" ? "pulse" : ""}`}>
                  <div className="image">
                    <div className="type">{levelMapper.get(element?.level)}</div>
                    {element.new && <div className="new"><p>New</p></div>}
                    {element.ident.includes("rosemary_") && <div className='type partner-of-the-month'>Partner des Monats</div>}
                    {element.ident.includes("_disc") && <div className='type discount'><p className='discount-procent'>-{element.discount}%</p><p className='discount-date'>24-26.11</p></div>}
                    <img src={element?.thumbnail?.file?.url} alt="" />
                  </div>
                  <div className="content">
                    <div className={`upper ${userDetails.level < element.level ? "insufficient-level" : unavailable || (element?.awardLimitExceeded && element.group === "omr") ? "unavailable" : ""}`}>
                      <div className={`points ${element.ident.includes("_disc") ? "discount" : ""}`}>
                        {element?.basePrice}<span>P</span>
                      </div>
                      {console.log(levelMapper.get(element.level).substring(0, 7).toUpperCase())}
                      <div className={`status ${(!element?.awardLimitExceeded && !element?.dailyLimitExceeded) && userDetails?.balance < element?.basePrice ? "progress" : ""}`}>
                        <p dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(element?.awardLimitExceeded || element?.dailyLimitExceeded ? (element?.awardLimitExceeded && element.group === "omr") ? "Prämienlimit erreicht" : "Bereits erhalten" : userDetails?.level < element?.level ? `Erforderlich: ${levelMapper.get(element?.level).length > 8 ? levelMapper.get(element?.level).substring(0, 7).toUpperCase() + "..." : levelMapper.get(element.level).toUpperCase()}` :
                            unavailable ? "ausverkauft" :
                              userDetails?.balance >= element?.basePrice ? "erreichbar" :
                                `Dir fehlen ${element?.basePrice - userDetails?.balance}<span>P</span>`)
                        }}>
                        </p>
                        {element?.awardLimitExceeded && element.group === "omr" && <img src={Unavailable} />}
                        {(!element?.awardLimitExceeded && !element?.dailyLimitExceeded) && userDetails.level >= element.level && <img src={unavailable ? Unavailable :
                          userDetails?.balance >= element?.basePrice ? Tick :
                            HourGlass} alt="" />}
                        {(!element?.awardLimitExceeded && !element?.dailyLimitExceeded) && userDetails?.balance < element?.basePrice && !unavailable && userDetails.level >= element.level
                          && <div className='front' style={{ clipPath: `inset(0 0 0 ${Math.min(userDetails?.balance / element?.basePrice * 100, 100)}%)` }}>
                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`Dir fehlen ${element?.basePrice - userDetails?.balance}<span>P</span>`) }}></p>
                            <img src={HourGlass} alt="" />
                          </div>}
                      </div>
                    </div>
                    <h4 className="title">
                      {element?.content?.title}
                    </h4>
                    <div className="arrow">
                      <img src={Arrow} alt="" />
                    </div>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </section>
  )
}
