import React, { useEffect, useRef, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Footer from "./components/Footer"
import Header from "./components/Header"
import ActivitiesPage from "./pages/ActivitiesPage";
import GloProfile from "./pages/ActivitiesPage/Activities/GloProfile";
import IntroTemplate from "./pages/ActivitiesPage/Activities/IntroTemplate";
import HomePage from "./pages/HomePage"
import InvitePage from "./pages/InvitePage"
import RankInfo from "./pages/RankInfo";
import Catalogue from "./pages/RewardsPage/Catalogue";
import Redeem from "./pages/RewardsPage/Redeem";
import Received from "./pages/RewardsPage/Received";
import GameProvider from "./pages/ActivitiesPage/Activities/GameProvider"
import "./components/Modal/modals"
import FaqPage from "./pages/FaqPage";
import TermsPage from "./pages/TermsPage";
import RefreshToken from "./pages/RefreshToken";
import { getUrlParam, initUrlParams, removeUrlParam } from "./UrlParamsHelper";
import { loginByToken, useUserDispatch, useUserState, getUserDetails } from "./UserContext";
import { ProtectedComponent, ProtectedRoute } from "./components/ProtectedComponent";
import ProfilePage from "./pages/ProfilePage";
import ArticlePage from "./pages/NewsPage/Article";
import LogoutWarning from "./components/LogoutWarning";
import LoggedOut from "./pages/StatePages/LoggedOut";
import NotFound from "./pages/StatePages/NotFound";
import { fetchData, getCookie } from "./Helpers";
import NotificationsPage from "./pages/NotificationsPage";
import NotificationsWrapper from "./components/NotificationsWrapper";
import NiceModal from '@ebay/nice-modal-react';
import IntroTemplateOMR from "./pages/ActivitiesPage/Activities/IntroTemplateOMR";
import EasterEggCounter from "./components/EasterEgg/EasterEggCounter";
import AwardForm from "./pages/RewardsPage/AwardForm";
import ZonePlusPage from "./pages/ZonePlusPage";
import SpidersCounter from "./components/Halloween/SpidersCounter";

const disablePopUpIn = [
  "/benachrichtigungen",
  "/pramienshop/einlosen/gloadv",
  "/pramienshop/einlosen/glopro",
  "/pramienshop/einlosen/glomaster",
]

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector(".app").scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const disableWelcomeModal = [
  "/token",
  "/404",
  "/logged-out",
]

function Transition() {
  let location = useLocation();
  const dispatch = useUserDispatch()
  const { userDetails } = useUserState()
  const [notifications, setNotifications] = useState([])
  const notificationRef = useRef(true)
  const welcomeRef = useRef(true)

  useEffect(() => {
    if(getUrlParam("hyperproar")) {
      localStorage.setItem("hyperproar", getUrlParam("hyperproar"))
      removeUrlParam("hyperproar")
    }
    if (getUrlParam("token") && !Boolean(userDetails.token?.uuid)) {
      loginByToken(dispatch, getUrlParam("token"))
    } else if (getCookie("loyalty_sso_session_id") && !Boolean(userDetails.token?.uuid)) {
      loginByToken(dispatch, getCookie("loyalty_sso_session_id"))
    } else {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent("SW_READY", {}), true)
        window.loaderC.hide();
      }, 1500)
    }
    initUrlParams()
    window.addEventListener("SW_READY", (e) => { document.querySelector(".loading-screen").classList.add("hide") })
    //eslint-disable-next-line
  }, [])

  if (userDetails?.firstName && !userDetails?.introductionCompleted && !disableWelcomeModal.includes(location.pathname) && welcomeRef.current) {
    setTimeout(() => NiceModal.show('welcome-modal'), 250)
    welcomeRef.current = false
  }

  if (userDetails.firstName && userDetails.newMessage && JSON.parse(sessionStorage.getItem("token"))?.uuid && notificationRef.current) {
    notificationRef.current = false
    fetchData({
      endpoint: "/message/list",
      payload: {
        "limit": 100,
        "offset": 0
      },
      successCallback: resObj => {
        setNotifications(resObj.data.filter(e => !e.closed && !e.seen))
        dispatch({
          type: 'USER_DETAILS_SUCCESS',
          payload: { ...userDetails, newMessage: false }
        })
        getUserDetails(dispatch)
        // notificationRef.current = true
      },
      shouldThrowError: false
    })
  }

  // function isAccountOlderThan14Days(accountCreatedDate) {
  //   const createdDate = new Date(accountCreatedDate);
  //   const currentDate = new Date();
  //   const differenceInDays = (currentDate - createdDate) / (1000 * 60 * 60 * 24);
  //   return differenceInDays >= 14;
  // }

  return (
    <div className="app">
      <LogoutWarning />
      <div className={`page-holder ${JSON.parse(sessionStorage.getItem('token'))?.uuid ? "" : "padding-less"}`}>
        <div>
          <ProtectedComponent><Header /><EasterEggCounter /></ProtectedComponent>
          {<TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames="page"
              className="page-with-bg"
              timeout={{ enter: 250, exit: 250 }}
            >
              <Routes location={location}>
                <Route path={`${process.env.PUBLIC_URL}/`} exact element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aufgaben`} exact element={<ProtectedRoute><ActivitiesPage /></ProtectedRoute>} />
                {process.env.REACT_APP_BRANCH === "dev" && <Route path={`${process.env.PUBLIC_URL}/token`} exact element={<RefreshToken />} />}
                <Route path={`${process.env.PUBLIC_URL}/aufgaben/glo-profile`} exact element={<ProtectedRoute><GloProfile /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aufgaben/poll_1`} exact element={<ProtectedRoute><IntroTemplateOMR /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aufgaben/:ident`} exact element={<ProtectedRoute><IntroTemplate /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/aufgaben/:ident/spiel`} exact element={<ProtectedComponent><GameProvider notificationRef={notificationRef} /></ProtectedComponent>} />
                <Route path={`${process.env.PUBLIC_URL}/pramienshop`} exact element={<ProtectedRoute><Catalogue /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/pramienshop/einlosen/:ident`} exact element={<ProtectedRoute><Redeem /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/pramienshop/einlosen/:ident/form`} exact element={<ProtectedRoute><AwardForm /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/pramienshop/eingelost`} exact element={<ProtectedRoute><Received /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/status-vorteile`} exact element={<ProtectedRoute><RankInfo /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/einladen`} exact element={<ProtectedRoute><InvitePage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/faq`} exact element={<ProtectedRoute><FaqPage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/terms/:slug`} exact element={<ProtectedRoute><TermsPage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/profile`} exact element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/article/:slug`} exact element={<ProtectedRoute><ArticlePage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/logged-out`} exact element={<LoggedOut />} />
                <Route path={`${process.env.PUBLIC_URL}/benachrichtigungen`} exact element={<ProtectedRoute><NotificationsPage notificationRef={notificationRef} setPopupNotifications={setNotifications} /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/zone-plus`} exact element={<ProtectedRoute><ZonePlusPage /></ProtectedRoute>} />
                <Route path={`${process.env.PUBLIC_URL}/404`} exact element={<NotFound />} />
                <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<NotFound />} />
              </Routes>
            </CSSTransition>
          </TransitionGroup>}
          <ProtectedComponent>{!disablePopUpIn.includes(location.pathname) && notifications.length !== 0 &&
            <NotificationsWrapper notifications={notifications} notificationRef={notificationRef} setNotifications={setNotifications} />}</ProtectedComponent>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default function MainView() {
  return (
    <>
      <ScrollToTop />
      <Transition />
    </>
  );
}
