import React, { useEffect } from 'react'
import PageHeader from '../../components/PageHeader'
import Calendar from "../../../img/calendar.svg"
import Statute from "../../../img/statute.svg"
import Tick from "../../../img/tick-orange.svg"
import Image from "./img/reward.png"
import { useState } from 'react'
import Button from '../../components/Button'
import NiceModal from '@ebay/nice-modal-react';
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData, getCookie, getModuleValue, levelMapper, parseDate, parseHTMLToObjets, parseHTMLToText } from '../../Helpers'
import { getUserDetails, useUserDispatch, useUserState } from '../../UserContext'
import * as DOMPurify from 'dompurify';
import { showErrorPopup, translateError } from '../../components/ErrorHandling'

export default function Redeem() {
  const { ident } = useParams()
  const [content, setContent] = useState({})
  const [selector, setSelector] = useState("info")
  const [disable, setDisable] = useState(false)
  const { userDetails } = useUserState()
  const dispatch = useUserDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController()
    fetchData({
      endpoint: "/prize/catalogue/describe",
      payload: {
        "catalogue": {
          "ident": DOMPurify.sanitize(ident)
        }
      },
      successCallback: resObj => {
        getUserDetails(dispatch);
        setContent(resObj.data);
      },
      errorCallback: () => {
        getUserDetails(dispatch);
        setDisable(true);
      },
      controller: controller
    })

    return () => {
      controller.abort()
    }
  }, [])

  function reserveAward() {
    fetchData({
      endpoint: "/prize/reserve",
      payload: {
        "catalogue": {
          "ident": DOMPurify.sanitize(ident)
        }
      },
      shouldThrowError: false
    })
  }

  function handleRedeemButton() {
    if (content.content.modules.find(e => e.type === "form")) {
      reserveAward()
      navigate(`/pramienshop/einlosen/${ident}/form`)
      return
    }
    NiceModal.show('collect-reward', { img: getModuleValue(content?.content?.modules, "hero_image", "file.url"), price: content?.basePrice, title: content?.content?.title, ident: DOMPurify.sanitize(ident), discount: content?.discount })
  }

  return (
    <section className='redeem-reward'>
      <PageHeader>{content?.content?.title}</PageHeader>
      <div className="desktop-wrapper">
        <div className="image">
          <img src={getModuleValue(content?.content?.modules, "hero_image", "file.url")} alt="" />
          {content?.ident?.includes("_disc") && <div className='type discount'><p className='discount-procent'>-{content?.discount}%</p><p className='discount-date'>24-26.11</p></div>}
        </div>
        <div className="info mobile">
          <div className="selector">
            <button className={`${selector === "info" ? "selected" : ""}`}
              onClick={() => setSelector("info")}>Einzelheiten</button>
            <button className={`${selector === "howTo" ? "selected" : ""}`}
              onClick={() => setSelector("howTo")}>{getModuleValue(content?.content?.modules, "bullets", "metadata.desc")?.length !== 0 ? getModuleValue(content?.content?.modules, "bullets", "metadata.desc") : "Wie löse ich einen Code ein?"}</button>
          </div>
          {selector === "info" && <div className="reward-info">
            <p className='desc' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getModuleValue(content?.content?.modules, "intro", "custom")?.replace(/\&nbsp;/g, ' '), { ADD_ATTR: ['target'] }) }}></p>
            <div className="date">
              <img src={Calendar} alt="" />
              <p>Verfallsdatum: <strong>{parseDate(content?.expires?.date ?? "")}</strong></p>
            </div>
            {getModuleValue(content?.content?.modules, "terms", "custom") && <div className="statute">
              <img src={Statute} alt="" />
              <a href="">Vorschriften</a>
            </div>}
          </div>}
          {selector === "howTo" && getModuleValue(content?.content?.modules, "bullets", "custom") &&
            <>
              <h4 className="title">
                {getModuleValue(content?.content?.modules, "bullets", "metadata.name")?.length !== 0 ? getModuleValue(content?.content?.modules, "bullets", "metadata.name") : "Wie löse ich einen Code ein?"}
              </h4>
              <div className="how-to">
                {parseHTMLToObjets(getModuleValue(content?.content?.modules, "bullets", "custom"))
                  .map(({ tag, content }, index) => {
                    if (content === "" || tag === "" || !tag) {
                      return
                    }

                    if (tag === "li") {
                      return <div className='flex-list' key={index}>
                        <div className="tick">
                          <img src={Tick} alt="" />
                        </div>
                        <p className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
                      </div>
                    }

                    if (tag === "p") {
                      return <p key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
                    }
                  })}
              </div>
            </>
          }
        </div>
        <div className="info desktop">
          <h4 className="title">
            Einzelheiten
          </h4>
          <p className='desc' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getModuleValue(content?.content?.modules, "intro", "custom")?.replace(/\&nbsp;/g, ' '), { ADD_ATTR: ['target'] }) }}></p>
          <div className="info">
            <div className="date">
              <img src={Calendar} alt="" />
              <p>Verfallsdatum: <strong>{parseDate(content?.expires?.date ?? "")}</strong></p>
            </div>
            {getModuleValue(content?.content?.modules, "terms", "custom") && <div className="statute">
              <img src={Statute} alt="" />
              <a href="">Vorschriften</a>
            </div>}
          </div>
          {content.internal ? "" :
            <div className="button-wrapper">
              {content?.dailyLimitExceeded && <p>Diese Prämie kann einmal täglich eingelöst werden</p>}
              <div className={`price ${content?.ident?.includes("_disc") ? "discount" : ""}`}>
                {content?.basePrice}<span>P</span>
              </div>
              <Button action={handleRedeemButton}
                disabled={disable || content?.awardLimitExceeded || content?.dailyLimitExceeded || userDetails?.level < content?.level || userDetails?.balance < content?.basePrice || !content?.available || !content?.inStock || content.expired}>
                {content?.dailyLimitExceeded ? "Morgen wieder einlösbar" : content?.awardLimitExceeded ? "Bereits erhalten" : userDetails?.level < content?.level ? `Erforderlich: ${levelMapper.get(content?.level).toUpperCase()}` : !content?.available || !content?.inStock || content.expired ? "Ausverkauft" : userDetails?.balance < content?.basePrice ? <>{`Dir fehlen ${content?.basePrice - userDetails?.balance}`}<span>P</span></> : "Jetzt einlösen"}
              </Button>
            </div>}
          {getModuleValue(content?.content?.modules, "bullets", "custom") && <h4 className="title">
            {getModuleValue(content?.content?.modules, "bullets", "metadata.name")?.length !== 0 ? getModuleValue(content?.content?.modules, "bullets", "metadata.name") : "Wie löse ich einen Code ein?"}
          </h4>}
          {getModuleValue(content?.content?.modules, "bullets", "custom") && <div className="how-to">
            {parseHTMLToObjets(getModuleValue(content?.content?.modules, "bullets", "custom"))
              .map(({ tag, content }, index) => {
                if (content === "" || tag === "" || !tag) {
                  return
                }

                if (tag === "li") {
                  return <div className='flex-list' key={index}>
                    <div className="tick">
                      <img src={Tick} alt="" />
                    </div>
                    <p className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
                  </div>
                }

                if (tag === "p") {
                  return <p key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
                }
              })}
          </div>}
        </div>
      </div>
      {!content.internal && <div className="footer">
        {content?.dailyLimitExceeded && <p>Diese Prämie kann einmal täglich eingelöst werden</p>}
        <div className="inner">
          <div className={`price ${content?.ident?.includes("_disc") ? "discount" : ""}`}>
            {content?.basePrice}<span>P</span>
          </div>
          <Button action={handleRedeemButton}
            disabled={disable || content?.awardLimitExceeded || content?.dailyLimitExceeded || userDetails?.level < content?.level || userDetails?.balance < content?.basePrice || !content?.available || !content?.inStock || content.expired}>
            {content?.dailyLimitExceeded ? "Morgen wieder einlösbar" : content?.awardLimitExceeded ? "Bereits erhalten" : userDetails?.level < content?.level ? `Erforderlich: ${levelMapper.get(content?.level).toUpperCase()}` : !content?.available || !content?.inStock || content.expired ? "Ausverkauft" : userDetails?.balance < content?.basePrice ? <>{`Dir fehlen ${content?.basePrice - userDetails?.balance}`}<span>P</span></> : "Jetzt einlösen"}          </Button>
        </div>
      </div>}
    </section>
  )
}
