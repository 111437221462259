import React from 'react'
import Modal from "../index"
import ErrorIcon from "./img/error.svg"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import ErrorPage from "./img/error-page.svg"
import { useNavigate } from 'react-router-dom';
import { logout, useUserDispatch } from '../../../UserContext';

export default NiceModal.create(() => {
  const modal = useModal();
  const navigate = useNavigate();
  const dispatch = useUserDispatch()

  return (
    <Modal isOpen={modal.visible} onClose={() => { modal.hide(); if (modal?.args?.returnUrl) navigate(`${modal?.args?.returnUrl}`); if (modal?.args?.logout) logout(dispatch) }}
      onAfterClose={() => modal.remove()} barIcon={ErrorIcon} disableClose={modal?.args?.disableClose ?? true}
      innerClass={"correct-answer-modal error-modal"}>
      <h3 className="upper">{modal?.args?.title ?? ""}</h3>
      <p className="sub">{modal?.args?.desc ?? ""}</p>
      <img src={ErrorPage} alt="" />
      <p className="error">
        Error no. {modal?.args?.code ?? ""}
      </p>
      <Button action={() => { modal.hide(); if (modal?.args?.returnUrl) navigate(`${modal?.args?.returnUrl}`); if (modal?.args?.logout) logout(dispatch) }}>
        {modal?.args?.button ?? ""}
      </Button>
    </Modal>
  );
});
