import React, { useEffect, useRef, useState } from 'react'
import PageHeader from '../../../../components/PageHeader'
import Button from '../../../../components/Button'
import "swiper/css";
import _ from 'lodash';
import { useTransition } from 'react-spring'
import { usePopper } from 'react-popper'
import { useWindowSize } from '../../../../Helpers'
import CorrectPopup from './CorrectPopup'
import CorrectAnswerPopup from '../../../../components/CorrectAnswerPopup/CorrectAnswerPopup';

export default function Meet({
  questions, currentQuestion, setCurrentQuestion, setAnswers, ident, answers
}) {
  const { width } = useWindowSize();
  const XGA = width >= 1020;
  const [selected, setSelected] = useState(0);
  const [tempAnswers, setTempAnswers] = useState([]);
  const [selectedAnswerCopy, setSelectedAnswerCopy] = useState("");
  const [selectedAnswerCopyTitle, setSelectedAnswerCopyTitle] = useState("");
  const [selectedAnswerCopySub, setSelectedAnswerCopySub] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [popup, setPopup] = useState(null);
  const [box, setBox] = useState(null);

  const [pinSources, setPinSources] = useState(
    questions?.content[currentQuestion]?.answers.map(
      () => questions.pin
    )
  );

  const handleAddPath = index => {
    let ident =
      questions.content[currentQuestion]?.answers[index].ident;
    let newTemp = tempAnswers;
    setSelectedAnswerCopy(
      questions.content[currentQuestion]?.answers[index].copy
    );
    setSelectedAnswerCopyTitle(
      questions.content[currentQuestion]?.answers[index].copyTitle
    );
    setSelectedAnswerCopySub(
      questions.content[currentQuestion]?.answers[index].copySub
    );
    if (newTemp.includes(ident)) return;
    newTemp.push(ident);

    const updatedSources = [...pinSources];

    updatedSources[index] = questions.clickedPin;
    setPinSources(updatedSources);

    setTempAnswers([...newTemp]);
    setSelected(1);
  };

  const openPopup = () => {
    if (popupOpen) {
      setPopupOpen(false);
      setTimeout(() => {
        setPopupOpen(true);
      }, 500);
    } else {
      setPopupOpen(true);
    }
  };

  const transitionPopup = useTransition(popupOpen, {
    from: {
      opacity: 0,
      scale: 0,
    },
    enter: {
      opacity: 1,
      width: "300px",
      scale: 1,
      top: window.innerWidth < 1020 ? "35%" : "55%",
      left: window.innerWidth < 1020 ? `calc(50% - 150px)` : `-130px`,
    },
    leave: {
      opacity: 0,
      scale: 0,
    },
    config: {
      duration: 200,
    },
  });

  const { styles, attributes } = usePopper(box, popup, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, width < 1020 ? -50 : 6],
          top: "10px",
        },
      },
      {
        name: "eventListeners",
        enabled: popupOpen,
      },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          // padding: { top: 100, left: 100 }
        },
      },
    ],
  });

  useEffect(() => {
    if (popupOpen) {
      const timeout = setTimeout(() => {
        setPopupOpen(false);
      }, 10000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [popupOpen]);

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  function hadnleButtonClick() {
    console.log("cliock")
    setAnswers(prev => [...prev, {
      question_ident: `q${currentQuestion}`,
      answer_ident: tempAnswers
    }])
    setCurrentQuestion(prev => prev < questions.content.length - 1 ? ++prev : prev)
  };

  return (
    <section className={`game-page meet-mechanics ${ident}`}>
      <PageHeader>{questions.content[currentQuestion].title ?? "glo+ Quiz"}</PageHeader>
      <div className="wrapper">
        {questions.content[currentQuestion].mobileTopImg &&
          <div className={`top-img-meet `} style={{ backgroundImage: `url(${questions.staticSideImg ? questions.staticSideImg : questions?.content[currentQuestion]?.img})` }}>
            <div
              className="desktop-background desktop-path"
              style={
                width < XGA
                  ? {}
                  : {
                    backgroundImage: `url(${questions?.content[currentQuestion]?.desktopImg})`,
                  }
              }
            >
              {!XGA && (
                <div className="image">
                  <img
                    src={questions.content[currentQuestion]?.mobileImg}
                    alt=""
                  />
                  {questions.content[currentQuestion]?.answers.map(
                    (element, index) => (
                      <React.Fragment key={index}>
                        <div
                          style={{
                            position: "absolute",
                            top: element.clipPathMobile.top,
                            left: element.clipPathMobile.left,
                          }}
                          className={`pin-svg ${tempAnswers.includes(element.ident) ? "selected" : ""
                            }`}
                          key={index}
                          onClick={() => {
                            handleAddPath(index);
                            openPopup();
                          }}
                        >
                          <img
                            className="pin-svg"
                            src={pinSources[index]}
                            alt="Pin"
                          />
                        </div>
                        <div
                          style={{
                            clipPath: element.clipPathMobile,
                            backgroundImage: `url(${questions.content[currentQuestion]?.mobileImg})`,
                          }}
                          className="inner-frag"
                        ></div>
                        {transitionPopup((style, item) =>
                          item ? (
                            <CorrectAnswerPopup
                              attributes={attributes}
                              setPopup={setPopup}
                              handleClosePopup={handleClosePopup}
                              styles={{
                                ...styles.popper,
                                ...style,
                              }}
                              number={tempAnswers.length}
                              totalNumber={questions.content[currentQuestion]?.answers.length}
                              copy={selectedAnswerCopy}
                            />
                          ) : (
                            ""
                          )
                        )}
                      </React.Fragment>
                    )
                  )}
                </div>
              )}
            </div>
          </div>}
        <div className="game">
          <div className="question">
            <h4>{questions.content[currentQuestion].question}</h4>
            <p>{questions.content[currentQuestion]?.instruction}</p>
          </div>
          <Button action={hadnleButtonClick} disabled={
            tempAnswers.length !==
            questions.content[currentQuestion]?.answers.length
          }>{questions.content[currentQuestion]?.buttonCopy ?? "Nächste Frage"}</Button>
        </div>
        <div className={`side-img-meet`} style={{ backgroundImage: `url(${questions.staticSideImg ? questions.staticSideImg : questions?.content[currentQuestion]?.img})` }}>
          <div
            className="desktop-background desktop-path"
            style={
              width < XGA
                ? {}
                : {
                  backgroundImage: `url(${questions?.content[currentQuestion]?.desktopImg})`,
                }
            }
          >
            {XGA &&
              questions?.content[currentQuestion]?.answers.map(
                (element, index) => (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        position: "absolute",
                        top: element.clipPathDesktop.top,
                        left: element.clipPathDesktop.left,
                      }}
                      className={`pin-svg ${tempAnswers.includes(element.ident) ? "selected" : ""
                        }`}
                      key={index}
                      onClick={() => {
                        handleAddPath(index);
                        openPopup();
                      }}
                    >
                      <img
                        className="pin-svg"
                        src={pinSources[index]}
                        alt="Pin"
                      />
                    </div>
                    {/* <div
                      style={{
                        clipPath: element.clipPathDesktop,
                        backgroundImage: `url(${gameQuestionsQuery.content[currentQuestion]?.desktopImg})`,
                      }}
                      className="inner-frag"
                    ></div> */}
                    {transitionPopup((style, item) =>
                      item ? (
                        <CorrectAnswerPopup
                          attributes={attributes}
                          setPopup={setPopup}
                          handleClosePopup={handleClosePopup}
                          styles={{
                            ...styles.popper,
                            ...style,
                          }}
                          number={tempAnswers.length}
                          totalNumber={questions.content[currentQuestion]?.answers.length}
                          copy={selectedAnswerCopy}
                        />
                      ) : (
                        ""
                      )
                    )}
                  </React.Fragment>
                )
              )}
          </div>
        </div>
      </div>
    </section>
  );
}
