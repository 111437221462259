import Tile0 from "./img/tile0.jpg"
import Tile1 from "./img/tile1.jpg"
import Tile2 from "./img/tile2.jpg"
import Tile3 from "./img/tile3.jpg"
import Tile4 from "./img/tile4.jpg"
import Tile5 from "./img/tile5.jpg"
import Tile6 from "./img/tile6.jpg"
import Tile7 from "./img/tile7.jpg"
import Tile8 from "./img/tile8.jpg"


export const questions = {
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/glo-heater/?utm_source=Glo%2B&utm_medium=Activity&utm_campaign=hyperair",
    copy: "Jetzt Air-gattern"
  },
  content: [
    {
      mechanics: "Puzzle",
      title: "HYPER PUZZLE FÜR DICH",
      question: "Du kannst immer zwei horizontal oder vertikal benachbarte Teile auswählen. Diese werden dann vertauscht. ",
      correctAnswers: [
        "b021d9ac-efac-4c56-a3f1-78bd38869f65",
        "915f274a-0a67-47c2-9d6e-993022b3c7ba",
        "2be68759-c1d2-4b65-9de3-7c77ecc468ab",
        "85684206-9f5f-40c7-a487-f1358254c118",
        "5e1bdacc-dbb8-41ea-a6ae-574caa4781e1",
        "1ec72174-2d46-47fc-8115-852bc3c5a88b",
        "bc3f3740-354b-4a6b-a62a-77397089fd71",
        "bf67cc22-09f1-4423-87c8-c91b39c59534",
        "27b33531-debd-4df9-8641-952d8f1a2f0a"
      ],
      answers: [
        {
          ident: "915f274a-0a67-47c2-9d6e-993022b3c7ba",
          img: Tile1
        },
        {
          ident: "27b33531-debd-4df9-8641-952d8f1a2f0a",
          img: Tile8
        },
        {
          ident: "b021d9ac-efac-4c56-a3f1-78bd38869f65",
          img: Tile0
        },
        {
          ident: "85684206-9f5f-40c7-a487-f1358254c118",
          img: Tile3
        },
        {
          ident: "bf67cc22-09f1-4423-87c8-c91b39c59534",
          img: Tile7
        },
        {
          ident: "5e1bdacc-dbb8-41ea-a6ae-574caa4781e1",
          img: Tile4
        },
        {
          ident: "2be68759-c1d2-4b65-9de3-7c77ecc468ab",
          img: Tile2
        },
        {
          ident: "1ec72174-2d46-47fc-8115-852bc3c5a88b",
          img: Tile5
        },
        {
          ident: "bc3f3740-354b-4a6b-a62a-77397089fd71",
          img: Tile6
        },
      ]
    },
  ]
}

