import Phone from "./img/phone.png"
import Book from "./img/book.png"
import Cup from "./img/cup.png"
import SideImg from "./img/side-img.jpg"

export const questions = {
  staticSideImg: SideImg,
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/glo-tabak-heater/glo-hyper-x2-air/?utm_source=GLO%2B&utm_medium=CTA&utm_campaign=X2+Air+Article",
    copy: "Jetzt Ordern"
  },
  content: [
    {
      mechanics: "AirWeight",
      buttonCopy: "Nächste",
      title: "LEICHT, LEICHTER, HYPER X2 AIR",
      mobileTopImg: true,
      question: "Wähle einen Gegenstand aus und schau, wie die Waage reagiert.",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "6ebb31fa-3dda-4c75-8eec-cdf54de1874f",
          img: Phone,
          weight: "200 g",
          content: {
            title: "Handy:",
            desc: "glo™ Hyper X2 Air ist 60% leichter"
          },
        },
        {
          ident: "011a6875-27ab-487d-8f5d-40741ae39723",
          img: Book,
          weight: "300 g",
          content: {
            title: "Buch:",
            desc: "glo™ Hyper X2 Air ist 75% leichter"
          },
        },
        {
          ident: "d9fb4ebf-750b-40a0-8916-79813f38f8b8",
          img: Cup,
          weight: "350 g",
          content: {
            title: "Kaffeetasse:",
            desc: "glo™ Hyper X2 Air ist 80% leichter"
          },
        },
      ]
    }
  ]
}