import React from 'react'
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import "./style.scss"

// default link wrapper
// if http on begining of link use a wrapper
// if action specified use button wrapper

// add posibility of using external classes

function Button({ children, href, action = null, submit = false, disabled = false, btnClass = "orange", title, state, target = "_blank", loading = false }) {

  function validateLink(link) {
    let temp = link

    if (action === null && temp === undefined && submit === false) {
      return
    }
    if (submit === true) {
      return "submit"
    }
    if (action !== null && link === undefined) {
      return "function"
    }
    if (temp.includes("http://") || temp.includes("https://")) {
      return "external"
    }
    return "internal"
  }

  const mode = validateLink(href)

  switch (mode) {
    case "internal":
      return (
        <Link to={DOMPurify.sanitize(href)} className={`button ${btnClass} ${disabled || loading ? "disabled" : ""}`} title={title} onClick={action} state={state}>
          {!loading ? children :
            <div className="loading">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='button-loader'>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.609863 8.20649C0.609863 7.40425 1.27647 6.75391 2.09878 6.75391L14.011 6.75391C14.8333 6.75391 15.4999 7.40425 15.4999 8.20649C15.4999 9.00873 14.8333 9.65908 14.011 9.65908L2.09878 9.65908C1.27647 9.65908 0.609863 9.00873 0.609863 8.20649Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.05498 0.48584C8.82857 0.48584 9.45569 1.17714 9.45569 2.0299L9.45569 14.3833C9.45569 15.2361 8.82857 15.9274 8.05498 15.9274C7.28139 15.9274 6.65427 15.2361 6.65427 14.3833L6.65427 2.0299C6.65427 1.17714 7.28139 0.48584 8.05498 0.48584Z" fill="white" />
              </svg>
              Wird geladen...
            </div>
          }
        </Link>
      )

    case "external":
      return (
        <a href={DOMPurify.sanitize(href)} target={target} rel="noopener noreferrer" className={`button ${btnClass} ${disabled ? "disabled" : ""}`}
          title={title} onClick={action}>
          {children}
        </a>
      )

    case "function":
      return (
        <button className={`button ${btnClass} ${disabled || loading ? "disabled" : ""}`} onClick={action} title={title} disabled={disabled || loading}>
          {!loading ? children :
            <div className="loading">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='button-loader'>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.609863 8.20649C0.609863 7.40425 1.27647 6.75391 2.09878 6.75391L14.011 6.75391C14.8333 6.75391 15.4999 7.40425 15.4999 8.20649C15.4999 9.00873 14.8333 9.65908 14.011 9.65908L2.09878 9.65908C1.27647 9.65908 0.609863 9.00873 0.609863 8.20649Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.05498 0.48584C8.82857 0.48584 9.45569 1.17714 9.45569 2.0299L9.45569 14.3833C9.45569 15.2361 8.82857 15.9274 8.05498 15.9274C7.28139 15.9274 6.65427 15.2361 6.65427 14.3833L6.65427 2.0299C6.65427 1.17714 7.28139 0.48584 8.05498 0.48584Z" fill="white" />
              </svg>
              Wird geladen...
            </div>
          }
        </button>
      )

    case "submit":
      return (
        <input type="submit" value={children} className={`button ${btnClass} ${disabled ? "disabled" : ""}`}
          disabled={disabled} title={title} />
      )

    default:
      return (
        <div className={`button ${btnClass} ${disabled ? "disabled" : ""}`} title={title}>
          {children}
        </div>
      )
  }
}

export default Button
