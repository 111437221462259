import { errorDefinitions } from "./definitions"
import _ from "lodash"
import NiceModal from '@ebay/nice-modal-react';

export function showErrorPopup(data, code) {
  if (data && code) data.code = code
  if (!data) {
    data = translateError()
  }
  NiceModal.show('error-modal', {...data})
}

export const translateError = (error) => {
  let errorDetails = {}

  if (error) {
    errorDetails = _.filter(errorDefinitions, { ident: error })[0] || _.filter(errorDefinitions, { ident: "generic" })[0]
  } else {
    errorDetails = _.filter(errorDefinitions, { ident: "generic" })[0]
  }

  return errorDetails
};
