export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  content: [
    {
      mechanics: "Quiz",
      type: "button",
      question: "Gimme points",
      img: "",
      correctAnswer: "a",
      answers: [
        {
          ident: "a",
          content: "Yes"
        },
      ]
    },
  ]
}