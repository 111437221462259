import React from 'react'
import GloPlusLogo from "../../../img/glo-plus.svg"
import Button from '../../components/Button'
import LogoutSVG from "./img/logout.svg"
import WowPage from "./img/404.svg"
import "./styles.scss"

export default function LoggedOut() {
  return (
    <section className='not-found-page'>
      <h1>HUCH, HIER WAR JA NOCH NIE JEMAND</h1>
      <div className="card">
        <img src={WowPage} alt="" />
        <div className="content">
          <h4>Du solltest besser umkehren</h4>
          <p>Nutze die Navigation, um zu den Seiten davor zurückzukehren oder den Link, um zur Startseite zu gelangen.</p>
          <Button href={"/"}>Zur Startseite</Button>
        </div>
      </div>
    </section>
  )
}
