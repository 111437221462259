import React, { useState } from "react";
import { ReactComponent as Egg } from "../../../img/egg.svg";
import { usePopper } from "react-popper";
import EasterPopup from "./EasterPopup";
import { animated, useTransition } from "@react-spring/web";
import "./style.scss";
import { fetchData, sendEvent, useSessionStorage } from "../../Helpers";
import { getUserDetails, useUserDispatch } from "../../UserContext";
import NiceModal from "@ebay/nice-modal-react";

export const finishEasterGame = (dispatch) => {
  fetchData({
    endpoint: "/activation/solution/create",
    payload: {
      activation: {
        ident: "quiz_19",
        payload: JSON.parse(
          window.sessionStorage.getItem("foundEasterEggs")
        ).map((e) => {
          return {
            question_ident: e,
            answer_ident: "found",
          };
        }),
      },
    },
    successCallback: (resObj) => {
      getUserDetails(dispatch);
      // notificationRef.current = true
      sendEvent(`quiz_19_FINISH`);
      if (resObj.data.payout !== 0) {
        NiceModal.show("correct-answer", {
          payout: resObj?.data?.payout,
          shouldNavigate: true,
        });
      }
      window.sessionStorage.setItem("easterFinished", true);
      window.sessionStorage.setItem("foundEasterEggs", []);
      window.dispatchEvent(new Event("storage"));
    },
  });
};

export const getEasterEggArray = () => {
  try {
    return JSON.parse(window.sessionStorage.getItem("foundEasterEggs")) ?? [];
  } catch (e) {
    return [];
  }
};

export default function EasterEgg({ uuid, style }) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [foundEasterEggs, setFoundEasterEggs] = useSessionStorage(
    "foundEasterEggs",
    []
  );
  const easterGameFinished =
    JSON.parse(window.sessionStorage.getItem("easterFinished")) ?? false;
  const [clicked, setClicked] = useState(false);
  const [box, setBox] = useState(null);
  const [popup, setPopup] = useState(null);

  const { styles, attributes } = usePopper(box, popup, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      {
        name: "eventListeners",
        enabled: popupOpen,
      },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: { top: 100, left: 10 },
        },
      },
    ],
  });

  const transitionPopup = useTransition(
    foundEasterEggs.includes(uuid) && popupOpen,
    {
      from: {
        opacity: 0,
        scale: 0,
      },
      enter: {
        opacity: 1,
        width: "275px",
        scale: 1,
      },
      leave: {
        opacity: 0,
        scale: 0,
      },
    }
  );

  const transitionEgg = useTransition(
    !foundEasterEggs.includes(uuid) || popupOpen,
    {
      enter: {
        opacity: 1,
      },
      leave: {
        opacity: 0,
      },
    }
  );

  const onEggClick = (e) => {
    if (clicked) {
      return;
    }
    e.preventDefault();
    setPopupOpen(true);
    setClicked(true);
    window.sessionStorage.setItem("hideEasterCounter", false);
    window.dispatchEvent(new Event("storage"));
    if (foundEasterEggs.length === 0) {
      setFoundEasterEggs([uuid]);
      sendEvent(`quiz_19_START`);
    } else {
      setFoundEasterEggs((prev) => [...prev, uuid]);
    }
  };

  if (easterGameFinished) {
    return <></>;
  }

  return (
    <div className="easter-egg" style={style}>
      {transitionEgg((style, item) =>
        item ? (
          <animated.div style={{ ...style }}>
            <Egg onClick={(e) => onEggClick(e)} ref={setBox} />
          </animated.div>
        ) : (
          ""
        )
      )}
      {transitionPopup((style, item) =>
        item ? (
          <EasterPopup
            popupOpen={popupOpen}
            attributes={attributes}
            setPopup={setPopup}
            setPopupOpen={setPopupOpen}
            uuid={uuid}
            styles={{ ...styles.popper, ...style }}
          />
        ) : (
          ""
        )
      )}
    </div>
  );
}
