import React from 'react'
import { useParams } from 'react-router-dom';
import TermsTemplate from './Template'

export default function TermsPage() {
  const { slug } = useParams();

  return (
    <>
      <TermsTemplate uuid={slug} />
    </>
  )
}
