import gold from "./img/gold.png"
import dark from "./img/dark.png"
import bright from "./img/bright.png"
import rounded from "./img/rounded.png"
import green from "./img/green.png"
import scarlet from "./img/scarlet.png"
import coral from "./img/coral.png"
import blue from "./img/blue.png"
import summer from "./img/summer.png"
import brilliant from "./img/brilliant.png"
import red from "./img/red.png"

export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  content: [
    {
      mechanics: "Quiz",
      type: "button-image",
      question: "Welche Packung gehört zu welchem Flavour? Welcher Flavour passt zu der jeweiligen Beschreibung? Wenn du alles richtig zuordnest, bekommst du 20 Punkte.",
      img: require("./img/1.jpg"),
      correctAnswer: "b",
      mobileQuestionImg: true,
      imgText: {
        text: "“Satter, intensiver Tabakgeschmack”",
        color: "white"
      },
      wrongAnswerInfo: {
        type: "basic",
      },
      // wrongAnswerInfo: {
      //   type: "extended",
      //   hint: {
      //     content: " Die richtige Anwort findest du in unserem letzten Instagram-Post.",
      //     linkContent: "Go to Instagram profile",
      //     url: "111"
      //   }
      // },
      answers: [
        {
          ident: "a",
          content: "Gold Tobacco",
          img: gold
        },
        {
          ident: "b",
          content: "Dark Tobacco",
          img: dark
        },
        {
          ident: "c",
          content: "Bright Tobacco",
          img: bright
        },
        {
          ident: "d",
          content: "Rounded Tobacco",
          img: rounded
        },
      ]
    },
    {
      mechanics: "Quiz",
      type: "button-image",
      question: "Welche Packung gehört zu welchem Flavour? Welcher Flavour passt zu der jeweiligen Beschreibung? Wenn du alles richtig zuordnest, bekommst du 20 Punkte.",
      img: require("./img/2.jpg"),
      mobileQuestionImg: true,
      imgText: {
        text: "“Fruchtig-feiner Marzipangeschmack aus süßem Mandelaroma und einer spritzigen Orangennote per Klick”",
        color: "black"
      },
      correctAnswer: "c",
      // wrongAnswerInfo: {
      //   type: "extended",
      //   hint: {
      //     content: " Die richtige Anwort findest du in unserem letzten Instagram-Post.",
      //     linkContent: "Go to Instagram profile",
      //     url: "111"
      //   }
      // },
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Green Click",
          img: green
        },
        {
          ident: "b",
          content: "Scarlet Click",
          img: scarlet
        },
        {
          ident: "d",
          content: "Blue Click",
          img: blue
        },
        {
          ident: "c",
          content: "Coral Click",
          img: coral
        },
      ]
    },
    {
      mechanics: "Quiz",
      type: "button-image",
      question: "Welche Packung gehört zu welchem Flavour? Welcher Flavour passt zu der jeweiligen Beschreibung? Wenn du alles richtig zuordnest, bekommst du 20 Punkte.",
      img: require("./img/3.jpg"),
      mobileQuestionImg: true,
      imgText: {
        text: "“Sanfter Blaubeer-Creme-Geschmack per Klick”",
        color: "white"
      },
      correctAnswer: "c",
      // wrongAnswerInfo: {
      //   type: "extended",
      //   hint: {
      //     content: " Die richtige Anwort findest du in unserem letzten Instagram-Post.",
      //     linkContent: "Go to Instagram profile",
      //     url: "111"
      //   }
      // },
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Blue Click",
          img: blue
        },
        {
          ident: "b",
          content: "Summer Yellow Click",
          img: summer
        },
        {
          ident: "c",
          content: "Brilliant Switch",
          img: brilliant
        },
        {
          ident: "d",
          content: "Coral Click",
          img: coral
        },
      ]
    },
    {
      mechanics: "Quiz",
      type: "button-image",
      question: "Welche Packung gehört zu welchem Flavour? Welcher Flavour passt zu der jeweiligen Beschreibung? Wenn du alles richtig zuordnest, bekommst du 20 Punkte.",
      img: require("./img/4.jpg"),
      mobileQuestionImg: true,
      imgText: {
        text: "“Süsslich frischer Menthol-Geschmack per Klick”",
        color: "white"
      },
      correctAnswer: "a",
      // wrongAnswerInfo: {
      //   type: "extended",
      //   hint: {
      //     content: " Die richtige Anwort findest du in unserem letzten Instagram-Post.",
      //     linkContent: "Go to Instagram profile",
      //     url: "111"
      //   }
      // },
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "c",
          content: "Red Switch",
          img: red
        },
        {
          ident: "b",
          content: "Blue Click",
          img: blue
        },
        {
          ident: "a",
          content: "Green Click",
          img: green
        },
        {
          ident: "d",
          content: "Summer Yellow Tobacco",
          img: summer
        },
      ]
    },
  ]
}