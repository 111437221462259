import React, { useState } from 'react'
import Button from '../../../../../components/Button'
import NiceModal from '@ebay/nice-modal-react';
import Dropdown from '../../../../../components/Dropdown';
import { getUserDetails, useUserDispatch } from '../../../../../UserContext';
import "../style.scss"
import { showErrorPopup, translateError } from '../../../../../components/ErrorHandling';
import { fetchData, getCookie } from '../../../../../Helpers';

export default function MuliSelect({ ident, question, content, setCurrentQuestion, currentQuestion, payout, index, disabled, disableAnswer, maxAnswers}) {
  const [answers, setAnswers] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useUserDispatch()

  const sendAnswer = async () => {
    // setLoading(true)
    // const response = await fetch(process.env.REACT_APP_API_URL + "/activation/solution/create", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " +  (getCookie("loyalty_sso_session_id") ?? JSON.parse(sessionStorage.getItem('token')).uuid),
    //   },
    //   body: JSON.stringify({
    //     "activation": {
    //       "ident": ident,
    //       "payload": [{
    //         question_ident: `q0`,
    //         answer_ident: answers
    //       }]
    //     }
    //   })
    // })
    // const answer = await response.json()
    // if (answer.status.success) {
    //   setLoading(false)
    //   getUserDetails(dispatch)
    //   NiceModal.show('glo-profile-points', {payout: answer.data.payout})
    // } else {
    //   showErrorPopup(translateError(answer.data.error), answer.meta.ts)
    // }

    fetchData({
      endpoint: "/activation/solution/create",
      payload: {
        "activation": {
          "ident": ident,
          "payload": [{
            question_ident: `q0`,
            answer_ident: answers
          }]
        }
      },
      successCallback: resObj => {
        getUserDetails(dispatch)
        NiceModal.show('correct-answer', { payout: resObj.data.payout })
        disableAnswer(index)
        setCurrentQuestion({index: -1, open: false })
      },
      setLoading: setLoading
    })
  }

  const handleMuliSelect = question => {
    let newArray = answers
    if (newArray.includes(question)) {
      const indexArray = newArray.indexOf(question)
      newArray.splice(indexArray, 1)
      setAnswers([...newArray])
      return
    }

    if(newArray.length >= maxAnswers) {
      return
    }

    newArray.push(question)
    setAnswers([...newArray.sort((a, b) => a.localeCompare(b))])
  }

  return (
    <Dropdown
      summary={
        <div className="summary">
          <div className="question-wrapper">
            <h4>{question}</h4>
            <p className="points">+{payout}P</p>
          </div>
        </div>
      }
      details={
        <div className="question-content">
          <div className={`questions ${answers.length !== 0 ? "chosen" : ""}`}>
            {content.map((question, index) => (
              <label key={index}>
                <input type="checkbox" checked={answers.includes(question)} onChange={() => handleMuliSelect(question)} />
                <p className='question'>{question}</p>
              </label>
            ))}
          </div>
          <Button action={sendAnswer} disabled={answers.length === 0} loading={loading}>Senden</Button>
        </div>}
      open={currentQuestion.index === index && currentQuestion.open}
      setOpen={() => { setCurrentQuestion(prev => ({ index: index, open: prev.index !== index ? true : !prev.open })) }}
      disabled={disabled}
    />
  )
}
