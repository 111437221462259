import React, { useEffect, useState } from 'react'
import { getUserDetails, useUserDispatch, useUserState } from '../../UserContext'
import Button from '../../components/Button'
import * as DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';
import { fetchData, getModuleValue } from '../../Helpers';
import _ from "lodash"
import PageHeader from '../../components/PageHeader';
import NiceModal from '@ebay/nice-modal-react';

export default function AwardForm() {
  const { ident } = useParams()
  const { userDetails } = useUserState()
  const dispatch = useUserDispatch()
  const [content, setContent] = useState("")
  const [mobilePhone, setMobilePhone] = useState("")
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const controller = new AbortController()
    fetchData({
      endpoint: "/prize/catalogue/describe",
      payload: {
        "catalogue": {
          "ident": DOMPurify.sanitize(ident)
        }
      },
      successCallback: resObj => {
        getUserDetails(dispatch);
        setContent(resObj.data);
      },
      errorCallback: () => {
        getUserDetails(dispatch);
      },
      controller: controller,
      setLoading: setLoading
    })

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (userDetails.mobilePhone) {
      let preparedPhoneNumber = userDetails.mobilePhone.includes("+49") ?
        userDetails.mobilePhone.replace("+49", "") : userDetails.mobilePhone.slice(0, 2) === "49" ?
          userDetails.mobilePhone.slice(2,) : userDetails.mobilePhone

      setMobilePhone(preparedPhoneNumber)
    }
  }, [userDetails.mobilePhone])

  function handlePhoneNumberChange(e) {
    const reg = /^[0-9\b]+$/;
    if (e.target.value === '' || reg.test(e.target.value)) {
      setMobilePhone(e.target.value)
    }
  }

  const validateForm = (e, callback, formClass = "") => {
    e.preventDefault()
    let wrongCounter = 0
    const inputs = document.querySelectorAll("form" + (formClass ? "." + formClass : "") + " input:required")

    if (inputs && inputs.length > 0) {
      for (let i = inputs.length - 1; i >= 0; i--) {
        const input = inputs[i];
        if (!validateInput(input)) {
          wrongCounter++
        }
      }

      if (wrongCounter === 0) {
        callback()
      }
    }
  }

  const validateInput = (i) => {
    const input = i
    const name = input.name
    const value = input.type === "checkbox" ? input.checked : input.value
    const regex = input.dataset?.regex || false
    const comment = input.dataset?.regexcomment || false
    const emptyComment = input.dataset?.emptycomment || false
    const lengthComment = input.dataset?.lengthcomment || false
    const minLength = input.dataset?.minlength || 0
    const checkSpaces = input.dataset?.checkspaces || false
    const checkSpacesComment = input.dataset?.checkspacescomment || false

    if (![name] || value === "" || value === false || value === "false") {
      let err = errors
      err[name] = "Pflichtfeld"
      setErrors({ ...err })
      // focus()
      return false
    } else if (checkSpaces && value.indexOf(" ") !== -1) {
      let err = errors
      err[name] = comment ? comment : "Falscher Wert"
      setErrors({ ...err })
      // focus()
      return false
    } else if (regex && !new RegExp(regex).test(value)) {
      let err = errors
      err[name] = comment ? comment : "Falscher Wert"
      setErrors({ ...err })
      // focus()
      return false
    } else if (value.length < 2 || value.length < minLength) {
      let err = errors
      err[name] = comment ? comment : "Falscher Wert"
      setErrors({ ...err })
      // focus()
      return false
    } else {
      let err = errors
      err[name] = false
      setErrors({ ...err })
      return true
    }
  }

  function handleSubmit(e) {
    validateForm(e, () => sendForm(), "award-form")
  }

  function sendForm() {
    fetchData({
      endpoint: "/prize/redeem",
      payload: {
        "consumer": {
          "firstName": userDetails.firstName,
          "lastName": userDetails.lastName,
          "emailAddress": userDetails.emailAddress,
          "mobilePhone": mobilePhone
        },
        "catalogue": {
          "ident": ident
        }
      },
      successCallback: resObj => {
        getUserDetails(dispatch);
        setContent(resObj.data);
        NiceModal.show('award-form-sucess')
      },
      errorCallback: () => {
        getUserDetails(dispatch);
      },
      setLoading: setLoading
    })
  }

  return (
    <section className="redeem-reward award-form">
      <PageHeader>{content?.content?.title}</PageHeader>
      <div className="desktop-wrapper">
        <div className="image">
          <img src={getModuleValue(content?.content?.modules, "hero_image", "file.url")} alt="" />
        </div>
        <div className="form-wrapper">
          <div className="bar">Kontaktinfos</div>
          <h3>Bitte gib deine Daten an, damit wir dich benachrichtigen können</h3>
          <form method='POST' className='award-form'>
            <label htmlFor="firstName">
              <p>Vorname</p>
              <input type="text" name='firstName' value={userDetails.firstName ?? ""} disabled id='firstName' required />
            </label>
            <label htmlFor="lastName">
              <p>Nachname</p>
              <input type="text" name='lastName' value={userDetails.lastName ?? ""} disabled id='lastName' required />
            </label>
            <label htmlFor="emailAddress">
              <p>Email</p>
              <input type="text" name='emailAddress' value={userDetails.emailAddress ?? ""} disabled id='emailAddress' required />
            </label>
            <label htmlFor="mobilePhone">
              <p>Handy</p>
              <input name="mobilePhone" type="text" maxLength={10} placeholder='z.B. 501 123 456' value={mobilePhone} id='mobilePhone' onChange={e => handlePhoneNumberChange(e)} required />
              {errors.mobilePhone && <p className='error'>{errors.mobilePhone}</p>}
            </label>
            <Button action={e => handleSubmit(e)} disabled={loading}>Senden</Button>
          </form>
        </div>
      </div>
    </section>
  )
}
