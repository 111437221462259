import SideImg from "./img/side-img.jpg"
import Black from "./img/black.png"
import Blue from "./img/blue.png"
import Gold from "./img/gold.png"
import Pink from "./img/pink.png"
import Purple from "./img/purple.png"
import Space from "./img/space.png"
import Teal from "./img/teal.png"


export const questions = {
  staticSideImg: SideImg,
  customFinishModal: "air-finish-modal",
  content: [
    {
      mechanics: "MeetAir",
      buttonCopy: "Nächste",
      title: "HYPER X2 AIR UND DU",
      mobileTopImg: true,
      type: "button",
      question: "",
      correctAnswer: ["f", "e", "a", "b", "d", "g", "c"],
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Standard-Taste",
        },
        {
          ident: "b",
          content: "Boost-Taste",
        },
        {
          ident: "c",
          content: "USB-C Ladeanschluss",
        },
        {
          ident: "d",
          content: "LED-Anzeige",
        },
        {
          ident: "e",
          content: "Heizkammer",
        },
        {
          ident: "f",
          content: "Schiebeverschluss",
        },
        {
          ident: "g",
          content: "Reinigungsklappe",
        },
      ]
    },
    {
      mechanics: "MeetAir",
      buttonCopy: "Nächste",
      title: "HYPER X2 AIR UND DU",
      mobileTopImg: true,
      type: "button",
      question: "WÄHLE DEIN LIEBLLINGSFARBE AUS.",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          img: Gold,
          content: "Rosey Gold",
        },
        {
          ident: "b",
          img: Black,
          content: "Moonless Black",
        },
        {
          ident: "c",
          img: Space,
          content: "Space Navy",
        },
        {
          ident: "d",
          img: Teal,
          content: "Light Teal",
        },
        {
          ident: "e",
          img: Blue,
          content: "Ocean Blue",
        },
        {
          ident: "f",
          img: Pink,
          content: "Velvet Pink",
        },
        {
          ident: "g",
          img: Purple,
          content: "Crisp Purple",
        },
      ]
    },
  ]
}