

export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  onSuccessCustomLink: {
    // outsideLink: "https://www.discoverglo.com/de/de/valentines-day?utm_source=Glo%2B&amp;utm_medium=Article&amp;utm_campaign=vday",
    copy: "Danke"
  },
  customFinishModal: "correct-find",
  content: [
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      title: "glo™ Hyper Pro QUIZ",
      mobileTopImg: true,
      type: "button",
      question: "Wodurch wird der Modus beim neuen glo™ Hyper Pro gewählt?",
      img: require("./img/1.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "TasteSelect™ Regler"
        },
        {
          ident: "b",
          content: "ModusSelect™ Regler"
        },
        {
          ident: "c",
          content: "IntenseSelect™ Regler"
        },
        {
          ident: "d",
          content: "HeatSelect™ Regler"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ Hyper Pro QUIZ",
      mobileTopImg: true,
      question: "Wie heißt der intensivere Modus vom glo™ Hyper Pro?",
      img: require("./img/1.jpg"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Blast-Modus"
        },
        {
          ident: "b",
          content: "Glo-Modus"
        },
        {
          ident: "c",
          content: "Boost-Modus"
        },
        {
          ident: "d",
          content: "Smoke-Modus"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ Hyper Pro QUIZ",
      mobileTopImg: true,
      question: "Wie heißt das neue Display?",
      img: require("./img/1.jpg"),
      correctAnswer: "d",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "PerfectView™ Display"
        },
        {
          ident: "b",
          content: "SimpleView™ Display"
        },
        {
          ident: "c",
          content: "SpecialView™ Display "
        },
        {
          ident: "d",
          content: "EasyView™ Display"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ Hyper Pro QUIZ",
      mobileTopImg: true,
      question: "Wie lange dauert der neue Standard-Modus?",
      img: require("./img/1.jpg"),
      correctAnswer: "d",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Genauso wie früher beim glo™ hyper X2"
        },
        {
          ident: "b",
          content: "15 Sekunden"
        },
        {
          ident: "c",
          content: "10 Minuten"
        },
        {
          ident: "d",
          content: "Bis zu 30 Sekunden länger als der Standardmodus des glo™ hyper X2."
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ Hyper Pro QUIZ",
      mobileTopImg: true,
      question: "Wie heisst der neue glo™ Heater?",
      img: require("./img/1.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "glo™ Hyper Pro "
        },
        {
          ident: "b",
          content: "glo™ 24"
        },
        {
          ident: "c",
          content: "glo™ Super Pro"
        },
        {
          ident: "d",
          content: "glo™ Pro24"
        },
      ]
    },
  ]
}