import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader'
import Button from '../../../../components/Button'
import Img from "./img/img.jpg"
import NiceModal from '@ebay/nice-modal-react';
import "./style.scss"
import Dropdown from '../../../../components/Dropdown';
import MuliSelect from "./InputTypes/MuliSelect"
import { showErrorPopup, translateError } from '../../../../components/ErrorHandling';
import { fetchData, getCookie } from '../../../../Helpers';
import SingleSelect from './InputTypes/Select';

export default function GloProfile() {
  const [currentQuestion, setCurrentQuestion] = useState({ index: -1, open: false })
  const [mainMap, setMainMap] = useState([])

  useEffect(() => {
    const requireData = async (ident) => {
      let data = await require(`./Idents/${ident}.json`)
      return data
    }

    const controller = new AbortController()
    fetchData({
      endpoint: "/activation/list",
      payload: {
        "limit": 100,
        "offset": 0
      },
      successCallback: async resObj => {
        const list = resObj.data.activations.filter(e => e.ident.includes("complete_profile")).reverse().sort((a, b) => a.currentNumberOfSolutions - b.currentNumberOfSolutions)
        const map = []
        for (const activity of list) {
          map.push({ ...await requireData(activity.ident), payout: activity.payout, ident: activity.ident, disabled: activity.currentNumberOfSolutions !== 0 })
        }
        setMainMap(map)
      },
      controller: controller
    })

    return () => {
      controller.abort()
    }
  }, [])


  const disableAnswer = index => {
    let newArray = [...mainMap]
    newArray[index].disabled = true
    setMainMap([...newArray])
  }

  return (
    <section className='glo-profile-activity'>
      <PageHeader>Erzähl uns mehr über dich</PageHeader>
      <div className="wrapper">
        <div className="image">
          <img src={Img} alt="" />
        </div>
        <div className="questions">
          {mainMap.map((element, index) => (
            // ident, question, content, setCurrentQuestion, currentQuestion, payout
            <React.Fragment key={index}>
              {element.type === "multi-select" && <MuliSelect {...element} setCurrentQuestion={setCurrentQuestion} currentQuestion={currentQuestion} disableAnswer={disableAnswer} index={index} />}
              {element.type === "select" && <SingleSelect {...element} setCurrentQuestion={setCurrentQuestion} currentQuestion={currentQuestion} disableAnswer={disableAnswer} index={index} />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}
