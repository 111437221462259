import React, { useEffect, useRef, useState } from 'react'
import PageHeader from '../../../../components/PageHeader'
import Button from '../../../../components/Button'
import AirSchema from "../Idents/quiz_9/img/air-schema.svg"
import Dropdown from "../../../../../img/dropdown-oragne-wide.svg"
import SwiperNav from "../../../../../img/nav-swiper.svg"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import _ from 'lodash';

export default function MeetAir({ questions, currentQuestion, setCurrentQuestion, setAnswers, ident, answers }) {
  const [showSelector, setShowSelector] = useState({ index: -1, show: false })
  const [currentSlide, setCurrentSlide] = useState(0)
  const buttonNext = useRef(null)
  const buttonPrev = useRef(null)

  function hadnleButtonClick() {
    if(currentQuestion === 1) {
      let ans = [...answers]
      ans[7] = {
        question_ident: "q7",
        answer_ident: currentSlide
      }
      setAnswers(ans)
    }
    setCurrentQuestion(prev => prev < questions.content.length - 1 ? ++prev : prev)
  }

  return (
    <section className={`game-page meet-air ${ident}`}>
      <PageHeader>{questions.content[currentQuestion].title ?? "glo+ Quiz"}</PageHeader>
      <div className="wrapper">
        {questions.content[currentQuestion].mobileTopImg && <div className={`mobile-top-img `} style={{ backgroundImage: `url(${questions.staticSideImg ? questions.staticSideImg : questions?.content[currentQuestion]?.img})` }}>
        </div>}
        <div className="game">
          <div className="progress">
            {Array(questions.content.length).fill(null).map((_, index) => (
              <div className={`number ${currentQuestion >= index ? "active" : ""}`} key={index}>
                <div><p>{++index}</p></div>
              </div>
            ))}
          </div>
          {currentQuestion === 0 ? <div className="answers-background">
            <img src={AirSchema} alt="air-schema" />
            <div className="element-list">
              {questions.content[0].answers.map((e, index) => (
                <div className="element" key={index}>
                  <button onClick={() => {
                    setShowSelector(prev => ({ index: index, show: prev.index === index ? !prev.show : true }))
                  }} className='dropdown-button'>
                    <div className={`air-dropdown ${showSelector.index !== index && showSelector.show ? "gray" : showSelector.show ? "active" : ""}`}>
                      <div className="number">{index + 1}</div>
                      <div className="answer">
                        <p>{answers[`q${index}`] ? questions.content[0].answers.filter(e => e.ident === answers[`q${index}`])[0].content : "Wählen Sie aus der Liste"}</p>
                        <img src={Dropdown} alt="" />
                      </div>
                    </div>
                  </button>
                  <div className={`selector ${index === showSelector.index && showSelector.show ? "visible" : ""}`}>
                    {questions.content[0].answers.map((e, index_) => (
                      <button key={index_} onClick={() => {
                        let prev = { ...answers }
                        prev[`q${index}`] = e.ident
                        console.log(e)
                        setAnswers(prev)
                        setShowSelector(prev => ({ ...prev, show: false }))
                      }}>
                        <div className="content">{e.content}</div>
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div> :
            <div className="answers-background second">
              <div className="question">
                <h4>{questions.content[currentQuestion].question}</h4>
              </div>
              <div className="swiper-air-wrapper">
                <Swiper className="swiper-meet-air"
                  slidesPerView='auto'
                  centeredSlides={true}
                  onSlideChange={index => {
                    setCurrentSlide(index.snapIndex);
                  }}
                  initialSlide={currentSlide}
                  spaceBetween={16}
                  observer={true}
                  observeParents={true}
                  navigation={{
                    prevEl: buttonPrev.current,
                    nextEl: buttonNext.current,
                  }}
                  onBeforeInit={swiper => {
                    let prev = { ...answers }
                    let newAnswers = []
                    for (const [key, value] of Object.entries(prev)) {
                      newAnswers.push({
                        question_ident: key,
                        answer_ident: value
                      })
                    }
                    
                    setAnswers(newAnswers)

                    swiper.params.navigation.prevEl = buttonPrev.current;
                    swiper.params.navigation.nextEl = buttonNext.current;
                  }}
                >
                  {questions.content[1].answers.map((e, index) => (
                    <SwiperSlide key={index}>
                      <div className="inner-wrapper">
                        <img src={e.img} alt="" />
                        <p>{e.content}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="button-wrapper">
                  <button ref={buttonPrev}>
                    <div className="inner">
                      <img src={SwiperNav} alt="" />
                    </div>
                  </button>
                  <button ref={buttonNext}>
                    <div className="inner">
                      <img src={SwiperNav} alt="" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          }
          <Button action={hadnleButtonClick} disabled={currentQuestion === 0 ? !_.isEqual(Object.values(answers), questions.content[0].correctAnswer) : false}>{questions.content[currentQuestion]?.buttonCopy ?? "Nächste Frage"}</Button>
        </div>
        {console.log(Object.values(answers))}
        {console.log(questions.content[0].correctAnswer)}
        <div className={`side-img`} style={{ backgroundImage: `url(${questions.staticSideImg ? questions.staticSideImg : questions?.content[currentQuestion]?.img})` }}></div>
      </div>
    </section>
  )
}
