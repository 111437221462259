import NiceModal from '@ebay/nice-modal-react';
import CollectReward from "./Modals/CollectReward"
import EmailShare from './Modals/EmailShare';
import EmailCopy from './Modals/EmailCopy';
import CloseAccount from './Modals/CloseAccount';
// import EditAccount from './Modals/EditAccount';
import WrongAnswer from './Modals/WrongAnswer';
import ErrorModal from './Modals/ErrorModal';
import CorrectAnswer from './Modals/CorrectAnswer';
import CorrectFind from "./Modals/CorrectFind"
import WelcomeModal from './Modals/WelcomeModal';
import NewFlavorModal from "./Modals/NewFlavor"
import CorrectAirAnswer from './Modals/CorrectAirAnswer';
import CorrectQuizAnswer from './Modals/CorrectQuizAnswer';
import AwardFormSucess from "./Modals/AwardFormSucess"
import ScratchModal from './Modals/ScratchModal';
import CorrectMatch from './Modals/CorrectMatch';
import CalendarModal from './Modals/CalendarModal';

NiceModal.register('collect-reward', CollectReward);
NiceModal.register('correct-answer', CorrectAnswer);
NiceModal.register('correct-match', CorrectMatch);
NiceModal.register('correct-find', CorrectFind);
NiceModal.register('air-finish-modal', CorrectAirAnswer);
NiceModal.register('quiz-finish-modal', CorrectQuizAnswer);
NiceModal.register('wrong-answer', WrongAnswer);
NiceModal.register('email-share', EmailShare);
NiceModal.register('email-copy', EmailCopy);
NiceModal.register('close-account-modal', CloseAccount);
// NiceModal.register('edit-account-modal', EditAccount);
NiceModal.register('error-modal', ErrorModal);
NiceModal.register('welcome-modal', WelcomeModal);
// NiceModal.register('new-flavor', NewFlavorModal);
NiceModal.register('scratch-modal', ScratchModal);
NiceModal.register('calendar-modal', CalendarModal);
NiceModal.register('award-form-sucess', AwardFormSucess);