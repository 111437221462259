import FirstMobile from "./img/first_mobile.jpg"
import FirstDesktop from "./img/first_desktop.jpg"
import SecondMobile from "./img/second_mobile.jpg"
import SecondDesktop from "./img/second_desktop.jpg"

export const questions = {
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/new-years-offer?utm_source=Glo%2B&utm_medium=Activity&utm_campaign=nyr_promo",
    copy: "Jetzt zum Shop!"
  },
  customFinishModal: "correct-find",
  content: [
    {
      mechanics: "Find",
      question: "Starte das neue Jahr mit einem Lächeln und „entdecke” den glo™ Heater für dich!",
      imgMobile: FirstMobile,
      imgDesktop: FirstDesktop,
      answers: [
        {
          ident: "f2b909c7-3cbc-4596-a773-222522c956a1",
          clipPathMobile: "19 26, 38 26, 38 53, 19 53",
          clipPathDesktop: "19 25, 28 25, 28 54, 19 54"
        },
      ]
    },
    {
      mechanics: "Find",
      question: "Starte das neue Jahr mit einem Lächeln und „entdecke” den glo™ Heater für dich!",
      imgMobile: SecondMobile,
      imgDesktop: SecondDesktop,
      answers: [
        {
          ident: "c49c275a-e087-4eb9-8ab9-2c9ba938f309",
          clipPathMobile: "2 67, 21 67, 21 88, 2 88",
          clipPathDesktop: "3 61, 20 61, 20 88, 3 88",
        },
      ]
    },
  ]
}