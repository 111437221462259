import React from "react";
import "./style.scss"
import { NavLink } from 'react-router-dom'
import { ReactComponent as GloIcon } from "../../../img/glo-plus-dark.svg"
import { ReactComponent as ActivitiesIcon } from "./img/activities.svg"
import { ReactComponent as MarketIcon } from "./img/market.svg"
import { ReactComponent as InviteIcon } from "./img/invite.svg"
import { ReactComponent as ZonePlusIcon } from "./img/zone-plus-icon.svg"
import { ReactComponent as SubscriptionIcon } from "./img/subscription.svg"
import Button from "../Button";

export default function Nav() {
  return (
    <nav className="main-nav">
      <div className="nav-container">
        <ul>
          <li>
            <NavLink to="/" exact={"true"}>
              <div className="menu-icon">
                <GloIcon width="30px" className="glo" />
              </div>
              <p>Übersicht</p>
            </NavLink>
            {/* <div className="sub-nav">
              <Button href="/co-nowego" btnClass='white menu-small' title="Co nowego?">Co nowego?</Button>
              <Button href="/znajdz-sklep" btnClass='white menu-small' title="Znajdź sklep">Znajdź sklep</Button>
              <Button href="/poznaj-glo" btnClass='white menu-small' title="Poznaj glo™">Poznaj glo™</Button>
            </div> */}
          </li>
          <li>
            <NavLink to="/aufgaben" exact={"true"}>
              <div className="menu-icon">
                <ActivitiesIcon />
                <ActivitiesIcon />
              </div>
              <p>Aufgaben</p>
            </NavLink>
            {/* <div className="sub-nav">
              <Button href="/aktywnosci" btnClass='white menu-small' title="Aktywności">
                Aktualne
              </Button>
              <Button href="/aktywnosci/tryb-glo-ar" btnClass='white menu-small' title="Tryb glo™ AR">Tryb glo™ AR</Button>
            </div> */}
          </li>
          <li>
            <NavLink to="/pramienshop" exact={"true"}>
              {/* <NavLink to="/nagrody" exact={ "true" }> */}
              <div className="menu-icon">
                <MarketIcon />
                <MarketIcon />
              </div>
              <p>Prämienshop</p>
            </NavLink>
            {/* <div className="sub-nav">
              <Button href="/nagrody" btnClass='white menu-small' title="Nagrody wyróżnione">
                                Nagrody wyróżnione
                            </Button>
              <Button href="/nagrody/katalog" btnClass='white menu-small' title="Katalog">
                Katalog
              </Button>
              <Button href="/nagrody/letnie-brzmienia" btnClass='white menu-small' title="Katalog" action={() => window.sessionStorage.setItem("selectedCity", JSON.stringify(""))}>
                Letnie Brzmienia
              </Button>
              <Button href="/nagrody/odebrane" btnClass='white menu-small' title="Odebrane">
                Odebrane
              </Button>
            </div> */}
          </li>
          <li >
            <NavLink to="/einladen" exact={"true"}>
              <div className="menu-icon">
                <InviteIcon />
                <InviteIcon />
              </div>
              <p>Einladen</p>
            </NavLink>
          </li>
          <li >
            <NavLink to="/zone-plus" exact={"true"}>
              <div className="menu-icon">
                <ZonePlusIcon />
                <ZonePlusIcon />
              </div>
              <p>Zone +</p>
            </NavLink>
          </li>
          {/* <li className="menu">
            <NavLink to="/subskrybcje" exact={"true"}>
              <div className="menu-icon">
                <SubscriptionIcon height={'19px'} />
              </div>
              <p>Subskrypcje</p>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </nav>
  )
}