import React, { useState } from 'react'
import Button from '../../../../../components/Button'
import NiceModal from '@ebay/nice-modal-react';
import Dropdown from '../../../../../components/Dropdown';
import { getUserDetails, useUserDispatch } from '../../../../../UserContext';
import "../style.scss"
import { showErrorPopup, translateError } from '../../../../../components/ErrorHandling';
import { fetchData, getCookie, sendEvent } from '../../../../../Helpers';

export default function SingleSelect({ident, question, content, setCurrentQuestion, currentQuestion, payout, index, disabled, disableAnswer }) {
  const [answer, setAnswer] = useState(-1)
  const [loading, setLoading] = useState(false)
  const dispatch = useUserDispatch()

  const sendAnswer = async () => {
    fetchData({
      endpoint: "/activation/solution/create",
      payload: {
        "activation": {
          "ident": ident,
          "payload": [{
            question_ident: `q0`,
            answer_ident: answer
          }]
        }
      },
      successCallback: resObj => {
        getUserDetails(dispatch)
        NiceModal.show('correct-answer', { payout: resObj.data.payout })
        disableAnswer(index)
        setCurrentQuestion({index: -1, open: false })
        sendEvent(`${ident}_FINISH`)
      },
      setLoading: setLoading
    })
  }

  return (
    <Dropdown
      summary={
        <div className="summary">
          <div className="question-wrapper">
            <h4>{question}</h4>
            <p className="points">+{payout}P</p>
          </div>
        </div>
      }
      details={
        <div className="question-content">
          <h2>{question}</h2>
          <div className={`questions ${answer !== -1 ? "chosen" : ""}`}>
            {content.map((question, index) => (
              <label key={index}>
                <input type="checkbox" checked={index === answer ? true : false} onChange={() => setAnswer(index)} />
                <p className='question'>{question}</p>
              </label>
            ))}
          </div>
          <Button action={sendAnswer} disabled={answer === -1} loading={loading}>Senden</Button>
        </div>}
      open={currentQuestion.index === index && currentQuestion.open}
      setOpen={() => { setCurrentQuestion(prev => ({ index: index, open: prev.index !== index ? true : !prev.open })); sendEvent(`${ident}_START`) }}
      disabled={disabled}
    />
  )
}
