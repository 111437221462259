import React from "react";
import Button from "../Button";
import { ReactComponent as Egg } from "../../../img/egg.svg";
import { ReactComponent as Close } from "../../../img/close.svg";
import { animated } from "@react-spring/web";
import { useUserDispatch } from "../../UserContext";
import { finishEasterGame, getEasterEggArray } from ".";

export default function EasterPopup({
  popupOpen,
  setPopupOpen,
  attributes,
  setPopup,
  styles,
  cancel = false,
}) {
  const foundEasterEggs = getEasterEggArray();
  const easterGameFinished =
    JSON.parse(window.sessionStorage.getItem("easterFinished")) ?? false;
  const dispatch = useUserDispatch();
  const lastEgg = foundEasterEggs.length === 5;

  const closePopup = () => {
    setPopupOpen(false);
  };

  const cancelGame = () => {
    window.sessionStorage.setItem("hideEasterCounter", true);
    window.dispatchEvent(new Event("storage"));
  };

  if (easterGameFinished) {
    return <></>;
  }

  return (
    <animated.div
      className="easter-popup"
      ref={setPopup}
      style={{ ...styles }}
      {...attributes.popper}
    >
      <div className="inner">
        <Egg />
        <div className="top">
          {!cancel && <p>{foundEasterEggs.length}/5</p>}
          <h4>{cancel && "Willst du das Spiel beenden? "}</h4>
          <button
            onClick={(e) => {
              closePopup(e);
            }}
          >
            <Close className="close" />
          </button>
        </div>
        <div className="content-wrapper">
          <h4>
            {!cancel &&
              (lastEgg
                ? "Geschafft! Du hast alle 5 Ostereier gefunden!"
                : "Glückwunsch! Du hast ein Osterei gefunden!")}
          </h4>
          {!lastEgg && (
            <p>
              {cancel
                ? "Du erhältst 25 Punkte, wenn du alle 5 Ostereier findest"
                : "Finde alle 5, um 25 Punkte zu gewinnen"}
            </p>
          )}
          {/* <p>{cancel ? "Du erhlältst einen Combo-gewinn, wenn du all 7 Ostereier findest" : lastEgg ? "As a reward you get: XXXX" : "Finde alle 7 für einen Combo-Gewinn."}</p> */}
          <div className="buttons-wrapper">
            {cancel ? (
              <>
                <Button
                  btnClass="transparent"
                  action={(e) => {
                    cancelGame();
                    closePopup(e);
                  }}
                >
                  Beenden
                </Button>
                <Button
                  btnClass="easter-popup-button"
                  action={(e) => {
                    closePopup(e);
                  }}
                >
                  Ich suche weiter
                </Button>
              </>
            ) : lastEgg ? (
              <Button
                btnClass="easter-popup-button"
                action={(e) => {
                  finishEasterGame(dispatch);
                  closePopup(e);
                }}
              >
                Punkte sammeln
              </Button>
            ) : (
              <Button
                btnClass="easter-popup-button"
                action={(e) => {
                  closePopup(e);
                }}
              >
                WEITER SUCHEN
              </Button>
            )}
          </div>
        </div>
      </div>
    </animated.div>
  );
}
