import React from 'react'
import Modal from "../index"
import PointsIcon from "./img/points_icon.svg"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import { useNavigate } from 'react-router-dom';
import packImg from "../../../pages/ActivitiesPage/Activities/Idents/quiz_10/img/rounded.png"
import packImg2 from "../../../pages/ActivitiesPage/Activities/Idents/quiz_10/img/tabaco.png"

export default NiceModal.create(() => {
    const modal = useModal();
    const navigate = useNavigate();

    console.log(modal.args)

    return (
        <Modal isOpen={modal.visible} onClose={() => { modal.hide(); modal.args?.nextQuestion() }}
            onAfterClose={() => modal.remove()} barIcon={PointsIcon}
            innerClass={"scratch-modal"}>
            <img alt='device' src={modal.args.img} />
            <div className='wrapper'>
                <h2>{modal.args.highlited}</h2>
                <h3 className="upper">{modal.args.title}</h3>
                <p className="sub">{modal.args.desc}</p>
                {modal.args.more && <p className='more'>{modal.args.more}</p>}
                <div className='lucky-strike-button'>
                    <Button action={() => { modal.hide(); modal.args?.nextQuestion() }}>
                        Nächste
                    </Button>
                </div>
            </div>
        </Modal>
    );
});
