import Mobile from "./img/mobile.png";
import Desktop from "./img/desktop.png";
import pin from "./img/pin.svg"
import ok from "./img/ok-meet.svg"

export const gameData = {

  pin: pin,
  clickedPin: ok,
  staticSideImg: Desktop,
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/glo-heater/glo-hyper-pro?utm_source=Glo%2B&utm_medium=Activity&utm_campaign=hyperpro#obsidian-black",
    copy: "Zum onlineshop"
  },
  content: [
    {
      mechanics: "Meet",
      title: "Die neue Funktionen des glo™ Hyper Pro",
      question: "ENTDECKE GLO™ GENUSS AUF DEM NÄCHSTEN LEVEL",
      instruction: "Klick auf die Punkte, um neue Funktionen zu entdecken.",
      desktopImg: Desktop,
      mobileImg: Mobile,
      mobileTopImg: true,
      correctAnswer: "",
      buttonCopy: "Hyper Pro kaufen",
      answers: [
        {
          "ident": "3a47f1be-b2dd-4a44-afd8-fc5810288faa",
          "clipPathMobile": { left: "58%", top: "30%" },
          "clipPathDesktop": { left: "59%", top: "30%" },
          "copy": `<strong>Das innovative EasyView™ Display</strong> zeigt dir den Akkustand, die Aufheizzeit, die verbleibende Länge der Nutzung sowie den gewählten Modus an.`,
        },
        {
          "ident": "89618cd4-07a6-4544-be9f-5ed5f38f6261",
          "clipPathMobile": { left: "28%", top: "14%" },
          "clipPathDesktop": { left: "28%", top: "14%" },
          "copy": `<strong>Die HeatBoost™ Technology</strong> sorgt für einen noch intensiveren Geschmack**. So erwartet dich im Boost-Modus ein authentisches und volles Geschmackserlebnis.`,
        },
        {
          "ident": "bbce7752-bf44-407b-8c83-e588efd69919",
          "clipPathMobile": { left: "41%", top: "21%" },
          "clipPathDesktop": { left: "41%", top: "21%" },
          "copy": `<strong>Der längere Standard-Modus</strong> bietet dir 30 Sekunden mehr gleichbleibenden Genuss**.`,
        },
        {
          "ident": "3a47f1be-b2dd-4a44-afd8-fc5810288faa2",
          "clipPathMobile": { left: "56%", top: "55%" },
          "clipPathDesktop": { left: "56%", top: "55%" },
          "copy": `<strong>Mit dem TasteSelect™ Button</strong> kannst du schnell und einfach zwischen Standard- und Boost-Modus wechseln.`,
        },
      ]
    },
  ],
};
