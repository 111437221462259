import React from 'react'
import ReactModal from 'react-modal'
import "./style.scss"
import "./Modals/style.scss"

export default function Modal({ isOpen, onClose, onAfterClose, barIcon, children, innerClass="", disableClose=false }) {
  return (
    <ReactModal isOpen={isOpen} onClose={onClose}
      onAfterClose={onAfterClose} overlayClassName={"modal-overlay"}
      className={"modal-inner"} shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true}
      preventScroll={true} appElement={document.getElementById("root")}>
      <div className="upper-bar">
        <img src={barIcon} alt="" />
        {!disableClose && <button onClick={onClose}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.95147 11.6485C10.4201 12.1172 11.1799 12.1172 11.6485 11.6485C12.1172 11.1799 12.1172 10.4201 11.6485 9.95147L7.69706 5.99999L11.6485 2.04853C12.1171 1.5799 12.1171 0.820101 11.6485 0.351472C11.1799 -0.117156 10.4201 -0.117156 9.95147 0.351472L6 4.30294L2.04853 0.351472C1.57991 -0.117157 0.820108 -0.117157 0.35148 0.351472C-0.11715 0.8201 -0.11715 1.5799 0.35148 2.04853L4.30295 5.99999L0.351472 9.95147C-0.117157 10.4201 -0.117157 11.1799 0.351472 11.6485C0.8201 12.1172 1.5799 12.1172 2.04853 11.6485L6 7.69705L9.95147 11.6485Z" fill="white" />
          </svg>
        </button>}
      </div>
      <div className={`inner ${innerClass}`}>
        {children}
      </div>
    </ReactModal>
  )
}