import Tile0 from "./img/tile0.jpg"
import Tile1 from "./img/tile1.jpg"
import Tile2 from "./img/tile2.jpg"
import Tile3 from "./img/tile3.jpg"
import Tile4 from "./img/tile4.jpg"
import Tile5 from "./img/tile5.jpg"
import Tile6 from "./img/tile6.jpg"
import Tile7 from "./img/tile7.jpg"
import Tile8 from "./img/tile8.jpg"


export const questions = {
  content: [
    {
      mechanics: "Puzzle",
      question: "Wähle zwei benachbarte Teile aus, um sie zu verschieben. Die Puzzleteile können nur vertikal und horizontal verschoben werden.",
      correctAnswers: [
        "b98d56ce-230f-481d-8541-e92160ffb45b",
        "0b83f04e-53bd-4194-88d2-ba7538ecfb1c",
        "0b7aef46-8fe2-401d-a6bd-87c1d9f70bc4",
        "4560528a-76ce-4522-a5ba-2ab1b5169e1a",
        "5f72be51-ef2d-4d6e-bf71-0177af7e9909",
        "d73b5d46-0798-4d97-9917-b8dc619543cc",
        "0fdb020f-4b9c-4867-b113-69cc562ba6c3",
        "97a28231-a760-42af-bc4e-7a09aa19f929",
        "17a0870e-85ed-4d69-82db-2a9d83b23070"
      ],
      answers: [
        {
          ident: "0b7aef46-8fe2-401d-a6bd-87c1d9f70bc4",
          img: Tile2
        },
        {
          ident: "5f72be51-ef2d-4d6e-bf71-0177af7e9909",
          img: Tile4
        },
        {
          ident: "4560528a-76ce-4522-a5ba-2ab1b5169e1a",
          img: Tile3
        },
        {
          ident: "97a28231-a760-42af-bc4e-7a09aa19f929",
          img: Tile7
        },
        {
          ident: "17a0870e-85ed-4d69-82db-2a9d83b23070",
          img: Tile8
        },
        {
          ident: "b98d56ce-230f-481d-8541-e92160ffb45b",
          img: Tile0
        },
        {
          ident: "d73b5d46-0798-4d97-9917-b8dc619543cc",
          img: Tile5
        },
        {
          ident: "0fdb020f-4b9c-4867-b113-69cc562ba6c3",
          img: Tile6
        },
        {
          ident: "0b83f04e-53bd-4194-88d2-ba7538ecfb1c",
          img: Tile1
        },
      ]
    },
  ]
}

