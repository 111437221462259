import { animated } from "@react-spring/web";
import "./style.scss";
import close from "./img/close.svg";
import Button from "../Button";

export default function CorrectAnswerPopup({
  attributes,
  setPopup,
  styles,
  copy,
  handleClosePopup,
  number,
  totalNumber,
}) {
  return (
    <animated.div
      className="correct-answer-popup-window"
      ref={setPopup}
      {...attributes.popper}
      style={{ ...styles }}
    >
      <img
        className="popup-close"
        alt="close"
        src={close}
        onClick={() => {
          handleClosePopup();
          console.log("clicked");
        }}
      />
      <div className="popup-wrapper">
        <p className="popup-number">
          {number}/{totalNumber}
        </p>
        <p
          className="popup-copy"
          dangerouslySetInnerHTML={{
            __html: copy,
          }}
        ></p>
        <Button
          action={() => {
            handleClosePopup();
            console.log("clicked");
          }}
          btnClass="white"
        >
          OK!
        </Button>
      </div>
    </animated.div>
  );
}
