import React from 'react'
import Modal from "../index"
import PointsIcon from "./img/points_icon.svg"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import { useNavigate } from 'react-router-dom';

export default NiceModal.create(() => {
  const modal = useModal();
  const navigate = useNavigate();

  return (
    <Modal isOpen={modal.visible} onClose={() => { modal.hide(); if (modal?.args?.shouldNavigate) { navigate("/aufgaben") } }}
      onAfterClose={() => modal.remove()} barIcon={PointsIcon}
      innerClass={"correct-answer-modal"}>
      <h3 className="upper">Fantastisch!</h3>
      <p className="sub">Du hast abgesahnt:</p>
      <div className="card">
        <p className="points">
          <span>{modal?.args?.payout ?? ""}</span> Punkte
        </p>
        <p className="copy">Es wird deinen Status und dein Punkteguthaben erhöhen, das du für Belohnungen ausgeben kannst.</p>
      </div>
      <div className="bottom">
        <h4 className="bottom-head">Geschafft! Herzlichen Glückwunsch, du nimmst an unserem OMR-Gewinnspiel teil.</h4>
        {/* <p className="bottom-des">Besuche den glo™ Shop und sichere dir 40 % Rabatt auf alle glo™ Geräte und coolen Accessoires. Das Angebot gilt nur bis zum 21.02.2024.
        </p> */}
      </div>
      <Button action={() => {
        modal.hide(); if (modal?.args?.shouldNavigate) {
          navigate(modal?.args?.customLink?.internalLink ? modal?.args?.customLink?.internalLink : "/aufgaben")
        }
      }}
        {...(modal?.args?.customLink?.outsideLink ? { href: modal?.args?.customLink?.outsideLink } : {})}
      >
        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M13.0202 0.940348C13.4215 1.31977 13.4392 1.95269 13.0598 2.35401L5.39984 10.4559L0.93986 5.73862C0.560434 5.33731 0.578179 4.70439 0.979495 4.32496C1.38081 3.94554 2.01373 3.96328 2.39315 4.3646L5.39984 7.54475L11.6065 0.979983C11.986 0.578667 12.6189 0.560922 13.0202 0.940348Z" fill="white" />
        </svg>
        {modal?.args?.shouldNavigate ? modal?.args?.customLink?.copy ? modal?.args?.customLink?.copy : "Zur Aufgabenübersicht" : "Danke"}
      </Button>
    </Modal>
  );
});
