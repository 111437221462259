import React, { useEffect } from 'react'
import GloPlusLogo from "../../../img/glo-plus.svg"
import Button from '../../components/Button'
import LogoutSVG from "./img/logout.svg"
import "./styles.scss"

export default function LoggedOut() {
  useEffect(() => {
    const documentHeight = () => {
      const obj = document.querySelector(".full-viewport")
      obj.style.setProperty('--height', `${window.innerHeight}px`)
    }

    documentHeight()
    window.addEventListener("resize", documentHeight)

    return () => {
      window.removeEventListener("resize", documentHeight)
    }
  }, [])
  
  return (
    <section className='logout-page full-viewport'>
      <div className="inner ">
        <img src={GloPlusLogo} alt="" className='logo' />
        <img src={LogoutSVG} alt="" className='logout-svg'/>
        <h2>ALLES ERLEDIGT!</h2>
        <p>Hab einen schönen Tag und komm bald wieder – die nächsten Prämien warten auf dich!</p>
        <p>Noch etwas vergessen?</p>
        <Button href={`${process.env.REACT_APP_DG_URL}/gloloyalty/manage`}>Zum Log In</Button>
      </div>
    </section>
  )
}
