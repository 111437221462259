import React, { useState } from 'react'
import Trash from "../../../img/trash.svg"
import { useUserDispatch, useUserState } from '../../UserContext'
import { ReactComponent as Edit } from "../../../img/edit.svg"
import "./style.scss"
import NiceModal from '@ebay/nice-modal-react';

export default function ProfilePage() {
  const { userDetails } = useUserState()
  const [userData, setUserData] = useState({ ...userDetails })

  const Input = ({ inputName, labelContent, editable = false }) => {
    return (
      <button disabled={!editable} className="edit" onClick={() => NiceModal.show('edit-account-modal',
        { labelContent, setUserData: (value) => setUserData(prev => ({ ...prev, [inputName]: value })), userData, inputName }
      )}>
        <label htmlFor={inputName}>
          <p>{labelContent}</p>
          <input type="text" name={inputName} disabled value={inputName === "mobilePhone" ? userData[inputName].replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4') : userData[inputName]} />
          {editable && <Edit />}
        </label>
      </button>
    )
  }

  return (
    <section className='profile-page'>
      <h1>PROFILE</h1>
      <Input
        inputName="firstName"
        labelContent="Vorname"
      />
      <Input
        inputName="lastName"
        labelContent="Nachname"
      />
      <Input
        inputName="emailAddress"
        labelContent="Email"
      />
      <Input
        inputName="mobilePhone"
        labelContent="Handynummer"
      />
      <p>Deine E-Mail Adresse oder Handynummer kannst du auf <span>discoverglo.com/de/de/profile</span> ändern.</p>
      <button className='close-account' onClick={() => NiceModal.show('close-account-modal')}>
        <div className="inner">
          <img src={Trash} alt="" />
          <p>Account löschen</p>
        </div>
      </button>
    </section>
  )
}
