import React from "react";
import LoginPage from "../../pages/StatePages/LoginPage";
// import { useUserState } from '../../UserContext'
 
export const ProtectedRoute = ({ children }) => {
    // const userDetails = useUserState()

    return (
        !Boolean(JSON.parse(sessionStorage.getItem("token"))?.uuid) ? (
            <LoginPage/>
        ) : (
            children
        )
    )
}

export const ProtectedComponent = ({ children }) => {
    // const userDetails = useUserState()

    return (
        !Boolean(JSON.parse(sessionStorage.getItem("token"))?.uuid) ? (
            null
        ) : (
            children
        )
    )
}