import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../../components/Button'
import PageHeader from '../../../../components/PageHeader'

export default function Poll({ questions, currentQuestion, setCurrentQuestion, answers, setAnswers, ident, sendAnswers }) {
  const [step, setStep] = useState(0)
  const [textAreaValue, setTextAreaValue] = useState('')
  const [terms, setTerms] = useState(false)
  const [terms2, setTerms2] = useState(false)

  const handleTextAreaChange = event => {
    const inputValue = event.target.value;
    setTextAreaValue(inputValue);
    if (inputValue.length > 300) {
      setTextAreaValue(inputValue.slice(0, 300));
    }
  };

  const nextQuestion = () => {
    setCurrentQuestion(prev => prev < questions.content.length - 1 ? ++prev : prev)
  }

  const handleNextClick = () => {
    if (currentQuestion + 1 === questions.content.length) {
      sendAnswers({
        showModal: false,
        customSuccessCallback: () => {
          setStep(1)
        }
      })
      return
    }
    if (ident === "poll_2" && answers[0].answer_ident === 1) {
      sendAnswers()
      return
    }
    setTextAreaValue("");
    nextQuestion()
  }

  useEffect(() => {
    if (typeof answers[currentQuestion]?.answer_ident === "string") {
      let newAnswers = [...answers]
      newAnswers[currentQuestion] = {
        question_ident: `q${currentQuestion + 1}`,
        answer_ident: textAreaValue
      }
      setAnswers(newAnswers)
    }

  }, [textAreaValue])

  const handleAnswerClick = index => {
    let newAnswers = [...answers]
    if (questions.content[currentQuestion].answers[index].textArea !== undefined) {
      newAnswers[currentQuestion] = {
        question_ident: `q${currentQuestion + 1}`,
        answer_ident: textAreaValue
      }
    } else
      newAnswers[currentQuestion] = {
        question_ident: `q${currentQuestion + 1}`,
        answer_ident: questions.content[currentQuestion].answers[index].ident
      }

    setAnswers(newAnswers)
  }

  const prevQuestion = () => {
    if (currentQuestion === 0) {
      return
    }
    setCurrentQuestion(prev => prev - 1)
  }

  console.log(answers)

  return (
    <section className={`game-page quiz poll ${ident}`}>
      <PageHeader>{questions.content[currentQuestion].title ?? "glo+ Quiz"}</PageHeader>
      {step === 0 ?
        <div className="wrapper">
          {questions.content[currentQuestion].mobileTopImg && <div className={`mobile-top-img`} style={{ backgroundImage: `url(${questions?.content[currentQuestion]?.img})` }}>
          </div>}
          <div className="game">
            <div className="progress">
              {Array(questions.content.length).fill(null).map((_, index) => (
                <div className={`number ${currentQuestion >= index ? "active" : ""}`} key={index}>
                  <div><p>{++index}</p></div>
                </div>
              ))}
            </div>
            <div className="question">
              <h4>{questions.content[currentQuestion].question}</h4>
            </div>
            {questions.content[currentQuestion].mobileQuestionImg && <div className="mobile-question-img"
              style={{ backgroundImage: `url(${questions.content[currentQuestion].img})` }}>
            </div>}
            <div className="answers-background">
              <div className={`answers ${questions.content[currentQuestion].type} ${questions.content[currentQuestion]?.forceSizeBySideMobile ? "side-by-side" : ""}`}>
                {questions.content[currentQuestion].answers.map((element, index) => (
                  ["button", "image", "button-image", "oval"].includes(questions.content[currentQuestion].type) &&
                  <React.Fragment key={index}>
                    {element.textArea !== undefined && element.textArea !== null ? (
                      <div className='poll-text-area'>
                        <button
                          onClick={() => handleAnswerClick(index)}
                          className={
                            (answers[currentQuestion]?.answer_ident && typeof answers[currentQuestion]?.answer_ident === "string") || answers[currentQuestion]?.answer_ident === ""
                              ? "selected"
                              : ""
                          }
                          key={index}
                        >
                          <div className="inner">
                            {"button" === questions.content[currentQuestion].type && (
                              <p>{element.content}</p>
                            )}
                            {"button-image" === questions.content[currentQuestion].type && (
                              <div className="button-image-wrapper">
                                <img src={element.img} alt="" />
                                <p>{element.content}</p>
                              </div>
                            )}
                            {"oval" === questions.content[currentQuestion].type && (
                              <>
                                <p><span className="oval-inner" />{element.content}</p>
                              </>
                            )}
                          </div>
                        </button>
                        <textarea disabled={typeof answers[currentQuestion]?.answer_ident === "number" || answers[currentQuestion]?.answer_ident === undefined} className='text-area' value={textAreaValue}
                          onChange={handleTextAreaChange}></textarea>
                      </div>
                    ) : (
                      <button
                        onClick={() => handleAnswerClick(index)}
                        className={
                          answers[currentQuestion]?.answer_ident ===
                            questions.content[currentQuestion].answers[index].ident
                            ? "selected"
                            : ""
                        }
                        key={index}
                      >
                        <div className="inner">
                          {"button" === questions.content[currentQuestion].type && (
                            <p>{element.content}</p>
                          )}
                          {"oval" === questions.content[currentQuestion].type && (
                            <>
                              <p><span className="oval-inner" />{element.content}</p>
                            </>
                          )}
                          {"button-image" === questions.content[currentQuestion].type && (
                            <div className="button-image-wrapper">
                              <img src={element.img} alt="" />
                              <p>{element.content}</p>
                            </div>
                          )}
                        </div>
                      </button>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className='poll-terms'>
              {questions.content[currentQuestion].terms && <div><p><span onClick={() => setTerms(!terms)} className={`square-inner ${terms ? "selected" : ""}`} /><span className='terms-text'>{questions.content[currentQuestion].terms}</span></p></div>}
              {questions.content[currentQuestion].termsData && <div><p><span onClick={() => setTerms2(!terms2)} className={`square-inner ${terms2 ? "selected" : ""}`} /><span className='terms-text'>{questions.content[currentQuestion].termsData}</span></p></div>}
            </div>
            <div className="buttons-wrapper">
              {/* <Button action={prevQuestion} disabled={currentQuestion === 0} btnClass="white">Zurück</Button> */}
              <Button action={handleNextClick} disabled={
                !answers[currentQuestion]?.question_ident ||
                (
                  (questions.content[currentQuestion].terms !== undefined ? !terms : false) ||
                  (questions.content[currentQuestion].termsData !== undefined ? !terms2 : false)
                )
              }>{questions.content[currentQuestion]?.buttonCopy ?? "Nächste Frage"}</Button>
            </div>
          </div>
          <div className={`side-img`} style={{ backgroundImage: `url(${questions?.content[currentQuestion]?.img})` }}>
          </div>
        </div>
        :
        <div className="wrapper">
          {questions.content[currentQuestion].mobileTopImg && <div className={`mobile-top-img`} style={{ backgroundImage: `url(${questions?.finishImg})` }}>
          </div>}
          <div className="game poll-finish">
            <p>Und zack – fertig! Wir werden dich in Kürze benachrichtigen,<br className='ommit' />wenn du zu den glücklichen Gewinnern gehörst.<br /><br />Danke für deine Teilnahme!</p>
          </div>
          <div className={`side-img`} style={{ backgroundImage: `url(${questions?.finishImg})` }}>
          </div>
        </div>
      }
    </section >
  )
}
