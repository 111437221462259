import SideImg from "./img/side-img.webp"

export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/heat-sticks/veo/?utm_source=Glo%2B&utm_medium=Article&utm_campaign=Veo",
    copy: "veo kaufen"
  },
  content: [
    {
      mechanics: "QuizMultiSelect",
      buttonCopy: "Nächste Frage",
      title: "Wie gut kennst du jetzt veo™ Sticks?",
      mobileTopImg: true,
      type: "button",
      question: "Was steckt in veo™ drin? Kreuze alle richtigen Antworten an",
      img: SideImg,
      correctAnswers: [
        "a",
        "c",
        "d"
      ],
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Nikotin"
        },
        {
          ident: "b",
          content: "Tabak"
        },
        {
          ident: "c",
          content: "Rooibos"
        },
        {
          ident: "d",
          content: "Flavours"
        },
      ]
    },
    {
      mechanics: "QuizMultiSelect",
      buttonCopy: "Nächste Frage",
      title: "Wie gut kennst du jetzt veo™ Sticks?",
      mobileTopImg: true,
      type: "button",
      question: "Wie viele Geschmacksvarianten gibt es bei veo™?",
      img: SideImg,
      correctAnswers: [
        "b"
      ],
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "6"
        },
        {
          ident: "b",
          content: "5"
        },
        {
          ident: "c",
          content: "4"
        },
        {
          ident: "d",
          content: "7"
        },
      ]
    },
    {
      mechanics: "QuizMultiSelect",
      buttonCopy: "Nächste Frage",
      title: "Wie gut kennst du jetzt veo™ Sticks?",
      mobileTopImg: true,
      type: "button",
      question: "Welche Geschmacksrichtungen hat veo™?",
      img: SideImg,
      correctAnswers: [
        "d"
      ],
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Exotisches Mango"
        },
        {
          ident: "b",
          content: "Rote Beete"
        },
        {
          ident: "c",
          content: "Minz-Schokolade"
        },
        {
          ident: "d",
          content: "Rote Beeren"
        },
      ]
    },
    {
      mechanics: "QuizMultiSelect",
      buttonCopy: "Nächste Frage",
      title: "Wie gut kennst du jetzt veo™ Sticks?",
      mobileTopImg: true,
      type: "button",
      question: "Wo kommt die Rooibos-Pflanze ursprünglich her?",
      img: SideImg,
      correctAnswers: [
        "b"
      ],
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Madagaskar"
        },
        {
          ident: "b",
          content: "Südafrika"
        },
        {
          ident: "c",
          content: "Sri Lanka"
        },
        {
          ident: "d",
          content: "Australien"
        },
      ]
    },
    // {
    //   mechanics: "QuizMultiSelect",
    //   buttonCopy: "Nächste Frage",
    //   title: "Wie gut kennst du jetzt veo™ Sticks?",
    //   mobileTopImg: true,
    //   type: "button",
    //   question: "Entsteht bei dem Verbrauch von veo™ Sticks ein Rauch- oder Aschengeruch?",
    //   img: SideImg,
    //   correctAnswers: [
    //     "b"
    //   ],
    //   wrongAnswerInfo: {
    //     type: "basic",
    //   },
    //   answers: [
    //     {
    //       ident: "a",
    //       content: "Ja"
    //     },
    //     {
    //       ident: "b",
    //       content: "Nein"
    //     },
    //   ]
    // },
    {
      mechanics: "QuizMultiSelect",
      buttonCopy: "Nächste Frage",
      title: "Wie gut kennst du jetzt veo™ Sticks?",
      mobileTopImg: true,
      type: "button",
      question: "Zu welchem Device passt veo™ perfekt?",
      img: SideImg,
      correctAnswers: [
        "c"
      ],
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Vuse PRO Smart"
        },
        {
          ident: "b",
          content: "Vuse Go"
        },
        {
          ident: "c",
          content: "glo™"
        },
        {
          ident: "d",
          content: "Keinem der oben genannten"
        },
      ]
    },
  ]
}