import Tile0 from "./img/tile0.jpg"
import Tile1 from "./img/tile1.jpg"
import Tile2 from "./img/tile2.jpg"
import Tile3 from "./img/tile3.jpg"
import Tile4 from "./img/tile4.jpg"
import Tile5 from "./img/tile5.jpg"
import Tile6 from "./img/tile6.jpg"
import Tile7 from "./img/tile7.jpg"
import Tile8 from "./img/tile8.jpg"


export const questions = {
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/shop/ ",
    copy: "Jetzt Air-gattern"
  },
  content: [
    {
      mechanics: "Puzzle",
      question: "Du kannst immer zwei horizontal oder vertikal benachbarte Teile auswählen. Diese werden dann vertauscht. ",
      correctAnswers: [
        "fc9012f2-03bc-4341-aa9a-c1a4d78b7909",
        "d0bccf04-64bc-4da7-b455-ed2614be50cf",
        "2b6d3e53-0e76-4a30-b8cd-83123e377c65",
        "8e006866-9bac-4580-b4fc-499b4a64f094",
        "465c2758-3260-43b5-b3fe-9f0823d61537",
        "40ea3cca-5ec4-49f4-bff5-e2be8f7f1b75",
        "5c24cb2d-5005-4887-88c6-d707dcb336b3",
        "86d2d344-7f5d-4f94-a7fa-159c7cade32d",
        "84912ada-a961-4491-aaf8-dd6dfc9ed4da"
      ],
      answers: [
        {
          ident: "2b6d3e53-0e76-4a30-b8cd-83123e377c65",
          img: Tile2
        },
        {
          ident: "465c2758-3260-43b5-b3fe-9f0823d61537",
          img: Tile4
        },
        {
          ident: "8e006866-9bac-4580-b4fc-499b4a64f094",
          img: Tile3
        },
        {
          ident: "86d2d344-7f5d-4f94-a7fa-159c7cade32d",
          img: Tile7
        },
        {
          ident: "84912ada-a961-4491-aaf8-dd6dfc9ed4da",
          img: Tile8
        },
        {
          ident: "fc9012f2-03bc-4341-aa9a-c1a4d78b7909",
          img: Tile0
        },
        {
          ident: "40ea3cca-5ec4-49f4-bff5-e2be8f7f1b75",
          img: Tile5
        },
        {
          ident: "5c24cb2d-5005-4887-88c6-d707dcb336b3",
          img: Tile6
        },
        {
          ident: "d0bccf04-64bc-4da7-b455-ed2614be50cf",
          img: Tile1
        },
      ]
    },
    // {
    //   mechanics: "Puzzle",
    //   question: "Wähle zwei benachbarte Teile aus, um sie zu verschieben. Die Puzzleteile können nur vertikal und horizontal verschoben werden.",
    //   answers: [
    //     {
    //       ident: "a",
    //       img: Tile0_1
    //     },
    //     {
    //       ident: "b",
    //       img: Tile1_1
    //     },
    //     {
    //       ident: "c",
    //       img: Tile2_1
    //     },
    //     {
    //       ident: "a",
    //       img: Tile3_1
    //     },
    //     {
    //       ident: "b",
    //       img: Tile4_1
    //     },
    //     {
    //       ident: "c",
    //       img: Tile5_1
    //     },
    //     {
    //       ident: "a",
    //       img: Tile6_1
    //     },
    //     {
    //       ident: "b",
    //       img: Tile7_1
    //     },
    //     {
    //       ident: "c",
    //       img: Tile8_1
    //     },
    //   ]
    // }
  ]
}

