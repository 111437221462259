import SideImg from "./img/side-image.webp"
import Rose from "./img/rose.webp"
import Teal from "./img/teal.webp"
import Blue from "./img/blue.webp"
import Black from "./img/black.webp"
import Bronze from "./img/bronze.webp"

export const questions = {
  staticSideImg: SideImg,
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/glo-heater/glo-hyper-pro?utm_source=Glo%2B&utm_medium=Activity&utm_campaign=hyperpro#obsidian-black",
    title: "Fantastisch!",
    mainInfo: "Mit dem neuen glo™ Hyper Pro wird dein Geschmackserlebnis auf ein neues Level gehoben.*<br/>Jetzt alle neuen Funktionen entdecken!",
    info: "*Im Vergleich zum glo™ Hyper X2 Standard-Modus. Dieses Produkt ist nicht risikofrei und enthält Nikotin, eine abhängig machende Substanz.",
    copy: "Jetzt kaufen"
  },
  content: [
    {
      mechanics: "Pick",
      buttonCopy: "Nächste",
      title: "Alle Farben des glo™ Hyper Pro ",
      mobileTopImg: true,
      type: "button",
      question: "Probier alle verschiedenen Farben des neuen glo™ Hyper Pro aus und lass uns wissen, welche dein Favorit ist!",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          img: Rose,
          content: "Quartz Rose",
        },
        {
          ident: "b",
          img: Teal,
          content: "Jade Teal",
        },
        {
          ident: "c",
          img: Blue,
          content: "Lapis Blue",
        },
        {
          ident: "d",
          img: Black,
          content: "Obsidian Black",
        },
        {
          ident: "e",
          img: Bronze,
          content: "Amber Bronze",
        },
      ]
    },
  ]
}