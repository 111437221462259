export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  disableAutoSubmit: true,
  finishImg: require("./img/6.webp"),
  content: [
    {
      mechanics: "Poll",
      buttonCopy: "Nächste Frage",
      title: "OMR-QUIZ",
      mobileTopImg: true,
      type: "button",
      question: "Wann und wo findet das\nOMR-Festival statt?",
      img: require("./img/1.webp"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: 0,
          content: "Online unter discoverglo.com"
        },
        {
          ident: 1,
          content: "Am 27.06.23 – 26.07.23\nin Hamburger Theater"
        },
        {
          ident: 2,
          content: "Anfang April in Berlin"
        },
        {
          ident: 3,
          content: "Am 09.05.23 – 10.05.23 in den\nHamburger Messehallen"
        },
      ]
    },
    {
      mechanics: "Poll",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "OMR-QUIZ",
      mobileTopImg: true,
      question: "Wer wär am liebsten beim\nOMR-Festival dabei?",
      img: require("./img/2.webp"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: 0,
          content: "Beauty-Blogger"
        },
        {
          ident: 1,
          content: "Fans von Digital-Marketing"
        },
        {
          ident: 2,
          content: "Steuerberater"
        },
        {
          ident: 3,
          content: "Schiffskaufmänner/frauen"
        },
      ]
    },
    {
      mechanics: "Poll",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "OMR-QUIZ",
      mobileTopImg: true,
      question: "Wie heißt der Host der offiziellen glo™ Instagram Talk Show Reel Talk?",
      img: require("./img/3.webp"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: 0,
          content: "Heidi Klum"
        },
        {
          ident: 1,
          content: "Bonnie Strange"
        },
        {
          ident: 2,
          content: "Serena Williams"
        },
        {
          ident: 3,
          content: "Ashton Kutcher"
        },
      ]
    },
    {
      mechanics: "Poll",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "OMR-QUIZ",
      mobileTopImg: true,
      question: "Was erwartet dich\nbei dem OMR-Festival?",
      img: require("./img/4.webp"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: 0,
          content: "Masterclasses, internationale\nSpeaker, Side-Events und glo™"
        },
        {
          ident: 1,
          content: "Zertifizierte Fortbildungen\nzum Heaten"
        },
        {
          ident: 2,
          content: "Tipps und Tricks rund ums\nThema Offline-Marketing"
        },
        {
          ident: 3,
          content: "Excel-Kurs für Anfänger"
        },
      ]
    },
    {
      mechanics: "Poll",
      buttonCopy: "Fertig!",
      type: "button",
      title: "OMR-QUIZ",
      mobileTopImg: true,
      question: "Unter welchem Motto präsentiert sich glo™ – auch auf dem OMR-Festival?",
      img: require("./img/5.webp"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: 0,
          content: "#glotheway – den Weg, den glo™ vorgibt"
        },
        {
          ident: 1,
          content: "#heatersgonnaheat – wir mögen’s heiß"
        },
        {
          ident: 2,
          content: "#glowiththeflow – immer mit dem Strom schwimmen"
        },
        {
          ident: 3,
          content: "#gloyourway – geh DEINEN eigenen Weg"
        },
      ]
    },
  ]
}