import React, { useEffect, useState } from "react";
import { ReactComponent as Egg } from "../../../img/egg.svg";
import { ReactComponent as Close } from "../../../img/close.svg";
import { useTransition } from "@react-spring/web";
import EasterPopup from "./EasterPopup";
import { usePopper } from "react-popper";
import { getEasterEggArray, finishEasterGame } from ".";
import { useUserDispatch } from "../../UserContext";
import Button from "../Button";
import { fetchData } from "../../Helpers";

export const hiddenActivities = ["poll_1", "quiz_6", "quiz_19"];

export default function EasterEggCounter() {
  const [foundEggsCount, setFoundEggsCount] = useState(
    getEasterEggArray().length
  );
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [easterGameFinished, setEasterGameFinished] = useState(false);
  const [hideBar, setHideBar] = useState(false);
  const dispatch = useUserDispatch();
  const [box, setBox] = useState(null);
  const [popup, setPopup] = useState(null);
  const [apiActivites, setAPIActivities] = useState([]);
  const [gloProfilePoints, setGloProfilePoints] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    fetchData({
      endpoint: "/activation/list",
      payload: {
        limit: 100,
        offset: 0,
      },
      successCallback: (resObj) => {
        setAPIActivities(
          resObj.data.activations.filter((e) => e.ident.includes("quiz_19"))
        );
        setGloProfilePoints(
          resObj.data.activations
            .filter(
              (e) =>
                e.ident.includes("complete_profile_") && e.currentBalance === 0
            )
            .map((e) => e.payout)
            .reduce((partial, a) => partial + a, 0)
        );
      },
      controller: controller,
      setLoading: setLoading,
    });

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const isBetweenDates =
      currentDate >= new Date("2024-03-25") &&
      currentDate <= new Date("2024-04-01");

    const getEggsCount = () => {
      setFoundEggsCount(getEasterEggArray().length);
      setEasterGameFinished(
        JSON.parse(window.sessionStorage.getItem("easterFinished")) ?? false
      );
      setHideBar(
        JSON.parse(window.sessionStorage.getItem("hideEasterCounter")) ?? true
      );
    };

    if (apiActivites[0]?.completed || !isBetweenDates) {
      setEasterGameFinished(true);
      setHideBar(true);
      window.sessionStorage.setItem("easterFinished", true);
      window.sessionStorage.setItem("hideEasterCounter", true);
    } else {
      getEggsCount();
      window.addEventListener("storage", getEggsCount);

      return () => {
        window.removeEventListener("storage", getEggsCount);
      };
    }
  }, [apiActivites[0]?.completed]);

  const { styles, attributes } = usePopper(box, popup, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 27],
        },
      },
      {
        name: "eventListeners",
        enabled: showCancelPopup,
      },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: { top: 100, right: 24 },
        },
      },
    ],
  });

  const transition = useTransition(showCancelPopup, {
    from: {
      opacity: 0,
      scale: 0,
    },
    enter: {
      opacity: 1,
      width: "275px",
      scale: 1,
    },
    leave: {
      opacity: 0,
      scale: 0,
    },
  });

  return (
    !easterGameFinished &&
    !hideBar && (
      <div className="easter-counter">
        {foundEggsCount === 5 ? (
          <p className="small">Du hast alle 5 Ostereier gefunden</p>
        ) : (
          <p>
            <strong>{foundEggsCount}/5 Ostereier gefunden</strong>
          </p>
        )}
        <div className="counter">
          {foundEggsCount === 5 ? (
            <Button
              action={() => {
                finishEasterGame(dispatch);
              }}
            >
              Punkte sammeln
            </Button>
          ) : (
            Array(5)
              .fill(false)
              .fill(true, 0, foundEggsCount)
              .map((e, index) => (
                <Egg key={index} className={`${!e ? "hidden" : ""}`} />
              ))
          )}
        </div>
        <button
          onClick={() => {
            setShowCancelPopup(true);
          }}
          ref={setBox}
          className="cancel"
        >
          <div className="inner">
            <Close />
          </div>
        </button>
        {transition((style, item) =>
          item ? (
            <EasterPopup
              popupOpen={showCancelPopup}
              setPopupOpen={setShowCancelPopup}
              setPopup={setPopup}
              attributes={attributes}
              styles={{ ...styles.popper, ...style }}
              cancel={true}
            />
          ) : (
            ""
          )
        )}
      </div>
    )
  );
}
