import React from 'react'
import Modal from "../index"
import PointsIcon from "./img/points_icon.svg"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import { useNavigate } from 'react-router-dom';

export default NiceModal.create(() => {
  const modal = useModal();
  const navigate = useNavigate();

  return (
    <Modal isOpen={modal.visible} onClose={() => { modal.hide(); { navigate("/pramienshop") } }}
      onAfterClose={() => modal.remove()} barIcon={PointsIcon}
      innerClass={"award-form-sucess"}>
      <h3 className="upper">Deine Tickets sind zum Greifen nah!</h3>
      <p className="sub">Wir informieren dich so schnell wie möglich. Die Tickets gibt es dann per Mail.</p>
      <Button action={() => { modal.hide(); navigate("/pramienshop") }}>
        Zurück zum Katalog
      </Button>
    </Modal>
  );
});
