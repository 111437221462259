import React from 'react'
import Modal from "../index"
import PointsIcon from "./img/points_icon.svg"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import { useNavigate } from 'react-router-dom';
import packImg from "../../../pages/ActivitiesPage/Activities/Idents/quiz_8/img/packLounge.png"
import packImg2 from "../../../pages/ActivitiesPage/Activities/Idents/quiz_8/img/packTerazza.png"

export default NiceModal.create(() => {
    const modal = useModal();
    const navigate = useNavigate();

    return (
        <Modal isOpen={modal.visible} onClose={() => { modal.hide(); modal.args?.nextQuestion() }}
            onAfterClose={() => modal.remove()} barIcon={PointsIcon}
            innerClass={"new-flavor-modal"}>
            <img alt='pack' src={modal?.args?.currentQuestion === 0 ? packImg : packImg2} />
            <div className='wrapper'>
                <h2>{modal?.args?.currentQuestion === 0 ? "Wir präsentieren:" : "Und noch dazu:"}</h2>
                <h3 className="upper">{modal?.args?.currentQuestion === 0 ? "neo™ LOUNGE CLICK" : "neo™ TERRAZZA CLICK"}</h3>
                <p className="sub">{modal?.args?.currentQuestion === 0 ? "Bittersüße Orangen- & Zitrusnoten komplettiert durch würziges Kräuteraroma" : "Weintrauben-Geschmack verbunden mit der Kraft roter Früchte"}</p>
                <div className='new-flavor-button'>
                    <Button action={() => { modal.hide(); modal.args?.nextQuestion() }}
                        {...(modal?.args?.currentQuestion === 1 ? { href: "https://www.discoverglo.com/de/de/neo-sticks/?utm_source=Glo%2B&utm_medium=CTA&utm_campaign=TN5_Launch" } : {})}>
                        {modal?.args?.currentQuestion === 0 ? "Nächste" : "Jetzt Probieren"}
                    </Button>
                </div>
            </div>
        </Modal>
    );
});
