import FirstMobile from "./img/first_mobile.jpg"
import FirstDesktop from "./img/first_desktop.jpg"
import SecondMobile from "./img/second_mobile.jpg"
import SecondDesktop from "./img/second_desktop.jpg"
import ThirdMobile from "./img/ThirdMobile.jpg"
import ThirdDesktop from "./img/ThirdDesktop.jpg"

export const questions = {
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/glo-tabak-heater/glo-bonnie-strange-hyper-x2/?utm_source=Glo%2B&utm_medium=LEP_quiz&utm_campaign=LEP_Device_Quiz",
    copy: "Visit"
  },
  customFinishModal: "correct-find",
  content: [
    {
      mechanics: "Find",
      question: "Finde die glo™ x Bonnie Strange: Hyper X2 Limited Edition auf dem Bild",
      imgMobile: FirstMobile,
      imgDesktop: FirstDesktop,
      answers: [
        {
          ident: "ddd01aaf-ef15-4b78-82e7-0d2f15b65b95",
          clipPathMobile: "16 81, 28 81, 28 92, 16 92",
          clipPathDesktop: "36 81, 41 81, 41 91, 36 91",
        },
      ]
    },
    {
      mechanics: "Find",
      question: "Finde die glo™ x Bonnie Strange: Hyper X2 Limited Edition auf dem Bild",
      imgMobile: SecondMobile,
      imgDesktop: SecondDesktop,
      answers: [
        {
          ident: "2b65cb06-2dfa-47e3-a3cb-88f7ba787dba",
          clipPathMobile: "88 86, 98 86, 98 95, 88 95",
          clipPathDesktop: "65 85, 70 85, 70 95, 65 95",
        },
      ]
    },
    {
      mechanics: "Find",
      question: "Finde die glo™ x Bonnie Strange: Hyper X2 Limited Edition auf dem Bild",
      imgMobile: ThirdMobile,
      imgDesktop: ThirdDesktop,
      answers: [
        {
          ident: "5ac6fa43-dd20-4b8c-8b91-48291bf3957e",
          clipPathMobile: "69 89, 80 89, 81 100, 70 100",
          clipPathDesktop: "57 89, 63 89, 63 100, 57 100",
        },
      ]
    },
  ]
}