export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  content: [
    {
      mechanics: "Quiz",
      type: "button",
      question: "Wie heißt das neueste glo™ Device",
      img: require("./img/1.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      // wrongAnswerInfo: {
      //   type: "extended",
      //   hint: {
      //     content: " Die richtige Anwort findest du in unserem letzten Instagram-Post.",
      //     linkContent: "Go to Instagram profile",
      //     url: "111"
      //   }
      // },
      answers: [
        {
          ident: "a",
          content: "Hyper X2"
        },
        {
          ident: "b",
          content: "Hyper K3"
        },
        {
          ident: "c",
          content: "Hyper M1"
        },
        {
          ident: "d",
          content: "Hyper Z4"
        },
      ]
    },
    {
      mechanics: "Quiz",
      type: "button",
      question: "Wie heißt unsere Instagram-Talkshow?",
      img: require("./img/2.jpg"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
        // hint: {
        //   content: " Die richtige Anwort findest du in unserem letzten Instagram-Post.",
        //   linkContent: "Go to Instagram profile",
        //   url: "111"
        // }
      },
      answers: [
        {
          ident: "a",
          content: "Deep Stuff"
        },
        {
          ident: "b",
          content: "Real Post"
        },
        {
          ident: "c",
          content: "Reel Talk"
        },
        {
          ident: "d",
          content: "Hot Fuzz"
        },
      ]
    },
    {
      mechanics: "Quiz",
      type: "button",
      question: "Wie heißt das größte Festival der Welt?",
      img: require("./img/3.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
        // hint: {
        //   content: " Die richtige Anwort findest du in unserem letzten Instagram-Post.",
        //   linkContent: "Go to Instagram profile",
        //   url: "111"
        // }
      },
      answers: [
        {
          ident: "a",
          content: "Tomorrowland"
        },
        {
          ident: "b",
          content: "Wacken Open Air "
        },
        {
          ident: "c",
          content: "Glastonberry Festival "
        },
        {
          ident: "d",
          content: "Nature One "
        },
      ]
    },
    {
      mechanics: "Quiz",
      type: "button",
      question: "In wie vielen Farben gibt es das X2 Device?",
      img: require("./img/4.jpg"),
      correctAnswer: "d",
      wrongAnswerInfo: {
        type: "extended",
        hint: {
          content: "Die richtige Antwort finden Sie auf unserer Website",
          linkContent: "Go to Discover Glo",
          url: "https://www.discoverglo.com/de/de/"
        }
      },
      answers: [
        {
          ident: "a",
          content: "drei"
        },
        {
          ident: "b",
          content: "vier"
        },
        {
          ident: "c",
          content: "fünf"
        },
        {
          ident: "d",
          content: "sechs"
        },
      ]
    },
  ]
}