import Tile0 from "./img/tile0.jpg"
import Tile1 from "./img/tile1.jpg"
import Tile2 from "./img/tile2.jpg"
import Tile3 from "./img/tile3.jpg"
import Tile4 from "./img/tile4.jpg"
import Tile5 from "./img/tile5.jpg"
import Tile6 from "./img/tile6.jpg"
import Tile7 from "./img/tile7.jpg"
import Tile8 from "./img/tile8.jpg"


export const questions = {
  content: [
    {
      mechanics: "Puzzle",
      question: "Wähle zwei benachbarte Teile aus, um sie zu verschieben. Die Puzzleteile können nur vertikal und horizontal verschoben werden.",
      correctAnswers: [
        "47789c53-f78c-458e-aa29-1d86f39649c8",
        "bef618b9-c930-4666-ac0b-2fcfda47dbfc",
        "b4d1cd93-b449-447e-a9e3-132777c65971",
        "2927b63e-16b7-412b-98c9-695916d1c7ec",
        "887d784f-f189-42ec-a89b-1fc648d3eb69",
        "218f799c-0854-4ccb-93f8-c645be8db485",
        "6ca01b81-8761-46bc-b982-35125922f418",
        "5eb849f6-17ff-4c39-afbb-85317736edf4",
        "7f5b2377-8605-46fd-8719-111fbc74327a"
      ],
      answers: [
        {
          ident: "b4d1cd93-b449-447e-a9e3-132777c65971",
          img: Tile2
        },
        {
          ident: "887d784f-f189-42ec-a89b-1fc648d3eb69",
          img: Tile4
        },
        {
          ident: "2927b63e-16b7-412b-98c9-695916d1c7ec",
          img: Tile3
        },
        {
          ident: "5eb849f6-17ff-4c39-afbb-85317736edf4",
          img: Tile7
        },
        {
          ident: "7f5b2377-8605-46fd-8719-111fbc74327a",
          img: Tile8
        },
        {
          ident: "47789c53-f78c-458e-aa29-1d86f39649c8",
          img: Tile0
        },
        {
          ident: "218f799c-0854-4ccb-93f8-c645be8db485",
          img: Tile5
        },
        {
          ident: "6ca01b81-8761-46bc-b982-35125922f418",
          img: Tile6
        },
        {
          ident: "bef618b9-c930-4666-ac0b-2fcfda47dbfc",
          img: Tile1
        },
      ]
    },
    // {
    //   mechanics: "Puzzle",
    //   question: "Wähle zwei benachbarte Teile aus, um sie zu verschieben. Die Puzzleteile können nur vertikal und horizontal verschoben werden.",
    //   answers: [
    //     {
    //       ident: "a",
    //       img: Tile0_1
    //     },
    //     {
    //       ident: "b",
    //       img: Tile1_1
    //     },
    //     {
    //       ident: "c",
    //       img: Tile2_1
    //     },
    //     {
    //       ident: "a",
    //       img: Tile3_1
    //     },
    //     {
    //       ident: "b",
    //       img: Tile4_1
    //     },
    //     {
    //       ident: "c",
    //       img: Tile5_1
    //     },
    //     {
    //       ident: "a",
    //       img: Tile6_1
    //     },
    //     {
    //       ident: "b",
    //       img: Tile7_1
    //     },
    //     {
    //       ident: "c",
    //       img: Tile8_1
    //     },
    //   ]
    // }
  ]
}

