import React from 'react'
import Modal from "../index"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import { useNavigate } from 'react-router-dom';
import gift from "./img/gift_icon.svg"

export default NiceModal.create(() => {
  const modal = useModal();
  const navigate = useNavigate();

  const handleClose = () => {
    modal.hide();
    navigate("/pramienshop");
  };

  return (
    <Modal
      isOpen={modal.visible}
      onClose={() => { modal.hide() }}
      onAfterClose={() => modal.remove()}
      barIcon={gift}
      innerClass={"calendar-modal"}
    >
      <div className="calendar-modal-inner">
        <p>Deine heutige Belohnung</p>
        <h2>{modal?.args?.payout} Punkte</h2>
        <Button action={() => { handleClose(); }}>Zum Katalog</Button>
      </div>
    </Modal>
  );
});