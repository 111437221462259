import "./styles.scss"

export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  content: [
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      title: "glo™ QUIZ",
      mobileTopImg: true,
      type: "button",
      question: "Welche einflussreiche Frau ist abgebildet?",
      img: require("./img/1.jpg"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      correctAnswerInfo: {
        content: "Die französisch-polnische Physikerin war die erste Frau, die jemals einen Nobelpreis gewonnen hat. Ihre Forschung der Radioaktivität hat bis heute noch einen großen Einfluss auf die Röntgen-Technologien."
      },
      answers: [
        {
          ident: "a",
          content: "Marie Antoinette"
        },
        {
          ident: "b",
          content: "Marie Curie"
        },
        {
          ident: "c",
          content: "Queen Elizabeth"
        },
        {
          ident: "d",
          content: "Caterina de’Medici"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ QUIZ",
      mobileTopImg: true,
      question: "Welche einflussreiche Frau ist abgebildet?",
      img: require("./img/2.jpg"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      correctAnswerInfo: {
        content: "Ist eine mexikanische Künstlerin, die durch ihre eigenwilligen Selbstporträts mit Monobrauen und Damenbart, die sie malte, zur Ikone wurde. Sie hatte viele Schicksalsschläge in ihrem Leben aber ist sich selbst immer treu geblieben, womit sie viele Frauen inspirierte."
      },
      answers: [
        {
          ident: "a",
          content: "Mona Lisa"
        },
        {
          ident: "b",
          content: "Jane Austen"
        },
        {
          ident: "c",
          content: "Frida Kahlo"
        },
        {
          ident: "d",
          content: "Eva Longoria"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ QUIZ",
      mobileTopImg: true,
      question: "Welche einflussreiche Frau ist abgebildet?",
      img: require("./img/3.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      correctAnswerInfo: {
        content: "Ist heute einer der erfolgreichsten weiblichen Musikerinnen der Welt. Sie hat eine Foundation mit der sie regelmäßig Menschen in Not hilft. Sie setzt sich für den Klimawandel, Veganismus und die POC-Community ein."
      },
      answers: [
        {
          ident: "a",
          content: "Beyoncé"
        },
        {
          ident: "b",
          content: "Rihanna"
        },
        {
          ident: "c",
          content: "Adele"
        },
        {
          ident: "d",
          content: "Selena Gomez"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ QUIZ",
      mobileTopImg: true,
      question: "Welche einflussreiche Frau ist abgebildet?",
      img: require("./img/4.jpg"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      correctAnswerInfo: {
        content: "War ein Teil des US Supreme Courts. Sie hat eine hohe Position in einer Männerdominierten Branche gehabt, weshalb sie sich mit ihrem Beruf für die Geschlechtergleichheit eingesetzt und hat mehrere Gesetze durchsetzen können, die für mehr Gleichberechtigung stehen."
      },
      answers: [
        {
          ident: "a",
          content: "J.K. Rowling"
        },
        {
          ident: "b",
          content: "Ruth Bader Ginsburg"
        },
        {
          ident: "c",
          content: "Emily Brontë"
        },
        {
          ident: "d",
          content: "Lucinder Riley"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ QUIZ",
      mobileTopImg: true,
      question: "Welche einflussreiche Frau ist abgebildet?",
      img: require("./img/5.jpg"),
      correctAnswer: "d",
      wrongAnswerInfo: {
        type: "basic",
      },
      correctAnswerInfo: {
        content: "Die katholische Nonne widmete ihr Leben den Ärmsten der Armen, in Indien und auf der ganzen Welt und wurde für ihre Taten mit dem Friedensnobelpreis ausgezeichnet."
      },
      answers: [
        {
          ident: "a",
          content: "Oprah"
        },
        {
          ident: "b",
          content: "Hildegard von Bingen"
        },
        {
          ident: "c",
          content: "Mutter Angelica"
        },
        {
          ident: "d",
          content: "Mutter Teresa"
        },
      ]
    },
  ]
}