import React from 'react'
import Dislike from "../../../../../img/dislike.svg"
import { ReactComponent as ShortArrowBlack } from "../../../../../img/short-arrow-black.svg"

export default function HintPopup({ visible, setClose, content }) {
  return !visible ? null : (
    <div className="hint-popup">
      <div className="dislike">
        <img src={Dislike} alt="" />
      </div>
      <div className="content">
        <h4>Sorry, das ist die falsche Antwort. Versuch’s nochmal!</h4>
        {content.type === "extended" &&
          <>
            <p>Du brauchst einen Tipp?</p>
            <p>{content.hint.content ? content.hint.content : content.hint}</p>
            {content.hint.url &&
              <a href={content.hint.url} rel="noopener noreferrer" target="_blank">
                <div className="inner">
                  <p>{content.hint.linkContent}</p>
                  <ShortArrowBlack />
                </div>
              </a>}
          </>
        }
      </div>
      <button className="exit" onClick={setClose}>
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.83398 3.33301L13.1673 12.6663" stroke="#343434" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M13.1673 3.33301L3.83398 12.6663" stroke="#343434" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    </div>
  )
}
