import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import Search from "./img/search.svg"
import Arrow from "../../../img/arrow.svg"
import Tick from "../../../img/tick.svg"
import Unavailable from "../RewardsPage/img/unavailable.svg"
import HourGlass from "../../../img/hourglass.svg"
import { useWindowSize, debounce, fetchData, getModuleValue, parseHTMLToText, levelMapper } from '../../Helpers';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import NavSwiper from "../../../img/nav-swiper.svg"
import NewsletterMain from "../ActivitiesPage/Activities/Idents/newsletter/img/main.webp"
import "./style.scss"

import { disableIntroIn, hiddenActivities } from '../ActivitiesPage';

import "swiper/css";
import "swiper/css/pagination"
import "swiper/css/scrollbar";
import "./style.scss"

import SwiperCore, {
  Mousewheel, Pagination, Navigation, Scrollbar
} from 'swiper';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import { useUserState } from '../../UserContext';
import Gift from "../../../img/gift-red-full.svg"
import Controler from "../../../img/controler.svg"
import DOMPurify from 'dompurify';

import OMRImg from "../ActivitiesPage/Activities/Idents/omr_countdown_local/img/OMR-mobile.jpg"
import EasterEggCounter from '../../components/EasterEgg/EasterEggCounter';
import Spider from '../../components/Halloween';
import EasterEgg from '../../components/EasterEgg';

SwiperCore.use([Mousewheel, Pagination, Navigation]);

export default function HomePage() {
  const SCREEN_XGA = 1020;
  const { width } = useWindowSize();
  const { userDetails } = useUserState()
  const [showFaq, setShowFaq] = useState(false)
  const [faqFiltered, setFaqFiltered] = useState([])
  const [allFaqs, setAllFaqs] = useState([])
  const [selectedNews, setSelectedNews] = useState(0)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState({ index: -1, open: false })
  const [activities, setActivities] = useState([])
  const [rewards, setRewards] = useState([])
  const [news, setNews] = useState([])
  const swiperActivitiesRef = useRef(null)
  const navigationActivitiesPrevRef = useRef(null)
  const navigationActivitiesNextRef = useRef(null)
  const swiperRewardsRef = useRef(null)
  const navigationRewardsPrevRef = useRef(null)
  const navigationRewardsNextRef = useRef(null)
  const [stats, setStats] = useState({})

  const [OMRStatus, setOMRStatus] = useState("finished")
  const [activitiesLoading, setActivitiesLoading] = useState(true)


  const [disableCalendarIntro, setDisableCalendarIntro] = useState(false)

  const OMR = {
    init: {
      img: OMRImg,
      type: "Quiz",
      title: "OMR-QUIZ",
      desc: "Beantworte alle Fragen richtig und nimm somit am Gewinnspiel teil. Der Gewinn? 2 x OMR-Festival Tickets im Wert von 798 €!",
      poitsCopy: "2x TICKETS",
      url: "omr_countdown_local/spiel"
    },
    finished: {
      img: OMRImg,
      type: "Quiz",
      title: "Danke für deine Teilnahme!",
      desc: "Wir werden dich in Kürze benachrichtigen, wenn du zu den glücklichen Gewinnern gehörst.",
      poitsCopy: "2x TICKETS",
      url: "omr_countdown_local/spiel"
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    fetchData({
      endpoint: "/activation/list",
      data: {
        "limit": 100,
        "offset": 0
      },
      successCallback: resObj => {
        setActivities(resObj.data.activations.
          filter(e => e.content && !e.expired && !hiddenActivities.includes(e.ident)).
          sort((a, b) => a.completed - b.completed).
          slice(0, 5))
        setOMRStatus(resObj.data.activations.find(e => e.ident === "poll_1")?.currentNumberOfSolutions === 0 ? "init" : "finished")
        const calendarIntro = resObj.data?.advent_2023
        setDisableCalendarIntro(calendarIntro)
      },
      controller: controller,
      setLoading: setActivitiesLoading
    })
    fetchData({
      endpoint: "/faq/list",
      payload: {
        "limit": 100,
        "offset": 0
      },
      successCallback: resObj => {
        setAllFaqs(resObj.data)
      },
      controller: controller
    })
    fetchData({
      endpoint: "/prize/catalogue/list",
      data: {
        "limit": 100,
        "offset": 0,
        "expired": false
      },
      successCallback: resObj => setRewards(resObj.data.catalogue.
        filter(e => e.content && !e.internal && e.available && e.inStock && !e.dailyLimitExceeded && !e.awardLimitExceeded && !e.expired && (e.level !== 1 ? userDetails.level - 1 === e.level || userDetails.level === e.level : e.level === userDetails.level)).
        sort((a, b) => a.level <= b.level || a.basePrice - b.basePrice).
        slice(0, 5)),
      controller: controller,
    })
    fetchData({
      endpoint: "/article/list",
      data: {
        "limit": 100,
        "offset": 0,
        "expired": false
      },
      successCallback: resObj => setNews(resObj.data.filter(e => e.content && e.slug !== "x-mas-specials-your-way").slice(0, 7)),
      controller: controller,
    })

    fetchData({
      endpoint: "/consumer/stats/",
      payload: {},
      successCallback: resObj => setStats(resObj.data),
      controller: controller
    })
    return () => {
      controller.abort()
    }
  }, [])

  const getFAQ = (faq) => {
    // faqContent.forEach(e => console.log(e))
    // const details = document.querySelectorAll("details")
    // details.forEach(detail => detail.removeAttribute("open"))
    setCurrentQuestion(prev => ({ ...prev, open: false }))

    if (faq.length !== 0) {
      const filtered = allFaqs.filter(e => e.content.title.toLowerCase().includes(faq.toLowerCase()) || parseHTMLToText(getModuleValue(e.content.modules, "intro", "custom"), "p").includes(faq.toLowerCase()))
      setFaqFiltered(filtered)
      setShowFaq(true)
      return
    }
    setFaqFiltered([])
    setShowFaq(false)
  }

  const debounceInput = useCallback(debounce(getFAQ, 500), [allFaqs])

  return (
    <section className="home-page">
      <div className="news">
        <h1>Was gibt's Neues?</h1>
        {width < SCREEN_XGA ? <Swiper onSlideChange={(swiper) => {
          if (swiper.isEnd) {
            // setAwardIndex(2)
          } else if (swiper.isBeginning) {
            // setAwardIndex(0)
          } else {
            // setAwardIndex(1)
          }
          // }} className={awardsVisible ? "swiper-awards visible" : "swiper-awards"}
        }} className={"swiper-news-mobile mobile"}
          breakpoints={{
            0: {
              spaceBetween: 16,
              slidesPerView: "auto"
            },
          }}
        >
          {news.length > 0 && news?.map((element, index) => (
            <SwiperSlide key={index}>
              <Link onClick={(e) => {
                if (element?.slug === "deutlich-besseres-geschmackserlebnis-bist-du-bereit") {
                  e.preventDefault();
                }
              }} className={`slide-link ${element?.slug === "deutlich-besseres-geschmackserlebnis-bist-du-bereit" ? "nonClickable" : ""}`} to={`/article/${element?.slug}`}>
                <div className="slide-wrapper">
                  <div className="image">
                    <img src={element?.hero_image?.file?.url} alt="" />
                  </div>
                  <div className="content">
                    <h4 className="title" dangerouslySetInnerHTML={{ __html: element?.content?.title }}></h4>
                    <p className="desc">{element?.content?.description}</p>
                    {element.slug !== "deutlich-besseres-geschmackserlebnis-bist-du-bereit" && <div className="arrow">
                      <img src={Arrow} alt="" />
                    </div>}
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
          :
          <div className="news-desktop desktop">
            <TransitionGroup className="img-wrapper">
              <CSSTransition
                key={selectedNews}
                classNames="news-img"
                timeout={{ enter: 250, exit: 250 }}
              >
                <Link
                  onClick={(e) => {
                    if (news[selectedNews]?.slug === "deutlich-besseres-geschmackserlebnis-bist-du-bereit") {
                      e.preventDefault();
                    }
                  }} className={`img ${news[selectedNews]?.slug === "deutlich-besseres-geschmackserlebnis-bist-du-bereit" ? "nonClickable" : ""}`} style={{ backgroundImage: "url(" + news[selectedNews]?.hero_image?.file?.url + ")" }} to={`/article/${news[selectedNews]?.slug}`}>
                </Link>
              </CSSTransition>
            </TransitionGroup>
            <Swiper pagination={true} direction={'vertical'} navigation={true} mousewheel={true} className="swiper-news-desktop" // change naviation={true} mousewheel={true} later
              width={320} slidesPerView={3}
              onSlideChange={index => setCurrentSlideIndex(index.snapIndex)}>
              {news.length > 0 && news?.map((element, index) => {
                return (<SwiperSlide className={index === selectedNews ? "selected" : ""} width={320} key={index} onMouseOver={() => { setSelectedNews(index) }} style={index === currentSlideIndex + 2 ? { borderBottomStyle: "none" } : {}} >
                  <Link onClick={(e) => {
                    if (element.slug === "deutlich-besseres-geschmackserlebnis-bist-du-bereit") {
                      e.preventDefault();
                    }
                  }} to={element?.slug === "deutlich-besseres-geschmackserlebnis-bist-du-bereit" ? "" : ("/article/" + element.slug)} className={`slide-link ${element?.slug === "deutlich-besseres-geschmackserlebnis-bist-du-bereit" ? "nonClickable" : ""}`}>
                    <div className="content-wrapper">
                      <h4 className="title" dangerouslySetInnerHTML={{ __html: element?.content?.title }}></h4>
                      <p className="desc">{element?.content?.description}</p>
                    </div>
                    {element.slug !== "deutlich-besseres-geschmackserlebnis-bist-du-bereit" && <div className="arrow">
                      <img src={Arrow} alt="" />
                    </div>}
                  </Link>
                </SwiperSlide>
                )
              })}
            </Swiper>
          </div>}
      </div>

      <div className="activities">
        <div className="navigation-swiper-wrapper">
          <h1>AUFGABEN</h1>
          <EasterEgg uuid={"59b66a9f-bce4-4e22-8eab-a3a8fba9717d"} />
          <div className={`navigation-swiper`}>
            <button ref={navigationActivitiesPrevRef}>
              <div className="prev">
                <img src={NavSwiper} alt="" />
              </div>
            </button>
            <div className={`pagination pagination-activites`}>
              {/* {Array(activities.length).fill(false).map((_, index) => (
                <div key={index} className={currentSlideActivities === index ? "selected" : ""}></div>
              ))} */}
            </div>
            <button ref={navigationActivitiesNextRef}>
              <div className="next">
                <img src={NavSwiper} alt="" />
              </div>
            </button>
          </div>
        </div>
        {/* <Swiper pagination={width < SCREEN_XGA ? true : false} navigation={activities.length < 5 ? false : true} onSlideChange={(swiper) => { */}
        <Swiper
          ref={swiperActivitiesRef}
          navigation={{
            prevEl: navigationActivitiesPrevRef.current,
            nextEl: navigationActivitiesNextRef.current,
          }}
          onBeforeInit={swiper => {
            swiper.params.navigation.prevEl = navigationActivitiesPrevRef.current;
            swiper.params.navigation.nextEl = navigationActivitiesNextRef.current;
          }}
          pagination={{
            el: '.pagination-activites',
          }}
          scrollbar={{
            el: '.pagination-activites',
            draggable: true,
            hide: false
          }}
          modules={[Scrollbar]}
          className={"swiper-activities"}

          breakpoints={{
            0: {
              spaceBetween: 16,
              slidesPerView: "auto",
              pagination: {
                type: "fraction",
              },
              scrollbar: {
                enabled: false
              }
            },
            1020: {
              spaceBetween: 32,
              slidesPerView: "auto",
              pagination: {
                enabled: false
              },
              scrollbar: {
                enabled: true
              }
            }
          }}
        >
          {/* {!activitiesLoading && <SwiperSlide>
            <Link to={`/aufgaben/${OMR[OMRStatus].url}`} className={`slide-wrapper pulse`} style={{ pointerEvents: `${OMRStatus === "finished" ? "none" : ""}` }}>
              <div className="image">
                <img src={OMR[OMRStatus].img} alt="" />
              </div>
              <div className="content">
                <p className="type">{OMR[OMRStatus].type}</p>
                <h4 className="title">{OMR[OMRStatus].title}</h4>
                <p className="desc">{OMR[OMRStatus].desc}</p>
                <div className="bottom">
                  <div className="arrow">
                    <img src={Arrow} alt="" />
                  </div>
                  <div className="points">{OMR[OMRStatus].poitsCopy}</div>
                </div>
              </div>
            </Link>
          </SwiperSlide>} */}
          {activities.length > 0 && activities?.map((element, index) => (
            <SwiperSlide key={index}>
              <Link to={`/aufgaben/${element.ident}${(disableIntroIn.includes(element.ident) || (disableCalendarIntro && element.ident.includes("advent_2023"))) ? "/spiel" : ""}`} className={`slide-wrapper ${element.completed ? "done" : ""}`}>
                <div className="image">
                  <img src={element?.thumbnail?.file?.url} alt="" />
                </div>
                <div className="content">
                  <p className="type">{element?.type}</p>
                  <h4 className="title" dangerouslySetInnerHTML={{ __html: element?.content?.title.replace("ß", "&#7838;") }} />
                  <p className="desc" dangerouslySetInnerHTML={{ __html: element?.content?.description.replace("ß", "&#7838;") }} />
                  <div className="bottom">
                    <div className="arrow">
                      <img src={Arrow} alt="" />
                    </div>
                    {element.ident.includes("advent_2023") ? "" : <div className="points">+{element?.payout}<span>P</span></div>}
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
          <SwiperSlide>
            <Link to={`/aufgaben`} className="slide-wrapper navigate-to-all">
              <div className="wrapper">
                <h4 className="title">Siehe Alles</h4>
                <div className="image">
                  <img src={Controler} alt="" />
                </div>
                <div className="arrow">
                  <img src={Arrow} alt="" />
                </div>
              </div>
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="rewards">
        <div className="navigation-swiper-wrapper rewards">
          <h1>Jetzt Prämien shoppen</h1>
          <div className={`navigation-swiper`}>
            <button ref={navigationRewardsPrevRef}>
              <div className="prev">
                <img src={NavSwiper} alt="" />
              </div>
            </button>
            {/* ${activities.length >= 6 ? "remove-transfrom" : ""} */}
            <div className={`pagination pagination-rewards `}>
              {/* {Array(rewards.length).fill(false).map((_, index) => (
                <div key={index} className={currentSlideRewards === index ? "selected" : ""}></div>
              ))} */}
            </div>
            <button ref={navigationRewardsNextRef}>
              <div className="next">
                <img src={NavSwiper} alt="" />
              </div>
            </button>
          </div>
        </div>
        <Swiper
          ref={swiperRewardsRef}
          navigation={{
            prevEl: navigationRewardsPrevRef.current,
            nextEl: navigationRewardsNextRef.current,
          }}
          onBeforeInit={swiper => {
            swiper.params.navigation.prevEl = navigationRewardsPrevRef.current;
            swiper.params.navigation.nextEl = navigationRewardsNextRef.current;
          }}
          pagination={{
            el: '.pagination-rewards',
          }}
          scrollbar={{
            el: '.pagination-rewards',
            draggable: true,
            hide: false
          }}
          modules={[Scrollbar]}
          className={"swiper-rewards"}
          breakpoints={{
            0: {
              spaceBetween: 16,
              slidesPerView: "auto",
              pagination: {
                type: "fraction",
              },
              scrollbar: {
                enabled: false
              }
            },
            1020: {
              spaceBetween: 32,
              slidesPerView: "auto",
              pagination: {
                enabled: false
              },
              scrollbar: {
                enabled: true
              }
            },
            // 1280: {
            //   spaceBetween: 16,
            //   slidesPerView: "auto",
            //   pagination: {
            //     dynamicBullets: true,
            //     dynamicMainBullets: 6
            //   }
            // }
          }}
        >
          {rewards?.map((element, index) => {
            const unavailable = !element?.available || !element?.inStock || element.expired

            return (
              <SwiperSlide key={index}>
                <Link to={`/pramienshop/einlosen/${element.ident}`} className="slide-wrapper">
                  <div className="image">
                    {/* <div className="type">{levelMapper.get(element?.level)}</div>
                    {element.new && <div className="new"><p>New</p></div>} */}
                    <img src={element?.thumbnail?.file?.url} alt="" />
                  </div>
                  <div className="content">
                    <div className={`upper ${userDetails.level < element.level ? "insufficient-level" : unavailable ? "unavailable" : ""}`}>
                      <div className={`points`}>
                        {element?.basePrice}<span>P</span>
                      </div>
                      <div className={`status ${(!element?.awardLimitExceeded && !element?.dailyLimitExceeded) && userDetails?.balance < element?.basePrice ? "progress" : ""}`}>
                        <p dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(element?.awardLimitExceeded || element?.dailyLimitExceeded ? "Bereits erhalten" : userDetails?.level < element?.level ? `Erforderlich: ${levelMapper.get(element?.level).length > 8 ? levelMapper.get(element?.level).substring(0, 7).toUpperCase() + "..." : levelMapper.get(element.level).toUpperCase()}` :
                            unavailable ? "ausverkauft" :
                              userDetails?.balance >= element?.basePrice ? "erreichbar" :
                                `Dir fehlen ${element?.basePrice - userDetails?.balance}<span>P</span>`)
                        }}>
                        </p>
                        {(!element?.awardLimitExceeded && !element?.dailyLimitExceeded) && userDetails.level >= element.level && <img src={unavailable ? Unavailable :
                          userDetails?.balance >= element?.basePrice ? Tick :
                            HourGlass} alt="" />}
                        {(!element?.awardLimitExceeded && !element?.dailyLimitExceeded) && userDetails?.balance < element?.basePrice && !unavailable && userDetails.level >= element.level
                          && <div className='front' style={{ clipPath: `inset(0 0 0 ${Math.min(userDetails?.balance / element?.basePrice * 100, 100)}%)` }}>
                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`Dir fehlen ${element?.basePrice - userDetails?.balance}<span>P</span>`) }}></p>
                            <img src={HourGlass} alt="" />
                          </div>}
                      </div>
                    </div>
                    <h4 className="title" dangerouslySetInnerHTML={{ __html: element?.content?.title }}>

                    </h4>
                    <div className="arrow">
                      <img src={Arrow} alt="" />
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            )
          })}
          <SwiperSlide>
            <Link to={`/pramienshop`} className="slide-wrapper navigate-to-all">
              <div className="image">
                <img src={Gift} alt="" />
              </div>
              <div className="content">
                <h4 className="title">Siehe Alles</h4>
                <div className="arrow">
                  <img src={Arrow} alt="" />
                </div>
              </div>
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="faq">
        <div className='navigation-swiper-wrapper'>
          <h1>FAQ</h1>
        </div>
        <div className="search-bar">
          <div className="search-icon">
            <img src={Search} alt="" />
          </div>
          <input type="text" placeholder='Wonach suchst du?' onChange={e => debounceInput(e.target.value)} />
        </div>

        {/*temp */}
        {showFaq && <div className="faq-content">
          <>
            {faqFiltered.map((element, index) => (
              <Dropdown className='details'
                key={index}
                summary={
                  <div className='question'>
                    <h4>{element.content.title}</h4>
                  </div>
                }
                details={
                  <div className="answer" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getModuleValue(element.content.modules, "intro", "custom")) }}></div>
                }
                open={currentQuestion.index === index && currentQuestion.open}
                setOpen={() => { setCurrentQuestion(prev => ({ index: index, open: prev.index !== index ? true : !prev.open })) }}
              />
              // <div className='details' key={index} onClick={() => setCurrentQuestion(prev => ({ index: index, open: prev.index !== index ? true : !prev.open }))} data-open={currentQuestion.index === index && currentQuestion.open}>
              //   <div className='question'>
              //     <h4>{element.title}</h4>
              //   </div>
              //   {currentQuestion.index === index && currentQuestion.open && <div className="answer">
              //     <p>{element.content}</p>
              //   </div>}
              // </div>
            ))}
          </>
          {showFaq && <div className='missing-answer'>
            <h4>Sie haben nicht gefunden, was Sie suchen? </h4>
            <Button href="/faq">Siehe alle FAQs</Button>
          </div>}
        </div>}
      </div>
    </section>
  )
}
