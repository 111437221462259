export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  content: [
    {
      mechanics: "Quiz",
      buttonCopy: "Weiter",
      forceSizeBySideMobile: true,
      title: "Wahr oder Falsch – Quiz", // remove later
      mobileTopImg: true,
      type: "button",
      question: "Du kannst 80 Punkte sammeln, wenn einer deiner Freunde deinen Link benutzt.",
      img: require("./img/1.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Wahr"
        },
        {
          ident: "b",
          content: "Falsch"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Weiter",
      type: "button",
      forceSizeBySideMobile: true,
      title: "Wahr oder Falsch – Quiz", // remove later
      mobileTopImg: true,
      question: "Es gibt 3 verschiedene Status Level für Loyalty Mitglieder.",
      img: require("./img/2.jpg"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Wahr"
        },
        {
          ident: "b",
          content: "Falsch"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Weiter",
      type: "button",
      title: "Wahr oder Falsch – Quiz", // remove later
      forceSizeBySideMobile: true,
      mobileTopImg: true,
      question: "Du kannst Punkte nur auf der glo+ Website sammeln.",
      img: require("./img/3.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Wahr"
        },
        {
          ident: "b",
          content: "Falsch"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Weiter",
      type: "button",
      title: "Wahr oder Falsch – Quiz", // remove later
      forceSizeBySideMobile: true,
      mobileTopImg: true,
      question: "Du kannst Punkte für Gutscheine oder Geschenke einlösen.",
      img: require("./img/4.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Wahr"
        },
        {
          ident: "b",
          content: "Falsch"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Weiter",
      type: "button",
      title: "Wahr oder Falsch – Quiz", // remove later
      forceSizeBySideMobile: true,
      mobileTopImg: true,
      question: "Du kannst nur eine Aktivität am Tag abschließen.",
      img: require("./img/5.jpg"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Wahr"
        },
        {
          ident: "b",
          content: "Falsch"
        },
      ]
    }
  ]
}