import React, { useEffect, useRef, useState } from 'react'
import PageHeader from '../../../../components/PageHeader'
import { useSpring, animated } from 'react-spring'
import Button from '../../../../components/Button'
import Air from "../Idents/memory_1/img/air.png"

export default function AirWeight({ questions, currentQuestion, setCurrentQuestion, setAnswers, ident }) {
  const [resetAnimation, setResetAnimation] = useState(false)
  const [started, setStared] = useState(false)
  const [selected, setSelected] = useState(-1)
  const [userSelected, setUserSelected] = useState([])

  const base = useSpring({
    from: { rotate: -5 },
    to: { rotate: started ? 5 : -5 },
    onStart: () => setResetAnimation(false),
    reset: resetAnimation,
    config: { mass: 40, friction: 55, tension: 1000 }
  });

  const bucketRight = useSpring({
    from: { y: -5 },
    to: { y: started ? 5 : -5 },
    reset: resetAnimation,
    config: { mass: 40, friction: 55, tension: 1000 }
  })


  const bucketLeft = useSpring({
    from: { y: 5 },
    to: { y: started ? -5 : 5 },
    reset: resetAnimation,
    config: { mass: 40, friction: 55, tension: 1000 }
  })

  const nextQuestion = () => {
    setAnswers(prev => [...prev, {
      question_ident: `q${currentQuestion}`,
      answer_ident: [userSelected]
    }])
    if (!Object.keys(questions.content).length === currentQuestion) {
      setSelected(-1)
    }
    setCurrentQuestion(prev => prev < questions.content.length - 1 ? ++prev : prev)
  }

  function handleItemClick(index, ident) {
    if (!started) {
      setStared(true)
    }
    setResetAnimation(true)

    setSelected(index);
    let newAnswers = userSelected
    if (newAnswers.includes(ident)) {
      return
    }
    newAnswers.push(ident)
    setUserSelected(newAnswers)
  }

  function hadnleButtonClick() {
    nextQuestion()
  }

  return (
    <section className={`game-page air-weight ${ident}`}>
      <PageHeader>{questions.content[currentQuestion].title ?? "glo+ Quiz"}</PageHeader>
      <div className="wrapper">
        {questions.content[currentQuestion].mobileTopImg && <div className={`mobile-top-img `} style={{ backgroundImage: `url(${questions.staticSideImg ? questions.staticSideImg : questions?.content[currentQuestion]?.img})` }}>
        </div>}
        <div className="game">
          <div className="answers-background">
            <div className="question">
              <h4 dangerouslySetInnerHTML={{ __html: questions.content[currentQuestion].question }} />
            </div>
            <div className="weight">
              <animated.img style={{ ...bucketLeft }} src={Air} alt="" className={selected === -1 ? "air-init" : "air"} />
              <animated.img style={{ ...bucketRight }} src={questions.content[currentQuestion].answers[selected]?.img} alt="" className={selected != -1 ? "place" : ""} />
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -20 198 120">
                <path className='feets' fill="#000"
                  d="M79.3519 85.9291V93.0352H52.3047V85.9291H79.3519Z M145.203 85.9304V93.0365H118.156V85.9304H145.203Z" />
                <animated.path className='right-bucket' fill="#000" style={{ ...bucketRight }}
                  d="M197.508 0.871094C197.508 12.5888 193.469 23.5015 179.539 23.5015H136.887C122.957 23.5015 118.919 12.588 118.919 0.871094H197.508ZM129.656 10.2174C131.248 12.6627 133.949 14.1643 136.879 14.1643H179.532C182.462 14.1643 185.171 12.6627 186.755 10.2174H129.648H129.656Z" />
                <animated.path className='left-bucket' fill="#000" style={{ ...bucketLeft }}
                  d="M78.5896 0.871094C78.5896 12.5888 74.5509 23.5015 60.6209 23.5015H17.9687C4.03869 23.5015 0 12.588 0 0.871094H78.5896ZM10.7373 10.2174C12.3298 12.6627 15.0305 14.1643 17.9609 14.1643H60.6132C63.5436 14.1643 66.2525 12.6627 67.8367 10.2174H10.7289H10.7373Z" />
                <path className='base' fill="#000"
                  d="M35.7078 73.6701H94.0786V28.0143H103.42V73.6701H161.791V83.0081H35.6979V73.6701H35.7078Z" />
                <animated.path className='arm' fill="#000" style={{ ...base }}
                  d="M43.9657 27.2761V32.5606L91.157 32.1419V41.48H39.2943C36.7085 41.48 34.6071 39.3957 34.6071 36.8108V27.2757L43.9657 27.2761ZM106.352 32.1255L153.544 31.748V27.3086H162.893L162.868 36.8438C162.754 39.6009 160.628 41.718 157.812 41.48H106.344V32.1419L106.352 32.1255Z" />
              </svg>
            </div>
            <div className="weight-info">
              <span>75 g</span>
              <span className={!questions.content[currentQuestion].answers[selected]?.weight ? "grayed" : ""}>{questions.content[currentQuestion].answers[selected]?.weight ?? "0 g"}</span>
              <h4>{questions.content[currentQuestion].answers[selected]?.content?.title}</h4>
              <p>{questions.content[currentQuestion].answers[selected]?.content?.desc}</p>
            </div>
            <div className="item-selector">
              {questions.content[currentQuestion]?.answers?.map((e, index) => (
                <button key={index} onClick={() => handleItemClick(index, e.ident)}>
                  <div className={`button-inner ${selected === index ? "selected" : ""}`}>
                    <img src={e.img} alt="" draggable={false} />
                  </div>
                </button>
              ))}
            </div>
            <Button action={hadnleButtonClick} disabled={userSelected.length < 3}>{questions.content[currentQuestion]?.buttonCopy ?? "Nächste Frage"}</Button>
          </div>
        </div>
        <div className={`side-img`} style={{ backgroundImage: `url(${questions.staticSideImg ? questions.staticSideImg : questions?.content[currentQuestion]?.img})` }}></div>
      </div>
    </section>
  )
}
