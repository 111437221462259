export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  customFinishModal: "quiz-finish-modal",
  content: [
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      title: "GEWINNSPIEL QUIZ MIT BONNIE SPECIAL*",
      mobileTopImg: true,
      type: "button",
      question: "Was gehört nicht zu Bonnies Jobs?",
      img: require("./img/1.jpg"),
      correctAnswer: "d",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Model"
        },
        {
          ident: "b",
          content: "Moderation"
        },
        {
          ident: "c",
          content: "Musikern"
        },
        {
          ident: "d",
          content: "Make-Up Artist"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "GEWINNSPIEL QUIZ MIT BONNIE SPECIAL*",
      mobileTopImg: true,
      question: "Wie lang moderiert sie schon unsere Insta-Talkshow Reel Talk?",
      img: require("./img/1.jpg"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Seit 17 Jahren"
        },
        {
          ident: "b",
          content: "Schon immer"
        },
        {
          ident: "c",
          content: "Seit gestern"
        },
        {
          ident: "d",
          content: "Anfang der 90er"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "GEWINNSPIEL QUIZ MIT BONNIE SPECIAL*",
      mobileTopImg: true,
      question: "Welcher Artist war beim Reel Talk dabei?",
      // video: require("./img/3.mp4"),
      img: require("./img/1.jpg"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Schauspielerin Angelina Jolie"
        },
        {
          ident: "b",
          content: "Mulitalent Pati Valpati"
        },
        {
          ident: "c",
          content: "Sängerin Miley Cyrus"
        },
        {
          ident: "d",
          content: "Koch Tim Mälzer"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "GEWINNSPIEL QUIZ MIT BONNIE SPECIAL*",
      mobileTopImg: true,
      question: "Warum ist Bonnie so oft auf Ibiza?",
      img: require("./img/1.jpg"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Weil sie dort lebt"
        },
        {
          ident: "b",
          content: "Sie mag die Insel"
        },
        {
          ident: "c",
          content: "Sie reist gern"
        },
        {
          ident: "d",
          content: "Ist sie gar nicht"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "GEWINNSPIEL QUIZ MIT BONNIE SPECIAL*",
      mobileTopImg: true,
      question: "Wodurch ist die Limited Edition inspiriert?",
      img: require("./img/1.jpg"),
      correctAnswer: "d",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Vom magischen Paris"
        },
        {
          ident: "b",
          content: "Von den idyllischen Alpen"
        },
        {
          ident: "c",
          content: "Vom eisigen Alaska"
        },
        {
          ident: "d",
          content: "Von den sunny Sunsets auf Ibiza"
        },
      ]
    },
  ]
}