import React from 'react'
import Like from "../../../../../img/like.svg"
import { ReactComponent as ShortArrowBlack } from "../../../../../img/short-arrow-black.svg"

export default function CorrectPopup({ visible, setClose, content }) {
  return !visible ? null : (
    <div className="hint-popup correct">
      <div className="dislike">
        <img src={Like} alt="" />
      </div>
      <div className="content">
        {content}
      </div>
      <button className="exit" onClick={setClose}>
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.83398 3.33301L13.1673 12.6663" stroke="#343434" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M13.1673 3.33301L3.83398 12.6663" stroke="#343434" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    </div>
  )
}
