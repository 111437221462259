
import BlueTile from "./img/blue_tile.jpg"
import BrilliantTile from "./img/brilliant_tile.jpg"
import CoralTile from "./img/coral_tile.jpg"
import RoundedTile from "./img/rounded_tile.jpg"
import ScarletTile from "./img/scarlet_tile.jpg"
import Blue from "./img/blue.jpg"
import Brilliant from "./img/brilliant.jpg"
import Coral from "./img/coral.jpg"
import Rounded from "./img/rounded.jpg"
import Scarlet from "./img/scarlet.jpg"
import SideImg from "./img/side-img.jpg"


export const questions = {
  staticSideImg: SideImg,
  content: [
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "THE ARTIST",
      mobileTopImg: true,
      type: "button",
      question: "<span>neo™</span> Rounded tobacco",
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: RoundedTile,
          img: Rounded
        },
        {
          ident: "b",
          content: ScarletTile,
          img: Scarlet
        },
        {
          ident: "c",
          content: CoralTile,
          img: Coral
        },
        {
          ident: "d",
          content: BrilliantTile,
          img: Brilliant
        },
        {
          ident: "e",
          content: BlueTile,
          img: Blue
        },
      ]
    },
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "THE ARTIST",
      mobileTopImg: true,
      type: "button",
      question: "<span>neo™</span> scarlet click",
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: RoundedTile,
          img: Rounded
        },
        {
          ident: "b",
          content: ScarletTile,
          img: Scarlet
        },
        {
          ident: "c",
          content: CoralTile,
          img: Coral
        },
        {
          ident: "d",
          content: BrilliantTile,
          img: Brilliant
        },
        {
          ident: "e",
          content: BlueTile,
          img: Blue
        },
      ]
    },
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "THE ARTIST",
      mobileTopImg: true,
      type: "button",
      question: "<span>neo™</span> Coral click",
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: RoundedTile,
          img: Rounded
        },
        {
          ident: "b",
          content: ScarletTile,
          img: Scarlet
        },
        {
          ident: "c",
          content: CoralTile,
          img: Coral
        },
        {
          ident: "d",
          content: BrilliantTile,
          img: Brilliant
        },
        {
          ident: "e",
          content: BlueTile,
          img: Blue
        },
      ]
    },
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "THE ARTIST",
      mobileTopImg: true,
      type: "button",
      question: "<span>neo™</span> brilliant click",
      correctAnswer: "d",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: RoundedTile,
          img: Rounded
        },
        {
          ident: "b",
          content: ScarletTile,
          img: Scarlet
        },
        {
          ident: "c",
          content: CoralTile,
          img: Coral
        },
        {
          ident: "d",
          content: BrilliantTile,
          img: Brilliant
        },
        {
          ident: "e",
          content: BlueTile,
          img: Blue
        },
      ]
    },
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "THE ARTIST",
      mobileTopImg: true,
      type: "button",
      question: "<span>neo™</span> Blue click",
      correctAnswer: "e",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: RoundedTile,
          img: Rounded
        },
        {
          ident: "b",
          content: ScarletTile,
          img: Scarlet
        },
        {
          ident: "c",
          content: CoralTile,
          img: Coral
        },
        {
          ident: "d",
          content: BrilliantTile,
          img: Brilliant
        },
        {
          ident: "e",
          content: BlueTile,
          img: Blue
        },
      ]
    },
  ]
}