export const questions = {
  content: [
    {
      mechanics: "Wheel",
      buttonCopy: "Spin the wheel",
      title: "Celebrate the 3rd birthday of glo™",
      mobileTopImg: true,
      type: "button",
      question: "Wünsch dir etwas! Drehe das Rad und schnapp dir einen der Gewinne",
      img: require("./img/BG.jpg"),
      correctAnswer: "",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: []
    },
  ]
}