import React, { useState } from 'react'
import { useEffect } from 'react'
import DropdownImg from "../../../img/dropdown-oragne.svg"
import { fetchData, getCookie, getModuleValue } from '../../Helpers'
import Dropdown from "../../components/Dropdown/"
import "./style.scss"
import * as DOMPurify from 'dompurify';
import { showErrorPopup, translateError } from '../../components/ErrorHandling'

export default function TermsTemplate({ uuid }) {
  const [currentQuestion, setCurrentQuestion] = useState({ index: -1, open: false })
  const [content, setContent] = useState({})

  useEffect(() => {
    // async function fetchData() {
    //   const response = await fetch(`${process.env.REACT_APP_API_URL}/document/describe`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${ getCookie("loyalty_sso_session_id") ?? JSON.parse(sessionStorage.getItem('token'))?.uuid}`
    //     },
    //     body: JSON.stringify(
    //       {
    //         document: {
    //           slug: uuid
    //         }
    //       }
    //     )
    //   })
    //   const result = await response.json()
    //   if (result.status.success) {
    //     setContent(result.data)
    //   } else {
    //     showErrorPopup(translateError(result.data.error), result.meta.ts)
    //   }
    // }

    // fetchData()

    const controller = new AbortController()
    fetchData({
      endpoint: "/document/describe",
      payload: {
        document: {
          slug: DOMPurify.sanitize(uuid)
        }
      },
      successCallback: resObj => setContent(resObj.data),
      controller: controller
    })

    return () => {
      controller.abort()
    }

    //eslint-disable-next-line
  }, [])

  const getDate = string => {
    if (!string) {
      return ""
    }
    const date = new Date(string.replace(" ", "T"))
    return date.toLocaleDateString('de-DE', { year: 'numeric', month: "long", day: "numeric" })
  }

  return (
    <section className='terms-template'>
      <h1>{content?.content?.title}</h1>
      <div className="inner">
        {content?.content?.modules?.filter(e => !["intro", "terms_pdf"].includes(e.type)).map((element, index) => (
          <React.Fragment key={index}>

            {element.type === "text" && <div className='paragraph-wrapper standalone' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.custom, { ADD_ATTR: ['target'] }) }}>
            </div>}

            {element.type === "faq" && <Dropdown
              summary={<div className='paragraph-title'>
                <h4>{element.metadata.title}</h4>
                <img src={DropdownImg} alt="" />
              </div>}
              details={<div className="paragraph-wrapper" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.custom, { ADD_ATTR: ['target'] }) }} />}
              setOpen={() => setCurrentQuestion(prev => ({ index: index, open: prev.index !== index ? true : !prev.open }))}
              open={currentQuestion.index === index && currentQuestion.open}
            />}

          </React.Fragment>
        ))}
      </div>
      <div className="info">
        <p>Last updated: {getDate(content?.updated?.date)}</p>
        {getModuleValue(content?.content?.modules, "terms_pdf", "file.url") && <a href={getModuleValue(content?.content?.modules, "terms_pdf", "file.url")} target="_blank" rel="noreferrer">Hier AGB als PDF-Datei</a>}
      </div>
    </section>
  )
}
