import React, { useEffect, useState } from "react"
import useCountDown from 'react-countdown-hook';
import { useNavigate } from "react-router-dom";
import { useUserDispatch, useUserState, logout, refreshToken } from '../../UserContext'
import "./style.scss"

const Timer = () => {
  const [timeLeft, { start }] = useCountDown(30000, 1000);

  useEffect(() => {
    start();

    //eslint-disable-next-line
  }, []);

  return (
    <p>Session will expire in: <span>{(timeLeft / 1000).toFixed(0)}</span> seconds.</p>
  )
}

export default function LogoutWarning () {
  // const navigate = useNavigate()
  const token = JSON.parse(sessionStorage.getItem("token"))
  const dispatch = useUserDispatch()
  const [logoutWarning, setLogoutWarning] = useState(false)
  const navigate = useNavigate()

  const handleLogout = () => {
    navigate("/logged-out", { replace: true })
    logout(dispatch)
  }

  let tokenTimer = null
  // let warningTimer = null
  let timeTimer = null

  const checkToken = () => {
    const tokenDate = token?.expires?.timestamp || false
    if(tokenDate) {
      const tokenValidTo = new Date(tokenDate).getTime()*1000
      const now = new Date().getTime()
      const tokenExpiresIn = parseInt((tokenValidTo - now))

      tokenTimer = setTimeout(() => {
        handleLogout()
      }, tokenExpiresIn-5000)

      tokenTimer = setTimeout(() => {
          setLogoutWarning(true)
      }, tokenExpiresIn-35000)
    } else {
      clearTimeout(tokenTimer)
      clearInterval(timeTimer)
      setLogoutWarning(false)
    }
  }

  useEffect(() => {
    checkToken()
    // eslint-disable-next-line
  }, [token?.uuid])

  return (
    <>
      { logoutWarning ? <div className="logout-warning"><Timer/></div> : null }
    </>
  )
}
