import SideImage from "./img/side-image.webp"
import BlackTile from "./img/tile-black.webp"
import BlueTile from "./img/tile-blue.webp"
import PinkTile from "./img/tile-pink.webp"
import TemplateMobile from "./img/template-mobile.webp"
import TemplateDesktop from "./img/template-desktop.webp"
import BlackDevice from "./img/device-black.webp"
import BlueDevice from "./img/device-blue.webp"
import PinkDevice from "./img/device-pink.webp"

export const questions = {
  staticSideImg: SideImage,
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/heat-not-burn/",
    copy: "ZUM ONLINESHOP"
  },
  content: [
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "Entdecke alle Features des neuen glo™ Hyper Pro!",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: false,
      modalData: {
        highlited: "Wir präsentieren:",
        title: "Das innovative EasyView™ Display ",
        desc: "Halte den Überblick mit dem innovativen EasyView™ Display: Sieh sofort deinen Akkustand, die Aufheizzeit, wie lange du noch nutzen kannst und welchen Modus du gewählt hast.",
        img: BlackDevice
      },
      question: "Wische mit dem Zeigefinger über die Flächen und enthülle die neuen Funktionen glo™ Hyper Pro",
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      templateMobile: TemplateMobile,
      templateDesktop: TemplateDesktop,
      answers: [
        {
          ident: "a",
          content: BlackTile,
          img: BlackDevice
        },
      ]
    },
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "Entdecke alle Features des neuen glo™ Hyper Pro!",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: false,
      modalData: {
        highlited: "Wir präsentieren:",
        title: "Die HeatBoost™ Technology ",
        desc: "Für einen noch intensiveren Geschmack*. Im Boost-Modus erwartet dich ein authentisches und volles Geschmackserlebnis.",
        more: "*Im Vergleich zum glo™ Hyper X2 Standard-Modus. Dieses Produkt ist nicht risikofrei und enthält Nikotin, eine abhängig machende Substanz.",
        img: BlueDevice
      },
      question: "Wische mit dem Zeigefinger über die Flächen und enthülle die neuen Funktionen glo™ Hyper Pro",
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      templateMobile: TemplateMobile,
      templateDesktop: TemplateDesktop,
      answers: [
        {
          ident: "b",
          content: BlueTile,
          img: BlueDevice
        },
      ]
    },
    {
      mechanics: "Scratch",
      buttonCopy: "Nächste",
      title: "Entdecke alle Features des neuen glo™ Hyper Pro!",
      mobileTopImg: true,
      type: "button",
      showModalOnCorrect: false,
      modalData: {
        highlited: "Wir präsentieren:",
        title: "Der TasteSelect™ Button",
        desc: "Für noch intensiveren Geschmack* sorgt der 4 Minuten lange Boost-Modus – einfach den TasteSelect™ Regler auf Boost einstellen und schon glo‘st du deutlich intensiver.",
        img: PinkDevice
      },
      question: "Wische mit dem Zeigefinger über die Flächen und enthülle die neuen Funktionen glo™ Hyper Pro",
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      templateMobile: TemplateMobile,
      templateDesktop: TemplateDesktop,
      answers: [
        {
          ident: "c",
          content: PinkTile,
          img: PinkDevice
        },
      ]
    },
  ]
}