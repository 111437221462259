export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  // disableAutoSubmit: true,
  onSuccessCustomTitle: {
    copy: "Danke für die Antworten!"
  },
  customFinishModal: "correct-answer",
  finishImg: require("./img/main.png"),
  content: [
    {
      mechanics: "Poll",
      buttonCopy: "Nächste Frage",
      title: "Reel Talk by glo™ - Umfrage",
      mobileTopImg: true,
      type: "oval",
      question: `Hast du schon einmal von der Talk- und Gameshow "Reel Talk by glo™" auf Instagram gehört?`,
      img: require("./img/main.png"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: 0,
          content: "Ja"
        },
        {
          ident: 1,
          content: "Nein"
        },
      ]
    },
    {
      mechanics: "Poll",
      buttonCopy: "Nächste Frage",
      type: "oval",
      title: "Reel Talk by glo™ - Umfrage",
      mobileTopImg: true,
      question: `Wie oft hast du bereits eine Folge von "Reel Talk by glo™" auf Instagram angesehen?`,
      img: require("./img/main.png"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: 0,
          content: "Ich habe mehr als fünf Folgen gesehen"
        },
        {
          ident: 1,
          content: "Ich habe ein paar Folgen gesehen"
        },
        {
          ident: 2,
          content: "Ich habe ein oder zwei Folgen gesehen"
        },
      ]
    },
    {
      mechanics: "Poll",
      buttonCopy: "Nächste Frage",
      type: "oval",
      title: "Reel Talk by glo™ - Umfrage",
      mobileTopImg: true,
      question: `Wie gefällt dir das Format "Reel Talk by glo™" auf Instagram?`,
      img: require("./img/main.png"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: 0,
          content: "Gefällt mir sehr gut"
        },
        {
          ident: 1,
          content: "Gefällt mir gut"
        },
        {
          ident: 2,
          content: "Gefällt mir weniger gut"
        },
        {
          ident: 3,
          content: "Gefällt mir gar nicht"
        },
      ]
    },
    {
      mechanics: "QuizMultiSelect",
      buttonCopy: "Nächste Frage",
      type: "square",
      title: "Reel Talk by glo™ - Umfrage",
      mobileTopImg: true,
      question: `Was hat dir besonders gut am Format "Reel Talk by glo™" auf Instagram gefallen? Bitte wähle 1 bis 3 Attribute.`,
      img: require("./img/main.png"),
      correctAnswers: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i"
      ],
      wrongAnswerInfo: {
        type: "basic",
      },
      maxAnswers: 3,
      answers: [
        {
          ident: "a",
          content: "Interessante Gesprächsthemen"
        },
        {
          ident: "b",
          content: "Produktintegration (Hyper X2/Air)"
        },
        {
          ident: "c",
          content: "Die Gäste"
        },
        {
          ident: "d",
          content: "Interaktiver Content/Games in der Show"
        },
        {
          ident: "e",
          content: "Gewinnspiele"
        },
        {
          ident: "f",
          content: "(auditive & visuelle) Qualität des Formats"
        },
        {
          ident: "g",
          content: "Bonnie Strange als Host"
        },
        {
          ident: "h",
          content: "Talkshow in Form von Reels"
        },
        {
          ident: "i",
          content: "Das Format hat mir gar nicht gefallen."
        },
      ]
    },
    {
      mechanics: "QuizMultiSelect",
      buttonCopy: "Nächste Frage",
      type: "square",
      title: "Reel Talk by glo™ - Umfrage",
      mobileTopImg: true,
      question: `Was hat dir nicht gut am Format "Reel Talk by glo™" auf Instagram gefallen? Bitte wähle 1 bis 3 Attribute.
      `,
      img: require("./img/main.png"),
      correctAnswers: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
      ],
      wrongAnswerInfo: {
        type: "basic",
      },
      maxAnswers: 3,
      answers: [
        {
          ident: "a",
          content: "Interessante Gesprächsthemen"
        },
        {
          ident: "b",
          content: "Produktintegration (Hyper X2/Air)"
        },
        {
          ident: "c",
          content: "Die Gäste"
        },
        {
          ident: "d",
          content: "Interaktiver Content/Games in der Show"
        },
        {
          ident: "e",
          content: "Gewinnspiele"
        },
        {
          ident: "f",
          content: "(auditive & visuelle) Qualität des Formats"
        },
        {
          ident: "g",
          content: "Bonnie Strange als Host"
        },
        {
          ident: "h",
          content: "Talkshow in Form von Reels"
        },
        {
          ident: "i",
          content: "Mir hat alles gut gefallen"
        },
      ]
    },
    {
      mechanics: "Poll",
      buttonCopy: "Nächste Frage",
      type: "oval",
      title: "Reel Talk by glo™ - Umfrage",
      mobileTopImg: true,
      question: `Wie bist auf "Reel Talk by glo™" aufmerksam geworden?`,
      img: require("./img/main.png"),
      correctAnswers: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
      ],
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: 0,
          content: "Durch Empfehlungen von Freunden oder Bekannten"
        },
        {
          ident: 1,
          content: "Durch die Gäste"
        },
        {
          ident: 2,
          content: "dem Host"
        },
        {
          ident: 3,
          content: "Wurde in meine Reels reingespielt"
        },
        {
          ident: 4,
          content: "Durch Social Media generell"
        },
        {
          ident: 5,
          content: "Durch (Online/Lifestyle) Magazin"
        },
        {
          ident: 6,
          content: "Durch Festivals und Veranstaltungen"
        },
        {
          ident: 7,
          content: "Durch das Loyaltyprogramm glo™+"
        },
        {
          ident: 8,
          content: "Durch die discoverglo™.com - glo™ Webseite"
        },
        {
          ident: 9,
          content: "Andere:",
          textArea: "",
        },
      ]
    },
    {
      mechanics: "QuizMultiSelect",
      buttonCopy: "Nächste Frage",
      type: "square",
      title: "Reel Talk by glo™ - Umfrage",
      mobileTopImg: true,
      question: `Welche Art von Inhalten würdest du gerne auf glo™ Instagram sehen?`,
      img: require("./img/main.png"),
      correctAnswers: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
      ],
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "Interviews mit Künstler:innen"
        },
        {
          ident: "b",
          content: "Gameshow-Elemente"
        },
        {
          ident: "c",
          content: "Behind-the-Scenes-Einblicke"
        },
        {
          ident: "d",
          content: "Channel Takeover der Künsler:innen"
        },
        {
          ident: "e",
          content: "Gewinnspiele"
        },
        {
          ident: "f",
          content: "Aktionen zum Mitwirken"
        },
        {
          ident: "g",
          content: "Events und Festivals"
        },
        {
          ident: "h",
          content: "Produktinhalte"
        },
        {
          ident: "i",
          content: "Private Einblicke der Stars"
        },
        {
          ident: "j",
          content: "Was ganz anderes, und zwar:",
          textArea: "",
        },
      ]
    },
  ]
}