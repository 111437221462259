import Tile0 from "./img/tile0.jpg"
import Tile1 from "./img/tile1.jpg"
import Tile2 from "./img/tile2.jpg"
import Tile3 from "./img/tile3.jpg"
import Tile4 from "./img/tile4.jpg"
import Tile5 from "./img/tile5.jpg"
import Tile6 from "./img/tile6.jpg"
import Tile7 from "./img/tile7.jpg"
import Tile8 from "./img/tile8.jpg"


export const questions = {
  onSuccessCustomLink: {
    outsideLink: "https://www.discoverglo.com/de/de/glo-heater/glo-hyper-pro?utm_source=Glo%2B&utm_medium=Activity&utm_campaign=hyperpro#obsidian-black",
    copy: "Jetzt Bestsellen",
    mainInfo: "Der neue glo™ Hyper Pro bietet dir dank der verbesserten HeatBoost™ Technology und dem innovativen EasyView™ Display ein deutlich besseres Geschmackserlebnis ab dem ersten Zug.*",
    info: "*Im Vergleich zum glo™ Hyper X2 Standard-Modus. Dieses Produkt ist nicht risikofrei und enthält Nikotin, eine abhängig machende Substanz.",
  },
  content: [
    {
      mechanics: "Puzzle",
      title: "Puzzeln mit Pro",
      question: "Du kannst immer zwei horizontal oder vertikal benachbarte Teile auswählen. Diese werden dann vertauscht. ",
      correctAnswers: [
        "0885721d-8c22-49d6-8cd8-bac53db6f7f9",
        "d4c5ef33-7645-493a-b84d-4d6e03a4b98f",
        "c887b1a8-a59b-4dee-87bc-73929aeedb69",
        "749fc1f4-ae67-4c83-b317-e4a6b4fb4bbb",
        "59f068ed-eb6a-4c09-981b-81a6edf87da0",
        "b17d7d1b-ed47-4db2-a565-2fb2710a74c6",
        "4bbd6deb-9753-4866-b51d-5fa275fc21e6",
        "cb6e6258-d3eb-4f13-8f8e-69a533d759f9",
        "97f8c403-13ee-415d-9a49-e9e30d570e21"
      ],
      answers: [
        {
          ident: "d4c5ef33-7645-493a-b84d-4d6e03a4b98f",
          img: Tile1
        },
        {
          ident: "97f8c403-13ee-415d-9a49-e9e30d570e21",
          img: Tile8
        },
        {
          ident: "0885721d-8c22-49d6-8cd8-bac53db6f7f9",
          img: Tile0
        },
        {
          ident: "749fc1f4-ae67-4c83-b317-e4a6b4fb4bbb",
          img: Tile3
        },
        {
          ident: "cb6e6258-d3eb-4f13-8f8e-69a533d759f9",
          img: Tile7
        },
        {
          ident: "59f068ed-eb6a-4c09-981b-81a6edf87da0",
          img: Tile4
        },
        {
          ident: "c887b1a8-a59b-4dee-87bc-73929aeedb69",
          img: Tile2
        },
        {
          ident: "b17d7d1b-ed47-4db2-a565-2fb2710a74c6",
          img: Tile5
        },
        {
          ident: "4bbd6deb-9753-4866-b51d-5fa275fc21e6",
          img: Tile6
        },
      ]
    },
  ]
}

