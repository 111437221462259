import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import NiceModal from '@ebay/nice-modal-react';
import Quiz from "./Mechanics/Quiz"
import Find from './Mechanics/Find';
import Puzzle from './Mechanics/Puzzle';
import Newsletter from './Mechanics/Newsletter';
import DOMPurify from 'dompurify';
import { getUserDetails, useUserDispatch } from '../../../UserContext';
import { fetchData, sendEvent } from '../../../Helpers';
import InstagramFollow from './Mechanics/InstagramFollow';
import Poll from './Mechanics/Poll';
import Countdown from './Mechanics/Countdown';
import { useNavigate } from "react-router-dom";
import { disableIntroIn } from '..';
import Scratch from './Mechanics/Scratch';
import AirWeight from './Mechanics/AirWeight';
import MeetAir from './Mechanics/MeetAir';
import Wheel from "./Mechanics/Wheel"
import QuizMultiSelect from './Mechanics/QuizMultiSelect';
import { Calendar } from './Mechanics/Calendar';
import Meet from './Mechanics/Meet';
import Pick from './Mechanics/Pick';


export default function GameProvider({ notificationRef }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [questions, setQuestions] = useState({})
  const [answers, setAnswers] = useState([])
  const { ident } = useParams();
  const dispatch = useUserDispatch()
  const [content, setContent] = useState(state)

  useEffect(() => {
    const controller = new AbortController()

    if (!(ident.includes("local") || state)) {
      fetchData({
        endpoint: "/activation/describe",
        payload: {
          activation: {
            ident: DOMPurify.sanitize(ident),
            withSolutions: false
          }
        },
        successCallback: resObj => { setContent(resObj.data) },
        controller: controller
      })
    }


    if ((content?.completed ?? true) && !disableIntroIn.includes(ident)) {
      if (ident.includes("advent_2023")) {
        return
      }
      navigate(`/aufgaben/${DOMPurify.sanitize(ident)}`, { replace: true })
    }


    const requireData = async () => {
      let data = await require(`./Idents/${DOMPurify.sanitize(ident)}/questions.js`)
      setQuestions(Object.values({ ...data })[0])
    }

    requireData()

    return () => {
      controller.abort()
    }
  }, [])

  const sendAnswers = async ({ showModal = true, customSuccessCallback = false } = {}) => {
    fetchData({
      endpoint: "/activation/solution/create",
      payload: {
        "activation": {
          "ident": DOMPurify.sanitize(ident),
          "payload": answers
        }
      },
      successCallback: resObj => {
        getUserDetails(dispatch)
        notificationRef.current = true
        sendEvent(`${ident}_FINISH`)
        if (showModal) {
          NiceModal.show(questions.customFinishModal ?? 'correct-answer', { payout: resObj?.data?.payout, shouldNavigate: true, ...(questions.onSuccessCustomLink && { customLink: questions.onSuccessCustomLink, }), ...(questions.onSuccessCustomTitle && { customTitle: questions.onSuccessCustomTitle }) })
        }
        if (customSuccessCallback) {
          customSuccessCallback()
        }
      },
    })
  }

  useEffect(() => {

    const requireData = async () => {
      let data = await require(`./Idents/${DOMPurify.sanitize(ident)}/questions.js`)
      setQuestions(Object.values({ ...data })[0])
    }

    requireData()
    if (ident === "quiz_9" && answers.length === 8) {
      sendAnswers()
    } else {
      if (questions.content && Object.keys(questions.content).length === answers.length && !questions.disableAutoSubmit) {
        sendAnswers()
        console.log("sendAnswers")
      }
    }
  }, [answers])



  return (
    <>
      {questions?.content?.[currentQuestion].mechanics === "Quiz" && <Quiz questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} setAnswers={setAnswers}
        ident={ident} />}

      {questions?.content?.[currentQuestion].mechanics === "Find" && <Find questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} setAnswers={setAnswers}
        ident={ident} />}

      {questions?.content?.[currentQuestion].mechanics === "Puzzle" && <Puzzle questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} setAnswers={setAnswers}
        ident={ident} />}

      {questions?.content?.[currentQuestion].mechanics === "Newsletter" && <Newsletter questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} setAnswers={setAnswers}
        ident={ident} notificationRef={notificationRef} />}

      {questions?.content?.[currentQuestion].mechanics === "InstagramFollow" && <InstagramFollow questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} setAnswers={setAnswers}
        ident={ident} notificationRef={notificationRef} content={content} />}

      {questions?.content?.[currentQuestion].mechanics === "Countdown" && <Countdown questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} setAnswers={setAnswers}
        ident={ident} />}

      {questions?.content?.[currentQuestion].mechanics === "Poll" && <Poll questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} answers={answers} setAnswers={setAnswers}
        ident={ident} sendAnswers={sendAnswers} />}

      {questions?.content?.[currentQuestion].mechanics === "Scratch" && <Scratch questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} answers={answers} setAnswers={setAnswers}
        ident={ident} sendAnswers={sendAnswers} />}

      {questions?.content?.[currentQuestion].mechanics === "AirWeight" && <AirWeight questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} answers={answers} setAnswers={setAnswers}
        ident={ident} sendAnswers={sendAnswers} />}

      {questions?.content?.[currentQuestion].mechanics === "MeetAir" && <MeetAir questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} answers={answers} setAnswers={setAnswers}
        ident={ident} sendAnswers={sendAnswers} />}

      {questions?.content?.[currentQuestion].mechanics === "Pick" && <Pick questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} answers={answers} setAnswers={setAnswers}
        ident={ident} sendAnswers={sendAnswers} />}

      {questions?.content?.[currentQuestion].mechanics === "Wheel" && <Wheel questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} answers={answers} setAnswers={setAnswers}
        ident={ident} sendAnswers={sendAnswers} notificationRef={notificationRef} />}

      {questions?.content?.[currentQuestion].mechanics === "QuizMultiSelect" && <QuizMultiSelect questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} setAnswers={setAnswers} answers={answers}
        ident={ident} />}
      {questions?.content?.[currentQuestion].mechanics === "Calendar" && <Calendar questions={questions}
        currentQuestion={currentQuestion}
        ident={ident} />}
      {questions?.content?.[currentQuestion].mechanics === "Meet" && <Meet questions={questions}
        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} answers={answers} setAnswers={setAnswers}
        ident={ident} sendAnswers={sendAnswers} />}
    </>
  )
}
