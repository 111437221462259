import ImgMobile from "./img/OMR-desktop.jpg"
import ImgDesktop from "./img/OMR-desktop.jpg"

export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  content: [
    {
      mechanics: "Countdown",
      title: "OMR-QUIZ",
      mobileTopImg: true,
      imgMobile: ImgMobile,
      imgDesktop: ImgDesktop,
      header: "YOU CAN'T MISS THIS EVENT",
      desc: "Das Quiz geht am 27.03. um 15:00 Uhr online.",
      date: new Date(1679922000000),
      navigateTo: "/aufgaben/poll_1"
    },
  ]
}