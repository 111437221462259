import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../../components/Button'
import PageHeader from '../../../../components/PageHeader'
import wheel from "../Idents/wheel_1/img/wheel.svg";
import indicator from "../Idents/wheel_1/img/indicator.svg";
import shade from "../Idents/wheel_1/img/shade.svg";
import logo from "../Idents/wheel_1/img/logo.svg";
import reward from "../Idents/wheel_1/img/reward.svg"
import { fetchData, sendEvent } from '../../../../Helpers';
import DOMPurify from 'dompurify';
import { getUserDetails, useUserDispatch } from '../../../../UserContext';
import { useNavigate } from 'react-router';

export default function Wheel({ questions, currentQuestion, setCurrentQuestion, ident, notificationRef }) {
  // const [deg, setDeg] = useState(0);
  const deg = useRef(0)
  const [spin, setSpin] = useState(false);
  const spinNumberBeforeStart = 3;
  const wholeSpin = 360 * spinNumberBeforeStart;
  const [showAwards, setShowAwards] = useState("");
  const singlePrizeDeg = 30;
  const style = {
    transform: `rotate(${deg.current}deg)`,
    transition: "all 4s cubic-bezier(.42,0,.44,1.05)",
  };
  const dispatch = useUserDispatch()
  const [content, setContent] = useState("")
  const [currentStep, setCurrentStep] = useState(0)
  const navigate = useNavigate();

  const sendAnswers = async () => {
    fetchData({
      endpoint: "/activation/solution/create",
      payload: {
        "activation": {
          "ident": DOMPurify.sanitize(ident),
          "payload": ""
        }
      },
      successCallback: resObj => {
        notificationRef.current = true
        sendEvent(`${ident}_FINISH`)
        setContent(resObj)
        const newPrizeNumber = rewardsNumber[resObj?.data?.answers?.ident || resObj?.data?.answers?.award[0].catalogue?.ident];
        setShowAwards(resObj?.data?.answers?.ident || resObj?.data?.answers?.award[0].catalogue?.ident)
        handleStartSpine(newPrizeNumber);
        setTimeout(() => {
          getUserDetails(dispatch);
        }, 1000);
      },
    })
  }

  const rewardsNumber = {
    wheel_x2air: 3,
    wheel_spotify: 4,
    wheel_wine: 1,
    wheel_chili: 0,
    wheel_points10: 2
  }

  const handleStartSpine = (prizeNumber) => {
    const needToSpin =
      prizeNumber === 0 ? wholeSpin : wholeSpin + prizeNumber * singlePrizeDeg;
    deg.current = needToSpin;
    setSpin(true);
  };

  const handleFinishSpin = () => {
    setSpin(false);
    setTimeout(() => {
      setCurrentStep(1);
    }, 1000);
  };

  const hadnleButtonClick = () => {
    if (currentStep === 0) {
      sendAnswers()
      return
    } else {
      navigate(`/aufgaben/`, { replace: true })
    }
  }

  const rewards = {
    wheel_x2air: "1 Glo™ Hyper X2 AIR KIT",
    wheel_spotify: "10€ Spotify Gutschein",
    wheel_wine: "10€ Weinbörse Geschenkgutschein",
    wheel_chili: "Chili - 1 Film Gutschein",
    wheel_points10: "10 Punkte"
  }
  const Reward = rewards[content?.data?.answers?.ident || content?.data?.answers?.award[0].catalogue?.ident]

  return (
    <section className={`game-page wheel ${ident}`}>
      <PageHeader>{questions.content[currentQuestion].title ?? "glo+ Wheel"}</PageHeader>
      <div className="wrapper">
        {questions.content[currentQuestion].mobileTopImg && <div className={`mobile-top-img  ${questions.content[currentQuestion]?.video ? "video-wrapper" : ""}`} style={{ backgroundImage: `url(${questions?.content[currentQuestion]?.img})` }}>
          {Boolean(questions.content[currentQuestion]?.imgText) && <p style={{ color: questions.content[currentQuestion].imgText.color }}>
            {questions.content[currentQuestion].imgText.text}
          </p>}
        </div>}
        <div className="game">
          <div className="desktop">
            <div className={currentStep === 0 ? "question" : "question2"}>
              <h4>{currentStep === 0 ? questions.content[currentQuestion].question : "Glückwunsch!"}</h4>
            </div>
            {currentStep === 0 ?
              <div className="wheel">
                <img src={shade} alt='shade' className="shade" />
                <img
                  className={spin ? "wheel-image spin" : "wheel-image"}
                  onTransitionEnd={() => {
                    handleFinishSpin();
                  }}
                  style={style}
                  src={wheel}
                  alt="koło"
                />
                <img src={logo} alt='logo' className="logo" />
                <div className={spin ? "indicator active" : "indicator"}>
                  <img src={indicator} alt="wskaźnik" />
                </div>
              </div>
              :
              <div className="reward">
                <img src={reward} alt='reward' className="reward" />
                <h5>Du hast gewonnen: </h5>
                <h6>{Reward}</h6>
                {showAwards === "wheel_points10" ? "" : <p>Dein Gutschein findest du im Prämienshop unter “Eingelöst”</p>}
              </div>}
          </div>
          {questions.content[currentQuestion].mobileQuestionImg && <div className="mobile-question-img"
            style={{ backgroundImage: `url(${questions.content[currentQuestion].img})` }}>
            {Boolean(questions.content[currentQuestion]?.imgText) && <p style={{ color: questions.content[currentQuestion].imgText.color }}>
              {questions.content[currentQuestion].imgText.text}
            </p>}
          </div>}
          <Button action={hadnleButtonClick} >{currentStep === 0 ? questions.content[currentQuestion]?.buttonCopy : "Fertig!"}</Button>
        </div>
        <div className={`side-img ${questions.content[currentQuestion]?.video ? "video-wrapper" : ""}`} style={{ backgroundImage: `url(${questions?.content[currentQuestion]?.img})` }}>
          {Boolean(questions.content[currentQuestion]?.imgText) && <p style={{ color: questions.content[currentQuestion].imgText.color }}>
            {questions.content[currentQuestion].imgText.text}
          </p>}
        </div>
      </div>
    </section>
  )
}
