export const questions = {
  // global gameData
  // eg. static side img flag, img,
  // disable re renders in games that questions cannot be mixed
  content: [
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      title: "glo™ SPRING QUIZ",
      mobileTopImg: true,
      type: "button",
      question: "Welche neo™ stick Sorte ist das?",
      img: require("./img/1.webp"),
      correctAnswer: "b",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "neo™ Rounded Tobacco"
        },
        {
          ident: "b",
          content: "neo™ Scarlet Click"
        },
        {
          ident: "c",
          content: "neo™ Coral Click"
        },
        {
          ident: "d",
          content: "neo™ Red Click"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ SPRING QUIZ",
      mobileTopImg: true,
      question: "Welche neo™ stick Sorte ist das?",
      img: require("./img/2.webp"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "neo™ Vibrant Velvet Click"
        },
        {
          ident: "b",
          content: "neo™ Brilliant Click"
        },
        {
          ident: "c",
          content: "neo™ Coral Click"
        },
        {
          ident: "d",
          content: "neo™ Gold Tobacco"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ SPRING QUIZ",
      mobileTopImg: true,
      question: "Welche neo™ stick Sorte ist das?",
      img: require("./img/3.webp"),
      correctAnswer: "a",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "neo™ Green Click"
        },
        {
          ident: "b",
          content: "neo™ Blue Click"
        },
        {
          ident: "c",
          content: "neo™ Brilliant Click"
        },
        {
          ident: "d",
          content: "neo™ Summer Yellow Click"
        },
      ]
    },
    {
      mechanics: "Quiz",
      buttonCopy: "Nächste Frage",
      type: "button",
      title: "glo™ SPRING QUIZ",
      mobileTopImg: true,
      question: "Welche neo™ stick Sorte ist das?",
      img: require("./img/4.webp"),
      correctAnswer: "c",
      wrongAnswerInfo: {
        type: "basic",
      },
      answers: [
        {
          ident: "a",
          content: "neo™ Blue Click"
        },
        {
          ident: "b",
          content: "neo™ Vibrant Velvet Click"
        },
        {
          ident: "c",
          content: "neo™ Brilliant Click"
        },
        {
          ident: "d",
          content: "neo™ Coral Click"
        },
      ]
    },
  ]
}