import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../../components/Button'
import PageHeader from '../../../../components/PageHeader'
import CorrectPopup from './CorrectPopup'
import HintPopup from './HintPopup'

export default function QuizMultiSelect({ questions, currentQuestion, setCurrentQuestion, setAnswers, answers, ident }) {
  const [selected, setSelected] = useState([])
  const [hint, setHint] = useState(false)
  const [correctPopup, setCorrectPopup] = useState(false)
  const videoRef = useRef(null)
  const videoRefMobile = useRef(null)
  const [textAreaValue, setTextAreaValue] = useState('')
  const [terms, setTerms] = useState(false)
  const [terms2, setTerms2] = useState(false)
  const [textAreaSelected, setTextAreaSelected] = useState(false);

  function handleTextAreaChange(event) {
    const inputValue = event.target.value;
    if (textAreaSelected) {
      let newAnswers = [...selected];
      newAnswers = newAnswers.filter(answer => !answer.startsWith('*'));
      newAnswers.push(`*${inputValue}`);
      setSelected([...newAnswers]);
    }
    setTextAreaValue(inputValue.length > 300 ? inputValue.slice(0, 300) : inputValue);
  }

  function handleTextAreaSelect(index) {
    let newAnswers = [...selected];

    if (!textAreaSelected) {
      newAnswers = newAnswers.filter(answer => !answer.startsWith('*'));
      newAnswers.push(`*${textAreaValue}`);
    } else {
      newAnswers = newAnswers.filter(answer => !answer.startsWith('*'));
    }

    setSelected([...newAnswers]);
  }

  console.log(textAreaValue)

  const nextQuestion = () => {
    setAnswers(prev => [...prev, {
      question_ident: `q${currentQuestion + 1}`,
      answer_ident: selected
    }])
    setSelected([])
    setCurrentQuestion(prev => prev < questions.content.length - 1 ? ++prev : prev)
    console.log("hit")
  }

  const hadnleButtonClick = () => {
    if (ident.includes("poll")) {
      nextQuestion()
      return
    }
    if (!compareArrays(selected, questions.content[currentQuestion]?.correctAnswers)) {
      setHint(true)
      return
    }
    if (questions.content[currentQuestion]?.correctAnswerInfo?.content) {
      setCorrectPopup(true)
    } else {
      nextQuestion()
    }
  }


  function handleMultiSelect(index) {
    let newAnswers = [...selected];

    if (newAnswers.includes(questions.content[currentQuestion].answers[index].ident)) {
      const indexArray = newAnswers.indexOf(questions.content[currentQuestion].answers[index].ident);
      newAnswers.splice(indexArray, 1);
      setSelected([...newAnswers]);
      return;
    }
    newAnswers.push(questions.content[currentQuestion].answers[index].ident);
    setSelected([...newAnswers.sort((a, b) => a.localeCompare(b))]);
  }


  function compareArrays(a, b) {
    return a.length === b.length &&
      a.every((element, index) => element === b[index]);
  }

  useEffect(() => {
    videoRef.current?.load()
    videoRefMobile.current?.load()
  }, [questions.content[currentQuestion]?.video])

  console.log(answers)
  console.log(selected)

  return (
    <section className={`game-page quiz ${ident}`}>
      <PageHeader>{questions.content[currentQuestion].title ?? "glo+ Quiz"}</PageHeader>
      <div className="wrapper">
        {questions.content[currentQuestion].mobileTopImg && <div className={`mobile-top-img  ${questions.content[currentQuestion]?.video ? "video-wrapper" : ""}`} style={{ backgroundImage: `url(${questions?.content[currentQuestion]?.img})` }}>
          {Boolean(questions.content[currentQuestion]?.video) && <video controls={true} controlsList="nodownload" name="media" ref={videoRefMobile}>
            <source src={questions.content[currentQuestion]?.video} type="video/mp4" />
          </video>}
          {Boolean(questions.content[currentQuestion]?.imgText) && <p style={{ color: questions.content[currentQuestion].imgText.color }}>
            {questions.content[currentQuestion].imgText.text}
          </p>}
        </div>}
        <div className="game">
          <div className="progress">
            {Array(questions.content.length).fill(null).map((_, index) => (
              <div className={`number ${currentQuestion >= index ? "active" : ""}`} key={index}>
                <div><p>{++index}</p></div>
              </div>
            ))}
          </div>
          <div className="question">
            <h4>{questions.content[currentQuestion].question}</h4>
          </div>
          {questions.content[currentQuestion].mobileQuestionImg && <div className="mobile-question-img"
            style={{ backgroundImage: `url(${questions.content[currentQuestion].img})` }}>
            {Boolean(questions.content[currentQuestion]?.imgText) && <p style={{ color: questions.content[currentQuestion].imgText.color }}>
              {questions.content[currentQuestion].imgText.text}
            </p>}
          </div>}
          <div className="answers-background">
            <div className={`answers ${questions.content[currentQuestion].type} ${questions.content[currentQuestion]?.forceSizeBySideMobile ? "side-by-side" : ""}`}>
              {questions.content[currentQuestion].answers.map((element, index) => (
                ["button", "image", "button-image", "square"].includes(questions.content[currentQuestion].type) &&
                <React.Fragment key={index}>
                  {element.textArea !== undefined && element.textArea !== null ? (
                    <div className='poll-text-area'>
                      <button onClick={() => { setTextAreaSelected(!textAreaSelected); handleTextAreaSelect(index) }} className={textAreaSelected ? "selected" : ""} key={index} disabled={hint}>
                        <div className="inner">
                          {"button" === questions.content[currentQuestion].type &&
                            <p>{element.content}</p>}
                          {"square" === questions.content[currentQuestion].type && (
                            <>
                              <p><span className="square-inner" />{element.content}</p>
                            </>
                          )}
                          {"button-image" === questions.content[currentQuestion].type &&
                            <div className='button-image-wrapper'>
                              <img src={element.img} alt="" />
                              <p>{element.content}</p>
                            </div>}
                        </div>
                      </button>
                      <textarea className='text-area' disabled={!textAreaSelected} value={textAreaValue}
                        onChange={handleTextAreaChange}></textarea>
                    </div>
                  ) : (<button onClick={() => handleMultiSelect(index)} className={selected.includes(element.ident) ? "selected" : ""} key={index} disabled={hint}>
                    <div className="inner">
                      {"button" === questions.content[currentQuestion].type &&
                        <p>{element.content}</p>}
                      {"square" === questions.content[currentQuestion].type && (
                        <>
                          <p><span className="square-inner" />{element.content}</p>
                        </>
                      )}
                      {"button-image" === questions.content[currentQuestion].type &&
                        <div className='button-image-wrapper'>
                          <img src={element.img} alt="" />
                          <p>{element.content}</p>
                        </div>}
                    </div>
                  </button>)}
                </React.Fragment>
              ))}
            </div>
            <HintPopup visible={hint} setClose={() => setHint(false)} content={questions.content[currentQuestion].wrongAnswerInfo} />
            <CorrectPopup visible={correctPopup} setClose={() => { setCorrectPopup(false); nextQuestion() }} content={questions.content[currentQuestion]?.correctAnswerInfo?.content} />
          </div>
          <div className='poll-terms'>
            {questions.content[currentQuestion].terms && <div><p><span onClick={() => setTerms(!terms)} className={`square-inner ${terms ? "selected" : ""}`} /><span className='terms-text'>{questions.content[currentQuestion].terms}</span></p></div>}
            {questions.content[currentQuestion].termsData && <div><p><span onClick={() => setTerms2(!terms2)} className={`square-inner ${terms2 ? "selected" : ""}`} /><span className='terms-text'>{questions.content[currentQuestion].termsData}</span></p></div>}
          </div>
          <Button action={hadnleButtonClick}
            disabled={questions.content[currentQuestion].maxAnswers ?
              (selected.length > questions.content[currentQuestion].maxAnswers ||
                selected.length === 0) :
              ident.includes("poll") ? (selected.length === 0 || (
                (questions.content[currentQuestion].terms !== undefined ? !terms : false) ||
                (questions.content[currentQuestion].termsData !== undefined ? !terms2 : false)
              )) :
                selected.length === 0 ||
                hint ||
                !compareArrays(selected, questions.content[currentQuestion]?.correctAnswers

                )}>
            {questions.content[currentQuestion]?.buttonCopy ?? "Nächste Frage"}</Button>
        </div>
        <div className={`side-img ${questions.content[currentQuestion]?.video ? "video-wrapper" : ""}`} style={{ backgroundImage: `url(${questions?.content[currentQuestion]?.img})` }}>
          {Boolean(questions.content[currentQuestion]?.video) && <video controls={true} controlsList="nodownload" name="media" ref={videoRef}>
            <source src={questions.content[currentQuestion]?.video} type="video/mp4" />
          </video>}
          {Boolean(questions.content[currentQuestion]?.imgText) && <p style={{ color: questions.content[currentQuestion].imgText.color }}>
            {questions.content[currentQuestion].imgText.text}
          </p>}
        </div>
      </div>
    </section>
  )
}
